import { useState, useEffect } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import EditEvaluation from "../Evaluation/EditEvaluations";
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const PotentialRiskRating = ({ RiskData, SiteData, RiskEvaluateStatusData, RootCauses, Consequences, RiskImpactRatings, PotentialData }) => {
  
  useEffect(() => {

  }, []);

 const OnEvaluationSave = async () => {
    //const { setCurrentView } = this.context;
    //setCurrentView(0);
    //await this.props.Load()
    //this.loadData();
    ToastUtility.show({
      title: 'Save Risk Evaluation', content: 'Risk evaluation was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
    });
  }


  return (
    <>
      {console.log(PotentialData)}
      <EditEvaluation EvaluationType="Potential" SubHeading={<small> (Ignores Current Controls) </small>} IsReadOnly={false} RootCauses={RootCauses} Consequences={Consequences} RiskId={RiskData.Id} CurrentData={PotentialData} RatingImpactData={RiskImpactRatings} OnSave={OnEvaluationSave} Site={SiteData} />
    </>
  );
};

export default PotentialRiskRating;