/* eslint-disable no-case-declarations */
import { Inject } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Count, HtmlEditor, QuickToolbar, RichTextEditorComponent, Toolbar as rteToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { SimpleToolbarSettings } from '../../helpers/global';
import Loader from '../Common/Loader';

import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export default function ImpactPerspectiveDiz(props) {

  const [editData, setEditData] = useState();

  const [loading, setLoading] = useState(true);


  
  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    var user = getUserDetails();
    console.log(user);
    try {
      const response = await fetch('/api/impactperspectivediz/listforcompany/' + user.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setEditData(data);
        setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const saveItem = async (event) => {
    event.stopPropagation();
    ThrobbleHelper.ToggleThrobble(true, "Saving descriptions...");
    //console.log(editData);
    //if (validateForm()) {
    //  const data = { id: editId, impactBandId: impactBandId, evaluationPerspectiveId: evaluationPerspectiveId, description: description, dateAdded: dateAdded, addedBy: addedBy, dateModified: dateModified, modifiedBy: modifiedBy, status: status, };

      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/impactperspectivediz/saveall', {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(editData),
        });
        if (response.ok) {
          await response.json();
          //loadData();
          ToastUtility.show({
            title: 'Persective Descriptions for the Rating Bands', content: 'The persective descriptions for the rating bands was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
          ThrobbleHelper.ToggleThrobble(false);
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
          ThrobbleHelper.ToggleThrobble(false);
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Persective Descriptions for the Rating Bands', content: 'There was an error saving the persective descriptions for the rating bands!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        ThrobbleHelper.ToggleThrobble(false);
      }
    //  toggle();
    //}
  }

  const saveDescription = (ImpactBandId, EvaluationPerspectiveId, newDescription) => {
    const index = editData.findIndex(
      item => item.ImpactBandId === ImpactBandId && item.EvaluationPerspectiveId === EvaluationPerspectiveId
    );

    // Create a new item with the updated description
    const updatedItem = {
      ...editData[index],
      Description: newDescription, // Update the description
    };
    console.log(updatedItem);
    // Create a new array with the updated item
    const updatedData = [
      ...editData.slice(0, index),   // Items before the updated item
      updatedItem,                   // The updated item
      ...editData.slice(index + 1)   // Items after the updated item
    ];

    // Update the state in React
    setEditData(updatedData);

    //console.log(updatedData);
  };

  const renderImpactBand = (perspectiveId) => {
    const data = editData.find((item) => { return item.EvaluationPerspectiveId === perspectiveId });
    //console.log(data);
    const name = data?.EvaluationPerspectiveName;
    //console.log(name);
    return (
      <>
        <h5 className="mt-5">{name}</h5>
        <table className="table table-sm table-striped w-100">
          <thead>
            <tr style={{background: "#203a4e" }}>
              <th style={{ width: "25%" }} className="text-white fw-bold">Impact Rating Band</th>
              <th className="text-white fw-bold">Description</th>
              
            </tr>
          </thead>

          <tbody>
            {
              editData.filter(item => item.EvaluationPerspectiveId === perspectiveId).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.ImpactBandName}</td>
                    <td>
                      <RichTextEditorComponent placeholder='Description' toolbarSettings={SimpleToolbarSettings} showCharCount={false} value={item.Description} change={e => saveDescription(item.ImpactBandId, item.EvaluationPerspectiveId, e.value)} >
                        <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
                      </RichTextEditorComponent>
                    </td>
                    
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </>
    )


  }

  useEffect(() => {
    loadData();
  }, []);

  if (loading)
    return <Loader />;

  const distinctPerspectives = [...new Set(editData.map(item => item.EvaluationPerspectiveId))];
  
  return (
    <>
      <div className="row">
        <div className="col-md-8 "><h1>Persective Descriptions for the Rating Bands</h1></div>
        <div className="col-md-4 text-end align-self-center">
          <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
          <Button color="success" size="sm" onClick={saveItem}>Save All<i className="far fa-check-circle ms-2"></i></Button>
        </div>
      </div>

      {distinctPerspectives?.map(perspectiveId => {
        return renderImpactBand(perspectiveId)
      })}
      <div className="text-end my-5">
        <Button color="success" size="sm" onClick={saveItem}>Save All<i className="far fa-check-circle ms-2"></i></Button>
      </div>
      <ThrobbleHelper />
    </>
  );
}

