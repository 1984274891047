import { getValue } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { fieldRequired } from '../helpers/validation';

export class DefaultPage extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      Title: '',
      Colour: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CompanyList: [],
      ImpersonatedId: 0
    };
    this.toggle = this.toggle.bind(this);
    this.impersonate = this.impersonate.bind(this)

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    const parts = window.location.href.split("/");
    const estnumber = parts[parts.length - 1];
    document.title = estnumber;
    let userdata = JSON.parse(localStorage.getItem("CurrentUser"))
    this.setState({ ImpersonatedId: userdata?.CompanyId })
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.IUd, CompanyId: data.CompanyId, Title: data.Title, Colour: data.Colour, DateAdded: data.DateAdded, addedBy: data.AddedBy, dateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyId: 0,
        Title: '',
        Colour: '',
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.CompanyId, 'tbCompanyIdError', '* required', 'ddCompanyId');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required', 'tbTitle');
    valid &= fieldRequired(this.state.Colour, 'tbColourError', '* required', 'tbColour');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });

      } else {
        this.setState({
          Status: 1
        });

      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;

    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    function getContrastColor(hexColor) {
      if (hexColor.indexOf("#") >= 0)
        hexColor = hexColor.substr(1);
      var r = parseInt(hexColor.substr(0, 2), 16);
      var g = parseInt(hexColor.substr(2, 2), 16);
      var b = parseInt(hexColor.substr(4, 2), 16);
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000' : '#fff';
    }

    const showStatusColour = (args) => {
      if (args.column.field === "Colour" && args.data && args.cell) {
        args.cell.style.backgroundColor = getValue('Colour', args.data);
        args.cell.style.color = getContrastColor(getValue('Colour', args.data));
      }
    }

    return (
      <GridComponent
        dataSource={data}
        ref={g => this.grid = g}
        commandClick={commandClick}
        allowSorting={true}
        toolbar={gridToolbar}
        queryCellInfo={showStatusColour} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective field='Colour' width='15' headerText="Colour" />
          <ColumnDirective headerText='Actions' width='15' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  impersonate = () => {
    let userInfo = JSON.parse(localStorage.getItem("CurrentUser"));
    userInfo.CompanyId = this.state.ImpersonatedId;
    userInfo.CompanyName = "Impersonating " + this.state.CompanyList.find(item => item.Id === this.state.ImpersonatedId)?.Name
    localStorage.setItem("CurrentUser", JSON.stringify(userInfo))
    this.context.setCompanyName(userInfo.CompanyName)
  }

  clearImpersonate = () => {
    let userInfo = JSON.parse(localStorage.getItem("CurrentUser"));
    userInfo.CompanyId = userInfo.DefaultCompanyId;
    userInfo.CompanyName = this.state.CompanyList.find(item => item.Id === userInfo.DefaultCompanyId)?.Name
    localStorage.setItem("CurrentUser", JSON.stringify(userInfo))
    this.context.setCompanyName(this.state.CompanyList.find(item => item.Id === this.state.ImpersonatedId)?.Name)
    this.setState({ ImpersonatedId: userInfo.CompanyId })
  }

    impersonateCompanies = (companies) => {
    return <div className='my-3'>
      <div>
        <DropDownListComponent id='ddCompanyId' name='ddCompanyId' placeholder='Select Company' dataSource={[{Id: 0, Name: "-SELECT-"},...companies]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.ImpersonatedId} change={e => this.setState({ ImpersonatedId: e.value })} /><div id='tbCompanyIdError' className='error-message' />
      </div>
      <div className='my-3 float-end'>
        <Button color="success" size="sm" className='mx-2' onClick={this.impersonate}>Impersonate <i className="far fa-check-circle ms-2"></i></Button>
        <Button color="primary" size="sm" onClick={this.clearImpersonate} >Clear <i className="far fa-check-circle ms-2"></i></Button>
      </div>
    </div>
  }

  render() {
    const parts = window.location.href.split("/");
      const estnumber = parts[parts.length - 1];
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : DefaultPage.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    
    return (
      <>
            <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1>{this.capitalizeFirstLetter(estnumber.replaceAll("-", " "))}</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
              {/*<Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="md" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Risk Types</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <DropDownListComponent id='ddCompanyId' name='ddCompanyId' placeholder='Company' dataSource={this.state.CompanyList} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.CompanyId} change={e => this.setState({ CompanyId: e.value })} /><div id='tbCompanyIdError' className='error-message' />
            </div>

            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
            </div>

            <div className='mb-3'>
              <label htmlFor="tbColour" className="form-label">
                Colour
                <div className='error' id='tbColourError'></div>
              </label>
              <SketchPicker
                id='tbColour' name='tbColour'
                color={this.state.Colour}
                onChange={(e) => this.setState({ Colour: e.hex })}
              />
            </div>

            {/*<div className='mb-3'>*/}
            {/*<TextBoxComponent id='tbColour' name='tbColour' placeholder='Colour' type='text' maxLength='50' floatLabelType='Auto' showClearButton={true} value={this.state.Colour} onChange={e => this.setState({ Colour: e.target.value })} /> <div id='tbColourError' className='error-message' />*/}
            {/*</div>*/}


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
      const { handleNavigate } = this.context;
      var bearer = 'Bearer ' + getAuthToken();
      const userData = getUserDetails();

    try {
      const response = await fetch('api/companies', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CompanyList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401) {
          handleNavigate("/login");
          localStorage.removeItem('AuthToken');
          localStorage.removeItem('CurrentUser');
          window.location.reload();
        }
      }

    } catch (e) {
      console.error(e);
    }

    try {
        const response = await fetch(`api/risktypes/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      }

      setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
      const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, CompanyId: this.state.CompanyId, Title: this.state.Title, Colour: this.state.Colour, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/risktypes', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Types', content: 'The risk types was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Types', content: 'There was an error saving the risk types!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

    async deleteData(dataId) {
        const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/risktypes/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Types', content: 'The risk types was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
            handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Types', content: 'There was an error deleting the risk types!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

DefaultPage.contextType = Context