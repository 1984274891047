/* eslint-disable eqeqeq */
export const fieldRequired = (data, errorContainer, errorMessage, inputFieldId) => {
  if (data === '' || data === 0 || data === null || data === undefined || data == false) {
      document.getElementById(errorContainer).innerText = errorMessage;
      if (inputFieldId) {
          document.getElementById(inputFieldId).focus();
      }
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};

