import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../helpers/authentication';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const isLoggedIn = getAuthToken();
  const isAdmin = getUserDetails()?.UserRole > 9;

  //const isLoggedIn = true;
    //const isAdmin = true;

  return (
    <Routes>
      <Route
        {...rest}
        //element={isLoggedIn && isAdmin > 1 ? Element : <Navigate to="/login" replace />}
        element={isLoggedIn && isAdmin  ? Element : <Navigate to="/login" replace />}
      />
    </Routes>
  );
};

export default ProtectedRoute;