import React from 'react';
export const CommandTemplate = [
  { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
  { type: 'Delete', buttonOption: { cssClass: 'e-flat text-danger', iconCss: 'e-delete e-icons' } }
];

export const ConfirmDialogStyles = {
  classNames: {
    confirmButton: 'btn btn-sm confirm-button',
    cancelButton: 'btn btn-sm cancel-button',
  }
}

export const YesNoTypes = [
  { value: 0, text: '- Select -' },
  { value: 1, text: 'Yes' },
  { value: 2, text: 'No' },
];

export const CompanySubscriptionTypes = [
  { Id: 0, Text: '- Select -' },
  { Id: 1, Text: 'Free' },
  { Id: 2, Text: 'Pay As You Go' },
  { Id: 3, Text: 'Annual' }
];

export const RatingScaleTypes = [
  { Id: 0, Text: '- Select -' },
  { Id: 1, Text: 'Linear' },
  { Id: 2, Text: 'Exponential' },
];


export const SearchGridToolbar = ['Search'];

export const FullToolbarSettings = {
  items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
    'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
    'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
    'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
  ],
  type: 'Expand'
};

export const SimpleToolbarSettings = {
  items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
  type: 'Expand'
};

const getSiteStructureItems = (siteStructureData, parentId) => {
  return siteStructureData.filter(item => item.ParentId === parentId);
}

export const GenerateSiteStructureTree = (siteStructureData, addAll) =>
{
  let result = [];
  if (addAll)
    result.push({ id: 0, name: "- All -", pid: null, hasChild: false, selected: true, expanded: true, });
  else
    result.push({ id: 0, name: "- Select -", pid: null, hasChild: false, selected: true, expanded: true, });

  siteStructureData.forEach((item) => {
    result.push({ id: item.Id, name: item.Name, pid: (item.ParentId > 0 ? item.ParentId : null), hasChild: (getSiteStructureItems(siteStructureData, item.Id).length > 0) });
  
  });
  return result;
}

export const GenerateTeamTree = () => { }

export const GetContrastColour = (hexColor) => {
  if (hexColor.indexOf("#") >= 0)
    hexColor = hexColor.substr(1);
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  //console.log(hexColor, r,g,b,yiq)
  return (yiq >= 128) ? '#000' : '#fff';
}


export const GetRatingBand = (Rating, RatingList, iv, cv, nl, type, amount) => {
    let Band = null;
    let scale = (cv / iv) ** (1 / nl - 2);
    let result = 0;

    if (type === 1) {
        let RiskScore = (amount - iv) / (((cv - iv) / (nl - 2))) + 2;
        result = RiskScore.toFixed(1);
    }
    else
        result = (Math.log(amount / iv) / Math.log(scale)) + 2;
    //console.log(RatingList)
    if (result > 0) {
        if (result > 5)
            Band = RatingList.find(c => c.Rating === 5);
        else
            Band = RatingList.find(c => c.Rating === Math.floor(result));
    }
    else
        return "- Select Rating -"

    if (Band)
        return Band.RatingBandTitle
    return "No Rating Band";
}

export const GetRatingImpact = (Rating, iv, cv, nl, type) => {
    //console.log(Rating)
    let scale = (cv / iv) ** (1 / nl - 2);
    if (Rating > 0) {
        if (type === 1)
            return Math.floor(((cv - iv) / nl - 2) * (Rating - 2) + iv);
        else
            return Math.floor(iv * scale ** (Rating - 2));
    }
    return 0.00;
}

export const GetMaxImpactRating = (RatingList) => {
    if (RatingList && RatingList.length > 0)
        return RatingList.sort((a, b) => b.Rating - a.Rating)[0].Rating;
    else
        return 1;
}

export const GetMaxRatingAmount = (CurrentRating, RatingList) => {
    let Band = null;
    if (CurrentRating > 0)
        Band = RatingList.find(c => c.Rating === CurrentRating);

    if (Band) {
        if (Band.ImpactTo > 0)
            return Band.ImpactTo;
        else if (Band.ImpactFrom > 0)
            return null;
    }
    return 0;
}

export const GetProbabilityValue = (ProbabilityRating) => {
    return ProbabilityRating / 100;
}

export const GetLikelyImpact = (Impact, P) => {
    return Impact * P;
}

export const CalculateTotalLikelyImpact = (data) => {
    let result = 0;
    data.map(item =>
        result += item.LikelyImpact
    );
    return result;
}

export const CalculateOverallRating = (data, iv, cv, nl, type, total) => {
    //console.log(data)
    let scale = (cv / iv) ** (1 / nl - 2);
    let result = 0;
    if (data) {


        if (type === 1) {
            let totalRiskScore = (total - iv) / (((cv - iv) / (nl - 2))) + 2;
            return totalRiskScore.toFixed(1);
        }
        else {
            let totalRiskScore = (Math.log(total / iv) / Math.log(scale)) + 2;
            return totalRiskScore.toFixed(1);
        }
    } else
        return 0;
}

export const CustomFixedDecimals = (value, IsMoney) => {

    if (IsMoney)
        return value.toFixed(2);

    try {
        let valueStr = value.toString();
        const DecimalPattern = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/;
        const matches = valueStr.match(DecimalPattern);
        const decimalPart = matches[1] || ''; // Extract the decimal part
        const exponentPart = matches[2] || ''; // Extract the exponent part

        // Combine the decimal part and the exponent part to get the total number of decimal places
        const totalDecimalPlaces = decimalPart.length - (exponentPart ? parseInt(exponentPart, 10) : 0);
        switch (totalDecimalPlaces) {
            case 0:
                return value;
            case 1:
                return value.toFixed(1);
            default:
                return value.toFixed(2);
        }
    } catch (e) {
        console.error(e);
        return value;
    }
}

export const ShowLoader = () => {
  return (
    <React.Fragment>
      <div className="text-center mt-3"><i className='fas fa-spinner fa-spin me-2 fa-2x'></i><br />Loading...</div>
    </React.Fragment>
  );
}