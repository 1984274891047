import React, { Component } from 'react';
import { Category, ChartComponent, DataLabel, Export, Highlight, Inject, Legend, MultiLevelLabel, ScatterSeries, Selection, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { Button, ButtonGroup, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class OutputAssuranceResults extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,
            EvaulationView: 1,
            SelectedRiskId: 0,
            RiskData: [],
            RatingImpacts: [],

        };

    }

    componentDidMount() {
        document.title = "Output - Assurance Results";
        this.loadData();
    }

    getChartData(View, RiskId, Data, RatingImpacts) {
        //console.log(Data, this.state.ControlStatus, this.state.RiskPlanStatus)
        var result = [];
        Data.map(item => {
            var RiskDetails = {
                RiskId: item.Id,
                RiskNumber: item.RiskNumber,
                RiskName: item.RiskName,
                RiskSite: item.RiskSiteName,
                Probability: 0,
                Impact: 0,
                Rating: 0,
                Color: "",
                Onset: item.SpeedOfOnset,
                ResponsiblePerson: item.ResponsiblePersonName,
                ResponsibleTeam: item.ResponsibleTeamName,
                Active: item.Status,
                ControlStatus: item.ControlStatus + " %",
                AssurancePlan: item.RiskPlanStatus + " %",
            }
            switch (View) {
                case 1:
                    if (item.RiskPotentialEvaluation) {
                        RiskDetails.Probability = item.RiskPotentialEvaluation.ProbabilityRating;
                        RiskDetails.Impact = item.RiskPotentialEvaluation.TotalLikelyImpact;
                    }
                    break;
                case 2:
                    if (item.RiskActualEvaluation) {
                        RiskDetails.Probability = item.RiskActualEvaluation.ProbabilityRating;
                        RiskDetails.Impact = item.RiskActualEvaluation.TotalLikelyImpact;
                    }
                    break;
            }

            var impactIdx = RatingImpacts.findIndex(c => c.ImpactFrom <= RiskDetails.Impact && (c.ImpactTo == 0 || c.ImpactTo >= RiskDetails.Impact));
            if (impactIdx > -1) {
                RiskDetails.Color = RatingImpacts[impactIdx].RatingBandColour;
            }
            result.push(RiskDetails);
        });
        return result;
    }

    renderDataTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='RiskName'></ColumnDirective>
                                <ColumnDirective headerText='Site' field='RiskSite'></ColumnDirective>
                                <ColumnDirective headerText='Probability' field='Probability'></ColumnDirective>
                                <ColumnDirective headerText='Impact' textAlign='Right' field='Impact'></ColumnDirective>
                                <ColumnDirective headerText={"Speed of Impact (Months)"} field='Onset'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePerson'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeam'></ColumnDirective>
                                <ColumnDirective headerText='Active' width='60' template={(props) => props.Active == 1 ? <span className='alert alert-success'>Y</span> : props.Active == 2 ? <span className='alert alert-danger'>N</span> : <span className='alert alert-secondary'>D</span>}></ColumnDirective>
                                <ColumnDirective headerText='% Controls Captures' field='ControlStatus'></ColumnDirective>
                                <ColumnDirective headerText='Adequacy % Risk Assurance Plan' field='AssurancePlan'></ColumnDirective>
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    render() {
        const ChartData = this.getChartData(this.state.EvaulationView, this.state.SelectedRiskId, this.state.RiskData, this.state.RatingImpacts);
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(ChartData);
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Output<br /><small>- Assurance Results -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                <div>

                </div>
                <ThrobbleHelper />
            </>
        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/dashboard/GetFilterData/${userData.CompanyId}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const FilterData = await response.json();
                this.setState({ RiskData: FilterData.Risks, RatingImpacts: FilterData.RatingBands, loading: false });
                //console.log(data);
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        //console.log(data)
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {
            //let data = this.state.TableData;

            //console.log(data)
            const response = await fetch("api/risks/resultassuranceresult", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

export default OutputAssuranceResults;