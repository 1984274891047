import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Inject, Sort, Toolbar, commandClick } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { fieldRequired } from '../../../helpers/validation';
import { confirm } from 'react-confirm-box';
import TooltipComponent from '../../Common/ToolTip';


export default class Attachments extends Component {
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'View', buttonOption: { cssClass: 'e-flat', iconCss: 'e-download e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons text-danger' } }
    ];

    this.state = {
      modal: false,
      attachmentModal: false,
      gridCommands: commandTemplate,
      fileName: '',
      fileDescription: '',
      fileData: "",
      Attachments: []
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleAttachmentModal = this.toggleAttachmentModal.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.saveItem = this.saveItem.bind(this);
  }

  componentDidMount() {
    this.loadAttachments();
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,

    })
  }

  async updateAttachment(e) {
    let photo = await this.handleFileUpload(e);
    this.setState({ fileData: photo });
  }

  toggleAttachmentModal() {
    this.setState({
      attachmentModal: !this.state.attachmentModal
    })
  }


  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      default: //edit
        this.downloadAttachment(args.rowData.Id);
        break;
    }
  }


  renderDataTable(data, gridCommands, commandClick) {
    return <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true}    >
      <ColumnsDirective>
        <ColumnDirective field='FileName' width='80' headerText="File Name" />
        <ColumnDirective field='FileDescription' width='100' headerText="File Description" />
        <ColumnDirective headerText='Actions' width='30' commands={gridCommands} />
      </ColumnsDirective>
      <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
    </GridComponent>
  }

  handleFileUpload(e) {
    let file = e.target.files[0]
    this.setState({
      fileName: file.name
    })
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    })
  }

  saveBase64ToDownloads(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  saveItem() {
    try {
      let valid = true;
      valid &= fieldRequired(this.state.fileData, "error-file", "*");
      if (valid) {
        this.saveAttachments();
      }
    } catch (error) {
      console.error(error)
    }
  }


  render() {
    return (
      <>
        <i className="fa-solid fa-paperclip me-2 text-primary" onClick={this.toggleModal} id="icoAttachments">
          <span className="badge rounded-pill badge-info position-absolute top-0 start-100 translate-middle">{this.state.Attachments.length}</span>
        </i>
        <TooltipComponent id="ttAttachments" target="icoAttachments" content='View and edit attachments' />
        
        <Modal isOpen={this.state.modal} size='lg' toggle={this.toggleModal}>
          <ModalHeader>
            Attachments
          </ModalHeader>
          <ModalBody>
            {this.renderDataTable(this.state.Attachments, this.state.gridCommands, this.onGridCommand)}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={() => { this.toggleAttachmentModal() }}>Add Attachment <i className="fas fa-plus-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.attachmentModal} toggle={this.toggleAttachmentModal}>
          <ModalHeader>
            Add Attachment
          </ModalHeader>
          <ModalBody>
            <Row className='mb-3'>
              <Col>
                <label>File</label>
                <Input type="file" name="file" id="exampleFile" onChange={(e) => {
                  this.updateAttachment(e);
                }} />
                <div id="error-file" className='error-message'></div>
              </Col>
            </Row>
            <Row >
              <Col>
                <label>File Description</label>
                <TextBoxComponent
                  placeholder="File Description"
                  multiline
                  value={this.state.fileDescription}
                  onChange={(e) => {
                    if (e.value.length <= 200) {
                      this.setState({
                        fileDescription: e.value
                      })
                    }
                  }} />
                <div className='float-end' style={{ fontSize: 10 }}>{200 - this.state.fileDescription.length} characters left</div>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleAttachmentModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  async loadAttachments() {
    const userData = getUserDetails();

    try {
      var bearer = 'Bearer ' + getAuthToken();
      let Id = this.props.Id;
      let Type = this.props.Type;
      const response = await fetch(`api/attachments/?Id=${Id}&Type=${Type}&Role=${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      })

      if (response.ok) {
        const body = await response.json();
        this.setState({ Attachments: body })
      }
    } catch (error) {
      console.error(error)
    }

  }

  async downloadAttachment(Id) {
    const userData = getUserDetails();
    try {
      var bearer = 'Bearer ' + getAuthToken();
      const response = await fetch(`api/attachments/${Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      })

      if (response.ok) {
        let body = await response.json();
        this.saveBase64ToDownloads(body.FileContents, body.FileDownloadName);
      }
    } catch (error) {
      console.error(error)
    }
  }

  async saveAttachments() {
    try {
      let [type, base64] = this.state.fileData.split(',');
      type = type.split("/")[1]?.split(";")[0]
      let postData = {
        FileName: this.state.fileName,
        FileDescription: this.state.fileDescription,
        Format: type,
        FileData: base64,
        Type: this.props.Type,
        AddedBy: getUserDetails().id,
        DateAdded: new Date(),
        ModifiedBy: getUserDetails().id,
        DateModified: new Date(),
        Status: 1,
        AttachmentFromId: this.props.Id,
      }
      const response = await fetch('api/attachments', {
        method: "POST",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + getAuthToken(),
        },
        body: JSON.stringify(postData)
      })
      if (response.ok) {
        const body = await response.json();
        this.loadAttachments();
        this.toggleAttachmentModal();
        this.toggleModal();
      }
    } catch (error) {
      console.error(error)
    }
  }

  async deleteItem(Id) {
    this.toggleModal();
    let confirmDelete = await confirm("Are you sure you want to delete this item?", "Delete Item");
    if (confirmDelete) {
      try {
        let response = await fetch(`api/attachments/${Id}`, {
          method: "DELETE",
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + getAuthToken(),
          },
        })

        if (response.ok) {
          this.loadAttachments();

        }
      } catch (error) {
        console.error(error)
      }
    }
    this.toggleModal();
  }
}