/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Context = createContext();

const AppContext = ({ children }) => {

    const [currentView, setCurrentView] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [userName, setUserName] = useState("");
    const [userOptions, setUserOptions] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleNavigate = (route) => {
        navigate(route);
    }

    const handleUserOptions = (options, name) => {
        setUserOptions(options);
        if (name) setUserName(name);
    }

    return (
        <Context.Provider
            value={{
                companyName,
                setCompanyName,
                handleNavigate,
                userOptions,
                userName,
                handleUserOptions,
                currentView,
                setCurrentView,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default AppContext;