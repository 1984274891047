import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../helpers/authentication';

const AuthenticatedRoute = ({ element: Element, ...rest }) => {
  const isLoggedIn = getAuthToken();

  return (
    <Routes>
      <Route
        {...rest}
        element={isLoggedIn ? Element : <Navigate to="/login" replace />}
      />
    </Routes>
  );
};

export default AuthenticatedRoute;