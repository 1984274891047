/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import { Category, ChartComponent, DataLabel, Export, Highlight, Inject, Legend, MultiLevelLabel, ScatterSeries, Selection, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import React, { Component } from 'react';
import { Button, ButtonGroup, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { ColumnDirective, ColumnsDirective, GridComponent, Sort, Toolbar, CommandColumn, Edit } from '@syncfusion/ej2-react-grids';
//import { isExternalModule } from 'typescript';
import { Link } from 'react-router-dom';
import { GetFilteredData } from '../../helpers/Lookup';
import { SearchGridToolbar, ShowLoader } from '../../helpers/global';
//import ChartHelper from '../../helpers/ChartHelper';

class SelectedRisk extends Component {

  constructor(props) {
    super(props);

    this.state = {
      EvaulationView: 1,
      SelectedRiskId: 0,
      RiskData: [],
      RatingImpacts: [],
      RiskControls: [],
      RiskControlAssurances: [],
      RiskFindingActions: [],
      RiskGapAction: [],
      RiskRootCauses: [],
      CurrentView: 1,
      loading: true,
      showRiskModal: false,
      SelectedRiskNumber: "",
      SelectedPotentialImpact: 0,
      SelectedPotentialProbability: 0,
      SelectedActualImpact: 0,
      SelectedActualProbability: 0,
      SelectedTargetImpact: 0,
      SelectedTargetProbability: 0,
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.onPointClick = this.onPointClick.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  toggleModal() {
    this.setState({ showRiskModal: !this.state.showRiskModal });
  }

  getChartData(View, RiskId, Data, RatingImpacts) {

    var result = [];
    //console.log(Data);
    Data.map(item => {
      var RiskDetails = {
        RiskId: item.Id,
        Slug: item.RiskSlug,
        RiskNumber: item.RiskNumber,
        RiskName: item.RiskName,
        RiskSite: item.RiskSiteName,
        Probability: 0,
        Impact: 0,
        Rating: 0,
        Color: "",
        Onset: item.SpeedOfOnset,
        ResponsiblePerson: item.ResponsiblePersonName,
        ResponsibleTeam: item.ResponsibleTeamName,
        Active: item.Status,
      }
      switch (View) {
        case 1:
          if (item.RiskPotentialEvaluation) {
            RiskDetails.Probability = item.RiskPotentialEvaluation.ProbabilityRating;
            RiskDetails.Impact = item.RiskPotentialEvaluation.TotalLikelyImpact;
          }
          break;
        case 2:
          if (item.RiskActualEvaluation) {
            RiskDetails.Probability = item.RiskActualEvaluation.ProbabilityRating;
            RiskDetails.Impact = item.RiskActualEvaluation.TotalLikelyImpact;
          }
          break;
      }

      // console.log(RatingImpacts, "\n", RiskDetails)
      var impactIdx = RatingImpacts.findIndex(c => c.ImpactFrom <= RiskDetails.Impact && (c.ImpactTo == 0 || c.ImpactTo >= RiskDetails.Impact));
      if (impactIdx > -1) {
        RiskDetails.Color = RatingImpacts[impactIdx].RatingBandColour;
      }
      result.push(RiskDetails);
    });
    return result;
  }

  onPointClick(args) {
    let RiskId = args.series.dataSource[args.pointIndex].RiskId;
    // display the name in an alert box
    // console.log("You selected " + RiskId);
    // const SelectedIdx = args.selectedDataValues[0].pointIndex;

    const SelectData = this.state.RiskData.find(c => c.Id == RiskId);
    let SelectedPotentialImpact = 0;
    let SelectedPotentialProbability = 0;
    let SelectedActualImpact = 0;
    let SelectedActualProbability = 0;
    let SelectedTargetImpact = 0;
    let SelectedTargetProbability = 0;

    if (SelectData.RiskPotentialEvaluation) {
      SelectedPotentialImpact = SelectData.RiskPotentialEvaluation.TotalLikelyImpact;
      SelectedPotentialProbability = SelectData.RiskPotentialEvaluation.ProbabilityRating;
    }

    if (SelectData.RiskActualEvaluation) {
      SelectedActualImpact = SelectData.RiskActualEvaluation.TotalLikelyImpact;
      SelectedActualProbability = SelectData.RiskActualEvaluation.ProbabilityRating;
    }

    if (SelectData.RiskTargetEvaluation) {
      SelectedTargetImpact = SelectData.RiskTargetEvaluation.TotalLikelyImpact;
      SelectedTargetProbability = SelectData.RiskTargetEvaluation.ProbabilityRating;
    }

    this.setState({ SelectedRiskNumber: SelectData.RiskNumber, SelectedPotentialImpact, SelectedPotentialProbability, SelectedActualImpact, SelectedActualProbability, SelectedTargetImpact, SelectedTargetProbability, showRiskModal: true });

  }
  rednerDataTable() {

    const ChartData = this.getChartData(this.state.EvaulationView, this.state.SelectedRiskId, this.state.RiskData, this.state.RatingImpacts);
    const categories = [];
    const maximum = ChartData.sort((a, b) => b.Impact - a.Impact)[0]?.Impact;
    let maxImpactTo = 0;
    if (maximum) {
      maxImpactTo = (maximum + (10000 - (maximum % 10000)));
    }
    this.state.RatingImpacts.sort((a, b) => a - b).map(item => {
      categories.push({
        start: item.ImpactFrom,
        end: item.ImpactTo > 0 ? item.ImpactTo : maxImpactTo,
        text: item.RatingBandTitle
      });

      if (maxImpactTo < item.ImpactTo)
        maxImpactTo = item.ImpactTo;
    });

    const primaryXAxis = { minimum: 0, maximum: 100, majorGridLines: { width: 0 }, edgeLabelPlacement: 'Shift', title: 'Probability (%)' };
    const primaryYAxis = {
      title: "Rating Impact",
      minimum: 0,
      maximum: maxImpactTo,
    };

    const Marker = {
      dataLabel: {
        visible: true, position: "Top", name: "RiskNumber"
      }
    }

    const engageCommandTemplate = [
      { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
    ];

    const onGridCommand = (args) => {
      switch (args.commandColumn.type) {

        case 'Engage':
          //this.engageItem(args.rowData.Id);
          window.location.href = "/risk-edit/"+args.rowData.Slug;
          break;
      }
    }

    return (
      <div className='mt-3'>
        <div className="mb-3">
          <Card>
            <CardBody>
              <div className="mb-3 text-center">
                <ButtonGroup>
                  <Button type='button' size='sm' disabled={this.state.EvaulationView === 1} onClick={() => this.setState({ EvaulationView: 1 })}>Potential</Button>
                  <Button type='button' size='sm' disabled={this.state.EvaulationView > 1} onClick={() => this.setState({ EvaulationView: 2 })}>Actual</Button>
                </ButtonGroup>
              </div>
              <ChartComponent title='Impact : Probability Map' primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} pointClick={this.onPointClick}>
                <Inject services={[ScatterSeries, Legend, Tooltip, Category, Highlight, DataLabel, Selection, Export]} />
                <SeriesCollectionDirective>
                  <SeriesDirective dataSource={ChartData}
                    emptyPointSettings={{ mode: "Drop", fill: "#FFF" }}
                    xName='Probability' yName='Impact' pointColorMapping="Color"
                    type='Scatter' opacity={1} marker={Marker} size='15' />
                </SeriesCollectionDirective>
              </ChartComponent>
            </CardBody>
          </Card>
        </div>
        <div className='mb-3' >
          <Card>
            <CardBody>
              <p className='fw-bolder'>Total No. of Risks: {ChartData.length}</p>
              

              <GridComponent dataSource={ChartData} commandClick={onGridCommand} allowSorting={true} toolbar={SearchGridToolbar}>
                <ColumnsDirective>
                  <ColumnDirective headerText='Risk No.' width='75' template={(props) => <Link to={"/risk-edit/" + props.Slug}>{props.RiskNumber}</Link>}></ColumnDirective>
                  <ColumnDirective headerText='Risk Name' width='100' field='RiskName'></ColumnDirective>
                  <ColumnDirective headerText='Site' width='120' field='RiskSite'></ColumnDirective>
                  <ColumnDirective headerText='Probability' width='80' field='Probability'></ColumnDirective>
                  <ColumnDirective headerText='Impact' width='80' textAlign='Right' field='Impact'></ColumnDirective>
                  <ColumnDirective headerText={"Speed of Impact (Months)"} width='120' field='Onset' textAlign="center"></ColumnDirective>
                  <ColumnDirective headerText='Site' width='120' headerText='Responsible Person' field='ResponsiblePerson'></ColumnDirective>
                  <ColumnDirective headerText='Responsible Team' width='120' field='ResponsibleTeam'></ColumnDirective>
                  <ColumnDirective headerText='Active' width='60' template={(props) => props.Active == 1 ? <span className='alert alert-success'>Y</span> : props.Active == 2 ? <span className='alert alert-danger'>N</span> : <span className='alert alert-secondary'>D</span>}></ColumnDirective>
                  {/*<ColumnDirective headerText='Action' width='60' textAlign="right" template={(props) => <Link to={"/risk-edit/" + props.Slug}><span class="e-btn-icon e-eye e-icons text-black"></span></Link>}></ColumnDirective>*/}
                  <ColumnDirective headerText='Actions' width='100' commands={engageCommandTemplate} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
              </GridComponent>
            </CardBody>
          </Card>
        </div>

        <div className=" mt-4">
          <div className="col-12 mb-3 text-center">
            <Card>
              <CardBody>
                <h5>DATA HEALTH VIEW</h5>
                <div className="row">
                  <div className="col-md-2">
                    <Link to={"/health-analyse-risk"} className='btn btn-primary h-100 w-100'>Analyse & Evaluate the Risks</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/health-treat-risk"} className='btn btn-primary  h-100 w-100'>Treat the Risks</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/health-capture-control"} className='btn btn-primary  h-100 w-100'>Capture Controls</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/health-treat-findings"} className='btn btn-primary  h-100 w-100'>Treat the Findings</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/health-plan-assurance"} className='btn btn-primary  h-100 w-100'>Plan the Control Assurance</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/health-do-assurance"} className='btn btn-primary  h-100 w-100'>Do the Control Assurance</Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 mb-3 text-center">
            <Card>
              <CardBody>
                <h5>RESULTS VIEW</h5>
                <div className="row">
                  <div className="col-md-3">
                    <Link to={"/result-risk-profile"} className='btn btn-primary  h-100 w-100'>Risk Profile</Link>
                  </div>
                  <div className="col-md-3">
                    <Link to={"/result-assurance-plan"} className='btn btn-primary  h-100 w-100'>Assurance Plan</Link>
                  </div>
                  <div className="col-md-3">
                    <Link to={"/result-assurance-results"} className='btn btn-primary  h-100 w-100'>Assurance Results</Link>
                  </div>
                  <div className="col-md-3">
                    <Link to={"/result-gap-closing"} className='btn btn-primary  h-100 w-100'>Gap Closing Actions</Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

      </div>
    );
  }

  render() {
    const content = this.state.loading ? ShowLoader() : this.rednerDataTable();
    return (
      <>
        {content}

        <Modal fade isOpen={this.state.showRiskModal}>
          <ModalHeader toggle={this.toggleModal} close={<button onClick={this.toggleModal} className='close'></button>}>{this.state.SelectedRiskNumber} Risk Details</ModalHeader>
          <ModalBody>
            <div className='alert alert-info text-center mb-3'>
              <h4 className="fw-bolder text-uppercase text-decoration-underline">Inherit</h4>
              Impact : R {this.state.SelectedPotentialImpact.toFixed(2)}<br />
              Probability: {this.state.SelectedPotentialProbability}%
            </div>

            <div className='alert  alert-info text-center mb-3'>
              <h4 className="fw-bolder text-uppercase text-decoration-underline">Residual</h4>
              Impact : R {this.state.SelectedActualImpact.toFixed(2)}<br />
              Probability: {this.state.SelectedActualProbability}%
            </div>

            <div className='alert alert-info text-center mb-3'>
              <h4 className="fw-bolder text-uppercase text-decoration-underline">Target</h4>
              Impact : R {this.state.SelectedTargetImpact.toFixed(2)}<br />
              Probability: {this.state.SelectedTargetProbability}%
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' size='sm' onClick={this.toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>

      </>);
  }


  async loadData() {
    var bearer = 'Bearer ' + getAuthToken();
    const { RatingImpacts } = this.props;
    const RiskData = GetFilteredData();

    const RiskIDs = RiskData.reduce((risks, risk) => {
      if (risks)
        risks.push(risk.Id);
      else
        risks = [risk.Id];
      return risks;
    }, []);
    try {
      const response = await fetch("api/dashboard/GetRiskData", {
        method: "POST",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(RiskIDs)
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          RiskData, RatingImpacts,
          RiskControls: data.RiskControls,
          RiskControlAssurances: data.RiskControlAssurances,
          RiskFindingActions: data.RiskFindingActions,
          RiskGapAction: data.RiskGapAction,
          RiskRootCauses: data.RiskRootCauses,
          loading: false
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default SelectedRisk;