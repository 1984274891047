import { useState, useEffect } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { Button } from 'reactstrap';
import Attachments from './Attachments';

import Findings from './Findings';
import TooltipComponent from '../../Common/ToolTip';



const RiskStatus = ({ RiskData, RiskAnalysisStatusData, RiskEvaluateStatusData, RiskTreatStatusData, RiskPlanStatusData }) => {
  
  useEffect(() => {

  }, []);



  return (
    <>
      <div className="risk-heading">
        <div className='row py-3 '>
          <div className='pb-3 col-md-9 text-center'>
            <strong>Risk Management</strong>
            {/*<div className='d-flex justify-content-center flex-wrap'>*/}
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <small className="p-1" style={{ backgroundColor: RiskAnalysisStatusData?.Percentage > 0 && RiskAnalysisStatusData?.Percentage < 100 ? 'orange' : RiskAnalysisStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{RiskAnalysisStatusData?.Percentage > 0 ? `${RiskAnalysisStatusData?.Percentage}% - ${new Date(RiskAnalysisStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                <button onClick={() => this.setState({ SelectedItem: 1 })} className='btn risk-btn-main mt-1'>Analyse</button>
              </div>
              <div className='col-md-4'>
                <small className="p-1" style={{ backgroundColor: RiskEvaluateStatusData?.Percentage > 0 && RiskEvaluateStatusData?.Percentage < 100 ? 'orange' : RiskEvaluateStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{RiskEvaluateStatusData?.Percentage > 0 ? `${RiskEvaluateStatusData?.Percentage}% - ${new Date(RiskEvaluateStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                <button onClick={() => this.handleUpdate(2, 0)} className='btn risk-btn-main mt-1'>Evaluate</button>
              </div>
              <div className='col-md-4'>
                <small className="p-1" style={{ backgroundColor: RiskTreatStatusData?.Percentage > 0 && RiskTreatStatusData?.Percentage < 100 ? 'orange' : RiskTreatStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{RiskTreatStatusData?.Percentage > 0 ? `${RiskTreatStatusData?.Percentage}% - ${new Date(RiskTreatStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
                <button onClick={() => this.setState({ SelectedItem: 3 })} className='btn risk-btn-main mt-1'>Treat</button>
              </div>
            </div>
          </div>

          <div className='mb-3 col-md vertical text-center'>
            <strong>Assurances Management</strong>
            <div className=''>
              <small className="p-1" style={{ backgroundColor: RiskPlanStatusData?.Percentage > 0 && RiskPlanStatusData?.Percentage < 100 ? 'orange' : RiskPlanStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{RiskPlanStatusData?.Percentage > 0 ? `${RiskPlanStatusData?.Percentage}% - ${new Date(RiskPlanStatusData?.DateModified).toISOString().split("T")[0]}` : "NYS"}</small> <br />
              <button onClick={() => this.setState({ SelectedItem: 4 })} className='btn risk-btn-main mt-1'>Plan</button>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default RiskStatus;