import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card, CardBody } from 'reactstrap';
import { PieChart, Pie, BarChart, Bar, Legend, Tooltip, Cell, ResponsiveContainer, Rectangle, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Link } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class HealthDoAssurance extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, editControlModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            GraphTitle: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,

        };
        this.toggle = this.toggle.bind(this);
        this.toggleControl = this.toggleControl.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    toggleControl() {
        this.setState({
            editControlModal: !this.state.editControlModal
        });
    }

    componentDidMount() {
        document.title = "Data Health - Do the Control Assurances";
        this.loadData();
    }

    editItem = async (data, title) => {

        const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toISOString().split("T")[0] }));
        this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
    };

    editControlItem = async (data, title) => {

        this.setState({ GraphTitle: title, GraphData: data, editControlModal: true });
    };

    getRiskOutstandingCounts = (risks, controls) => {
        const today = new Date();

        let pastDueCount = 0;
        let currentlyDueCount = 0;
        let noOutstandingCount = 0;

        const risksArray = [];

        risks.forEach(risk => {
            const matchingControl = controls.find(control => control.RiskId === risk.Id);
            const newRisk = { ...risk, Result: '' };

            if (matchingControl && matchingControl.Assurances) {
                const assurancesForControl = matchingControl.Assurances;

                if (assurancesForControl.length > 0) {
                    const latestAssurance = assurancesForControl.reduce((latest, assurance) => {
                        return new Date(assurance.EndsOnDate) > new Date(latest.EndsOnDate) ? assurance : latest;
                    });

                    if (new Date(latestAssurance.EndsOnDate) < today) {
                        pastDueCount++;
                        newRisk.Result = 'o/standing "past due" assurances';
                        risksArray.push(newRisk);
                    } else if (new Date(latestAssurance.EndsOnDate) === today) {
                        currentlyDueCount++;
                        newRisk.Result = 'o/standing "currently due" assurances';
                        risksArray.push(newRisk);
                    }
                } else {
                    noOutstandingCount++;
                    newRisk.Result = 'No o/standing assurances';
                    risksArray.push(newRisk);
                }
            }
        });

        return {
            counts: [
                { name: 'o/standing "past due" assurances', value: pastDueCount },
                { name: 'o/standing "currently due" assurances', value: currentlyDueCount },
                { name: 'No o/standing assurances', value: noOutstandingCount },
            ],
            risks: risksArray,
        };
    }

    getControlOutstandingCounts = (risks, controls) => {
        const today = new Date();

        let pastDueCount = 0;
        let currentlyDueCount = 0;
        let noOutstandingCount = 0;

        const controlsArray = [];
        //const riskIds = risks.map(risk => risk.Id);

        controls.forEach(control => {
            if (control.Assurances && control.Assurances.length > 0) {
                const latestAssurance = control.Assurances.reduce((latest, assurance) => {
                    return new Date(assurance.EndsOnDate) > new Date(latest.EndsOnDate) ? assurance : latest;
                });
                const newControl = { ...control, Result: '' };

                if (new Date(latestAssurance.EndsOnDate) < today) {
                    pastDueCount++;
                    newControl.Result = 'o/standing "past due" assurances';
                    controlsArray.push(newControl);
                } else if (new Date(latestAssurance.EndsOnDate) === today) {
                    currentlyDueCount++;
                    newControl.Result = 'o/standing "currently due" assurances';
                    controlsArray.push(newControl);
                }
            } else {
                const newControl = { ...control, Result: '' };
                noOutstandingCount++;
                newControl.Result = 'No o/standing assurances';
                controlsArray.push(newControl);
            }
        });
        
        const updatedControlsArray = controlsArray.map(control => {
            const matchingRisk = risks.find(risk => risk.Id === control.RiskId);
            if (matchingRisk) {
                return { ...control, ...matchingRisk };
            } else {
                return control;
            }
        });
        
        return {
            counts: [
                { name: 'o/standing "past due" assurances', value: pastDueCount },
                { name: 'o/standing "currently due" assurances', value: currentlyDueCount },
                { name: 'No o/standing assurances', value: noOutstandingCount },
            ],
            controls: updatedControlsArray,
        };
    }

    renderTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Result' field='Result'></ColumnDirective>}
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderControlTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Controls: {data?.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport2(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                                <ColumnDirective headerText='Control No.' field='ControlNumber' width='100' ></ColumnDirective>
                                <ColumnDirective headerText='Control Name' field='ShortName'></ColumnDirective>
                                <ColumnDirective headerText='Control Resp. Person' field='ControlResponsiblePerson'></ColumnDirective>
                                <ColumnDirective headerText='Control Resp. Team' field='ResponsibleTeamName'></ColumnDirective>
                                {/*<ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>*/}
                                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Result' field='Result'></ColumnDirective>}
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderDataTable(data, data2, colors) {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
                </text>
            );
        };
        return (
            <>
                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Which risks have inadiquate control assurance plans?")}>
                        <strong>Do my risks have any o/standing assurances?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                                    {data.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editControlItem(data2.controls, "Which controls have inadequate control assurances?")}>
                        <strong>Do my controls have any o/standing assurances?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data2.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>
            </>
        );
    }

    render() {
        let riskOutstanding;
        let controlOutstanding;

        const colors = ['#ff0000', '#ffb6c1', '#32cd32'];

        if (!this.state.loading) {
            riskOutstanding = this.getRiskOutstandingCounts(this.state.editData, this.state.ControlList);
            controlOutstanding = this.getControlOutstandingCounts(this.state.editData, this.state.ControlList);
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(riskOutstanding, controlOutstanding, colors);
        //console.log(this.state, evaluationStatuses, barData)
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Data Health<br /><small>- Do the Control Assurances -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                            <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, riskOutstanding.counts, controlOutstanding.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                <div>

                </div>
                {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.editModal && this.renderTable(this.state.GraphData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal >}

                {<Modal isOpen={this.state.editControlModal} toggle={this.toggleControl} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggleControl} close={<button className="close" onClick={this.toggleControl}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.editControlModal && this.renderControlTable(this.state.GraphData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggleControl}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal >}
                <ThrobbleHelper />
            </>

        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/controlassuranceresults/listallbycompany/${userData.CompanyId}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ AssuranceResultData: data });
                //console.log(data);
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskplanstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ RiskPlanStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/controlassurancestatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ AssuranceStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/controls/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                let data = await response.json();
                data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
                //console.log(data)
                this.setState({ ControlList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }

    handleExport = async (e, riskOutstanding, controlOutstanding) => {
        e.stopPropagation();
        ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        let postData = {
            PieData: JSON.stringify(riskOutstanding),
            Pie2Data: JSON.stringify(controlOutstanding),
            Pie3Data: JSON.stringify([]),
            Pie4Data: JSON.stringify([]),
            Pie5Data: JSON.stringify([]),
            BarData: JSON.stringify([]),
            Bar2Data: JSON.stringify([]),
        }

        try {
            const response = await fetch('api/risks/downloadhealthdoca', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    ThrobbleHelper.ToggleThrobble(false);
                    handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        const finalData = data.map(item => ({
            ResponsibleTeamName: item.ResponsibleTeamName,
            ResponsiblePersonName: item.ResponsiblePersonName,
            RiskNumber: item.RiskNumber,
            RiskName: item.Name,
            DateLogged: item.DateAdded,
            LoggedBy: item.AddedByName,
            Result: item.Result,
        }));
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {

            //console.log(data)
            const response = await fetch("api/risks/datahealthdocontrolassurance", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport2(evt, data) {
        //const { handleNavigate } = this.context;
        const finalData = data.map(item => ({
            ResponsibleTeamName: item.ResponsibleTeamName,
            ResponsiblePersonName: item.ResponsiblePersonName,
            RiskNumber: item.RiskNumber,
            RiskName: item.Name,
            DateLogged: item.DateAdded,
            LoggedBy: item.AddedByName,
            ControlNumber: item.ControlNumber,
            ShortName: item.ShortName,
            ControlResponsiblePerson: item.ControlResponsiblePerson,
            ControlResponsibleTeamName: item.ResponsibleTeamName,
            Result: item.Result,
        }));
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {

            //console.log(data)
            const response = await fetch("api/risks/datahealthdocontrolassurances", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

export default HealthDoAssurance;