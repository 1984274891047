import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import EditEvaluation from './EditEvaluations';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';

export default class RatingImpactTable extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      RatingImpactData: this.props.RatingImpactData, loading: true,
      showImpactTable: false,
      Perspectives: [],
      ImpactBandList: [],
      editData: [],
      showPrevEvaluation: false
    }

    this.toggle = this.toggle.bind(this);
    this.togglePrevEvaluation = this.togglePrevEvaluation.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  toggle() {
    this.setState({ showImpactTable: !this.state.showImpactTable });
  }

  togglePrevEvaluation() {
    this.setState({ showPrevEvaluation: !this.state.showPrevEvaluation });
  }

  getBackgroundClass() {
    if (this.props.showPrevEvaluation) {
      let result = " bg-evaluation " + this.props.PrevEvaluationName.toLowerCase();
      return result;
    }
  }

  render() {
    let newArray;
    let incrementValue;
    if (!this.state.loading) {
      newArray = Array.from({ length: this.state.editData.NumOfLevels }, (_, index) => index + 1);
      incrementValue = (this.state.editData.CatastrophicLevel / this.state.editData.ImmaterialityLevel) ** (1 / (this.state.editData.NumOfLevels - 2));

    }
    let valueB = 0;

    const { setCurrentView } = this.context;
    return (
      <>
        <div className="row px-0">
          <div className="col-2 mb-3">
            <button className="btn btn-outline-dark ms-2 mx-2 my-2" onClick={() => setCurrentView(0)}><i className="far fa-circle-left me-2"></i>Back</button>
          </div>
          <div className="col-10 mb-3 text-end">
            <button type="button" className='btn btn-info mx-2' onClick={this.togglePrevEvaluation} hidden={!this.props.showPrevEvaluation}><i className="fa-solid fa-exclamation-triangle me-2"></i>Show the {this.props.PrevEvaluationName} Risk Rating</button>

            <button type='button' className='btn btn-info' onClick={this.toggle}><i className="fa-solid fa-table me-2"></i>Show Impact Table</button>
          </div>
        </div>
        <Modal isOpen={this.state.showImpactTable} size='xl'   >
          <ModalHeader className='text-center' toggle={this.toggle}>
            Impact Table
          </ModalHeader>
          <ModalBody>
            <div className="text-center">

              <small>Refer to this table for guidance when rating</small>
            </div>
            <table className='table border table-sm w-100'>
              <thead className='fw-bold'>
                <tr style={{ width: "60px", background: "#203a4e", color: "#fff !important" }}>
                  <th style={{ width: "60px" }} rowSpan="2" >Level</th>
                  <th className='' style={{ width: "150px" }} rowSpan="2">Level label</th>
                  <th className='text-center' rowSpan="2" >Colour</th>
                  <th className='text-center' colSpan="2">Impact</th>
                  <th className='text-center' colSpan={this.state.Perspectives.length}>Impact description per evaluation perspective</th>
                </tr>
                <tr>
                  <th className='text-center table-primary'>Range from (incl)</th>
                  <th className='text-center table-primary'>Range to (below)</th>
                  {this.state.Perspectives.map((item, index) => (
                    <th key={index}>{item?.Title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!this.state.loading && newArray.map((item, index) => {
                  let valueA = 0;

                  if (index > 0 && index < (newArray.length - 1)) {

                    if (this.state.editData.TypeId === 1) {
                      valueA = valueB
                      valueB = Math.floor((this.state.editData.CatastrophicLevel - this.state.editData.ImmaterialityLevel) / (this.state.editData.NumOfLevels - 2)) + valueA
                    } else {
                      valueA = valueB
                      valueB = Math.ceil(this.state.editData.ImmaterialityLevel * incrementValue ** ((index + 1) - 1));
                    }
                  } else if (index === 0) {
                    valueA = 0;
                    valueB = this.state.editData.ImmaterialityLevel;
                  } else {
                    valueA = this.state.editData.CatastrophicLevel;
                    valueB = Infinity;
                  }
                  //console.log(this.state.TypeId)

                  return (<tr key={index}>
                    <td>{item}</td>
                    <td>{this.state.ImpactBandList[index]?.Title}</td>
                    <td>
                      <div style={{ padding: '2px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-block' }}>
                        <div style={{ width: '24px', height: '24px', borderRadius: '2px', background: `${this.state.ImpactBandList[index]?.Colour}`, }} />
                      </div>

                    </td>
                    <td>{valueA}</td>
                    <td>{valueB}</td>
                    {this.state.Perspectives.map((item, index2) => (
                      <td key={index2}>{item?.Description}</td>
                    ))}
                  </tr>)
                })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' size="sm" onClick={this.toggle}>Close<i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showPrevEvaluation} size='xl' className={this.getBackgroundClass()}>
          {/* <ModalHeader toggle={this.togglePrevEvaluation}></ModalHeader> */}
          <ModalBody>
            <EditEvaluation EvaluationType={this.props.PrevEvaluationName} IsReadOnly={true} CurrentData={this.props.PrevEvaluationData} RatingImpactData={this.props.RatingImpactData} />
          </ModalBody>
          <ModalFooter>
            <Button type='button' onClick={this.togglePrevEvaluation}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const User = getUserDetails();
    //console.log(this.props.SiteId)

    try {
      const response = await fetch('api/impactband/GetAllForCompany/' + User.CompanyId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        let colors = data.map(item => item.Colour)
        let levelLabel = data.map(item => item.Title)
        //console.log(data, colors, levelLabel)
        this.setState({ ImpactBandList: data, Colours: colors, LevelLabels: levelLabel });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/evaluationperspectives/listallbycompany/${User.CompanyId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ Perspectives: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/sitestructures/${this.props.SiteId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
    }

    setAuthToken(getAuthToken(), new Date());
  }
}
