import React, { Component } from 'react';
import { Category, ChartComponent, DataLabel, Export, Highlight, Inject, Legend, MultiLevelLabel, ScatterSeries, Selection, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { Button, ButtonGroup, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Table } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, GridComponent, CommandColumn, Edit, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class ResultGapClosingAction extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            /*{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },*/
            { type: 'More', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,
            EvaulationView: 1,
            SelectedRiskId: 0,
            RiskData: [],
            RatingImpacts: [],

        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Output - Gap Closing Actions";
        this.loadData();
    }

    getChartData(View, RiskId, Data, RatingImpacts) {
        //console.log(Data, this.state.ControlStatus, this.state.RiskPlanStatus)
        var result = [];
        Data.map(item => {
            let targetTotal = this.state.EvaluationData.TargetData.find(a => a.RiskId === item.Id)?.TotalLikelyImpact;
            let actualTotal = this.state.EvaluationData.ActualData.find(a => a.RiskId === item.Id)?.TotalLikelyImpact;

            let TotalDiff = Math.abs(targetTotal - actualTotal);
            let changePerc = Math.round((TotalDiff / actualTotal) * 100);
            changePerc = Math.min(changePerc, 100);
            //console.log(potentialTotal, actualTotal, TotalDiff)
            var RiskDetails = {
                RiskId: item.Id,
                RiskNumber: item.RiskNumber,
                RiskName: item.RiskName,
                RiskSite: item.RiskSiteName,
                Probability: 0,
                Impact: 0,
                Rating: 0,
                Color: "",
                Onset: item.SpeedOfOnset,
                ResponsiblePerson: item.ResponsiblePersonName,
                ResponsibleTeam: item.ResponsibleTeamName,
                Active: item.Status,
                ActiveStatus: item.Status == 1 ? "Yes" : item.Status == 2 ? "No" : "Draft",
                UncontrolledGap: TotalDiff ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(TotalDiff) : "NO GAP TO CLOSE",
                GapPerc: changePerc ? changePerc + " %" : "-----",
            }
            switch (View) {
                case 1:
                    if (item.RiskPotentialEvaluation) {
                        RiskDetails.Probability = item.RiskPotentialEvaluation.ProbabilityRating;
                        RiskDetails.Impact = item.RiskPotentialEvaluation.TotalLikelyImpact;
                    }
                    break;
                case 2:
                    if (item.RiskActualEvaluation) {
                        RiskDetails.Probability = item.RiskActualEvaluation.ProbabilityRating;
                        RiskDetails.Impact = item.RiskActualEvaluation.TotalLikelyImpact;
                    }
                    break;
            }

            var impactIdx = RatingImpacts.findIndex(c => c.ImpactFrom <= RiskDetails.Impact && (c.ImpactTo == 0 || c.ImpactTo >= RiskDetails.Impact));
            if (impactIdx > -1) {
                RiskDetails.Color = RatingImpacts[impactIdx].RatingBandColour;
            }
            result.push(RiskDetails);
        });
        return result;
    }

    DateDifferenceMessage = (targetDate) => {
        const today = new Date();
        const target = new Date(targetDate);
        const differenceInDays = Math.floor((target - today) / (1000 * 60 * 60 * 24));
        //console.log(targetDate, differenceInDays)
        if (differenceInDays < 0) {
            return <div>Late - {Math.abs(differenceInDays)}</div>;
        } else if (differenceInDays > 0) {
            return <div>Early - {differenceInDays}</div>;
        } else {
            return <div>On time - 0</div>;
        }
    };

    viewItem = (id) => {
        if (id > 0) {
            const planStatus = this.state.RiskPlanStatusData.find((item) => { return item.RiskId === id });
            const timingData = this.state.EvaluationData.TargetData.find((item) => { return item.RiskId === id });
            //console.log(planStatus, timingData)
            this.setState({ GCAPlanData: planStatus, TimingData: timingData });
        }

        this.setState({ editModal: true });
    };

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'More':
                this.viewItem(args.rowData.RiskId);
                break;
            default: //edit
                this.editItem(args.rowData.Id);
                break;

        }
    }

    renderResults(data) {
        return (
            data ?
                (<tr>
                    <td>{data.Percentage}%</td>
                    <td>{new Date(data.DateAdded).toDateString()}</td>
                    <td>{data.UserName}</td>
                </tr>)
                :
                (<p>No data captured yet</p>)
        );
    }

    renderTiming(data) {
        return (
            data && data.TargetedCompletionDate ?
                (<tr>
                    <td>{this.DateDifferenceMessage(data.TargetedCompletionDate)}</td>
                    <td>{new Date(data.TargetedCompletionDate).toDateString()}</td>
                    <td>{new Date(data.TargetedCompletionDate).toDateString()}</td>
                </tr>)
                :
                (<p>No data captured yet</p>)
        );
    }

    renderDataTable(data, gridCommands, commandClick) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} commandClick={commandClick} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='RiskName'></ColumnDirective>
                                <ColumnDirective headerText='Site' field='RiskSite'></ColumnDirective>
                                <ColumnDirective headerText='Probability' width='70' field='Probability'></ColumnDirective>
                                <ColumnDirective headerText='Impact' textAlign='Right' field='Impact'></ColumnDirective>
                                <ColumnDirective headerText='Speed of Impact (Months)' width='60' field='Onset'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePerson'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeam'></ColumnDirective>
                                <ColumnDirective headerText='Active?' width='60' template={(props) => props.Active == 1 ? <span className='alert alert-success'>Y</span> : props.Active == 2 ? <span className='alert alert-danger'>N</span> : <span className='alert alert-secondary'>D</span>}></ColumnDirective>
                                <ColumnDirective headerText='Size of Uncontrolled Gap' textAlign='Right' field='UncontrolledGap'></ColumnDirective>
                                <ColumnDirective headerText='Gap as % of Actual Likely Impact' field='GapPerc'></ColumnDirective>
                                <ColumnDirective headerText='Action' width='70' commands={gridCommands} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    render() {
        const ChartData = this.getChartData(this.state.EvaulationView, this.state.SelectedRiskId, this.state.RiskData, this.state.RatingImpacts);
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(ChartData, this.state.gridCommands, this.onGridCommand);
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Output<br /><small>- Gap Closing Actions -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Detailed Plan</ModalHeader>
                    <ModalBody>
                        <h3 className="text-center">Adequacy of the GCA Plan</h3>
                        <Table striped hover bordered aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th className="fw-bold">Adequacy of the GCP</th>
                                    <th className="fw-bold">Determined on</th>
                                    <th className="fw-bold">Determined by</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderResults(this.state.GCAPlanData)}
                            </tbody>
                        </Table>

                        <h3 className="text-center">Timing of Execution</h3>
                        <Table striped hover bordered aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th className="fw-bold">Late / early (days)</th>
                                    <th className="fw-bold">Targeted date by when the gap should be closed</th>
                                    <th className="fw-bold">Anticipated date to have the gap closed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTiming(this.state.TimingData)}
                            </tbody>
                        </Table>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <ThrobbleHelper />
            </>
        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/riskevaluation/GetRiskEvaluations`, {
                method: "GET",
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ EvaluationData: data });
            }
        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskplanstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ RiskPlanStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/dashboard/GetFilterData/${userData.CompanyId}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const FilterData = await response.json();
                this.setState({ RiskData: FilterData.Risks, RatingImpacts: FilterData.RatingBands, loading: false });
                //console.log(data);
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        //console.log(data)
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {
            //let data = this.state.TableData;

            //console.log(data)
            const response = await fetch("api/risks/resultgapclosingaction", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

export default ResultGapClosingAction;