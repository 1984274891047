import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import Attachments from './Common/Attachments';
import Findings from './Common/Findings';

export class ControlList extends Component {

  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
    ];

    this.state = {
      editData: [], risks: [], loading: true, editModal: false, viewModal: false, controlsEditModal: false, controlRootModal: false, controlConsequenceModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      RiskNumber: '',
      Name: '',
      Description: '',
      RiskTypeId: 0,
      RiskCategoryId: 0,
      RiskStatusId: 0,
      SiteStructureId: 0,
      SpeedOfOnset: 0,
      ResponsiblePersonId: 0,
      ResponsibleTeamId: 0,
      HasOpportunities: false,
      Opportunities: '',
      HasIndicators: false,
      Indicators: '',
      DeactivationReason: '',
      DateAdded: new Date(),
      AddedBy: 0,
      AddedByTeamId: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CompanyList: [],
      RiskCategoryList: [],
      RiskStatusList: [],
      RiskTypeList: [],
      SiteStructureList: [],
      TeamList: [],
      UsersList: [],
      ConsequencesList: [],
      RootCausesList: [],
      SelectedItem: 0,

    };
    this.toggleControlsEdit = this.toggleControlsEdit.bind(this);
    this.toggleControlRoot = this.toggleControlRoot.bind(this);
    this.toggleControlConsequence = this.toggleControlConsequence.bind(this);

  }

  componentDidMount() {
    document.title = "Control List";
    this.loadData();
  }

  toggleControlsEdit() {
    this.setState({
      controlsEditModal: !this.state.controlsEditModal
    });
  }

  toggleControlRoot() {
    this.setState({
      controlRootModal: !this.state.controlRootModal
    });
  }

  toggleControlConsequence() {
    this.setState({
      controlConsequenceModal: !this.state.controlConsequenceModal
    });
  }

  handleRootCauseChange = (id) => {
    const { ConnectedRootCauses } = this.state;

    const updatedConnectedRootCauses = ConnectedRootCauses?.includes(id)
      ? ConnectedRootCauses.filter((causeId) => causeId !== id)
      : [...ConnectedRootCauses, id];

    this.setState({ ConnectedRootCauses: updatedConnectedRootCauses });
  };

  handleConsequenceChange = (id) => {
    const { ConnectedConsequences } = this.state;

    const updatedConnectedConsequences = ConnectedConsequences?.includes(id)
      ? ConnectedConsequences.filter((consequenceId) => consequenceId !== id)
      : [...ConnectedConsequences, id];

    this.setState({ ConnectedConsequences: updatedConnectedConsequences });
  };

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  viewItem = (id) => {
    const { handleNavigate } = this.context;

    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({
        editId: id,
        ControlId: data.Id,
        ControlNumber: data.ControlNumber,
        ShortName: data.ShortName,
        Assurances: data.Assurances,
        RiskDescription: data.Description,
        Critical: data.Critical,
        NeedsAssurance: data.NeedsAssurance,
        ControlResponsiblePersonId: data.ControlResponsiblePersonId,
        LevelId: data.LevelId,
        ITId: data.ITId,
        AutomatedId: data.AutomatedId,
        ConnectedRootCauses: data.ConnectedRootCauses,
        ConnectedConsequences: data.ConnectedConsequences,
      });
    }
    //console.log(id)
    //if (id > 0) {
    //    const controlData = this.state.editData.find((item) => { return item.Id === id });
    //    const data = this.state.risks.find((item) => { return item.Id === controlData.RiskId });

    //    handleNavigate("/risk-edit/" + data.RiskNumber);
    //}

    this.setState({ editId: id, controlsEditModal: true });
  };

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      case 'Engage':
        this.viewItem(args.rowData.Id);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='ControlNumber' width='50' headerText="Control #" />
          <ColumnDirective field='ShortName' width='100' headerText="Control Name" />
          <ColumnDirective field='Description' width='100' headerText="Control Description" />
          <ColumnDirective field='ControlResponsiblePerson' width='70' headerText="Resp. Person" />
          <ColumnDirective field='ResponsibleTeam' width='70' headerText="Resp. Team" />
          <ColumnDirective field='Critical' width='50' headerText="Critical?" />
          <ColumnDirective field='NeedsAssurance' width='50' headerText="Needs Assurence?" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    const levels = [{ Title: "unclassified", Id: 0 }, { Title: "entity level", Id: 1 }, { Title: "activity level", Id: 2 }]
    const isIT = [{ Title: "unclassified", Id: 0 }, { Title: "IT general control", Id: 1 }, { Title: "IT application control", Id: 2 }]
    const isAutomated = [{ Title: "unclassified", Id: 0 }, { Title: "automated", Id: 1 }, { Title: "manual", Id: 2 }]
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ControlList.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    //console.log(this.state)
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1 className='mb-0'>Control List</h1>
              <span>(Find & select the control you wish to work on)</span>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/processing" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Processing</Link>
              {/*<Button color="primary" size="sm" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='mb-4'>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.controlsEditModal} toggle={this.toggleControlsEdit} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggleControlsEdit} close={<button className="close" onClick={this.toggleControlsEdit}><i className="fas fa-times"></i></button>}>Controls</ModalHeader>
          <ModalBody>
            <div className='text-center'>
              <em className='my-3 fw-bold'>(Add new control or click on 'edit' to do more with an existing control )</em>
            </div>
            <div className='row'>
              {this.state.editId > 0 && <div className='d-flex risk-status mb-3'>
                <Attachments Id={this.state.editId} Type={2} />
                <Findings Id={this.state.editId} Type={2} />
              </div>}
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.RiskDescription} onChange={e => this.setState({ RiskDescription: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddControlResponsiblePersonId' name='ddControlResponsiblePersonId' placeholder='Responsible Person (Control)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UsersList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ControlResponsiblePersonId} change={e => this.setState({ ControlResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='col-md-4 align-self-center'>
                <CheckBoxComponent id='cbCritical' name='cbCritical' type='checkbox' label='Critical' checked={this.state.Critical} change={e => this.setState({ Critical: e.checked })} />
              </div>
              <div className='col-md-4 align-self-center'>
                <CheckBoxComponent id='cbNeedsAssurance' name='cbNeedsAssurance' type='checkbox' label='Needs Assurance' checked={this.state.NeedsAssurance} change={e => this.setState({ NeedsAssurance: e.checked })} />
              </div>
            </div>
            <div className='text-center'>
              <h6 className='my-3 fw-bold'>Control Classification</h6>
            </div>
            <div className='row'>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddLevelId' name='ddLevelId' placeholder='Level' dataSource={levels} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.LevelId} change={e => this.setState({ LevelId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddITId' name='ddITId' placeholder='IT/non-IT' dataSource={isIT} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.ITId} change={e => this.setState({ ITId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddAutoId' name='ddAutoId' placeholder='Automated/Manual' dataSource={isAutomated} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.AutomatedId} change={e => this.setState({ AutomatedId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
            {this.state.editId > 0 && <div>
              <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlRoot() }}>Select Root Causes</Button>
              <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlConsequence() }}>Select Consequences</Button>
            </div>}
            {this.state?.Assurances?.length > 0 && <div>
              <Button color="primary" size="sm" onClick={() => { this.setState({ SelectedItem: 4 }); this.toggleControlsEdit(); }}>Engage with Control Assurance Plan</Button>
            </div>}
            <div className='ms-auto'>
              <Button className='ms-1' color="dark" size="sm" onClick={() => this.toggleControlsEdit()}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button className='ms-1' color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.controlRootModal} toggle={this.toggleControlRoot} className={this.props.className} scrollable size="sm" backdrop="static">
          <ModalHeader toggle={this.toggleControlRoot} close={<button className="close" onClick={this.toggleControlRoot}><i className="fas fa-times"></i></button>}>Control Root Causes</ModalHeader>
          <ModalBody>
            <div className='row'>
              <small className='text-center'>(Root cause/s addressed by this control)</small>
              {this.state.RootCausesList.length ? this.state.RootCausesList.map((cause, index) => (
                <div className='mb-2' key={index}>
                  <CheckBoxComponent
                    id={`cbRootCause_${cause.Id}`}
                    name={`cbRootCause_${cause.Id}`}
                    type="checkbox"
                    label={cause.ShortName}
                    checked={this.state.ConnectedRootCauses?.includes(cause.Id)}
                    change={() => this.handleRootCauseChange(cause.Id)}
                  />
                </div>
              )) : <span className='text-center'>Create a Root Cause first to link to a control</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleControlRoot}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            {this.state.RootCausesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.controlConsequenceModal} toggle={this.toggleControlConsequence} className={this.props.className} scrollable size="sm" backdrop="static">
          <ModalHeader toggle={this.toggleControlConsequence} close={<button className="close" onClick={this.toggleControlConsequence}><i className="fas fa-times"></i></button>}>Control Consequences</ModalHeader>
          <ModalBody>
            <div className='row'>
              <small className='text-center'>(Consequence/s addressed by this control)</small>
              {this.state.ConsequencesList.length ? this.state.ConsequencesList.map((consequence, index) => (
                <div className='mb-2' key={index}>
                  <CheckBoxComponent
                    id={`cbConsequence_${consequence.Id}`}
                    name={`cbConsequence_${consequence.Id}`}
                    type="checkbox"
                    label={consequence.ShortName}
                    checked={this.state.ConnectedConsequences?.includes(consequence.Id)}
                    change={() => this.handleConsequenceChange(consequence.Id)}
                  />
                </div>
              )) : <span className='text-center'>Create a Consequence first to link to a control</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleControlConsequence}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            {this.state.ConsequencesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
          </ModalFooter>
        </Modal>
      </>
    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({
          UsersList: data.map(item => ({
            ...item,
            FullName: item.FirstName + " " + item.LastName
          }))
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/rootcauses/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RootCausesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/consequences/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ ConsequencesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ risks: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controls/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data);
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    setAuthToken(getAuthToken(), new Date());
  }

  async saveControls() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, controlsEditModal: false, controlRootModal: false, controlConsequenceModal: false, controlsModal: true });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.ControlId, CompanyId: userData.CompanyId, RiskId: this.state.Id, ControlNumber: this.state.ControlNumber, LevelId: this.state.LevelId, ITId: this.state.ITId, AutomatedId: this.state.AutomatedId, ShortName: this.state.ShortName, Description: this.state.RiskDescription, NeedsAssurance: this.state.NeedsAssurance, Critical: this.state.Critical, ControlResponsiblePersonId: this.state.ControlResponsiblePersonId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, ConnectedRootCauses: JSON.stringify(this.state.ConnectedRootCauses), ConnectedConsequences: JSON.stringify(this.state.ConnectedConsequences) }

    try {
      const response = await fetch('api/controls', {
        method: data.Id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Controls', content: 'The controls was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Controls', content: 'There was an error saving the controls!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/risks/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error deleting the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}
