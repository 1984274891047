import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import moment from 'moment';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import Attachments from '../Risk/Common/Attachments';
import Findings from '../Risk/Common/Findings';

export class MyStuff extends Component {

  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Engage', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
    ];

    this.state = {
      editData: [], risks: [], loading: true, editModal: false, viewModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      controlsEditModal: false, controlRootModal: false, controlConsequenceModal: false, assuranceEditModal: false, actionEditModal: false, addNewModal: false, newActionModal: false,
      Id: 0,
      CompanyId: 0,
      RiskNumber: '',
      Name: '',
      Description: '',
      RiskTypeId: 0,
      RiskCategoryId: 0,
      RiskStatusId: 0,
      SiteStructureId: 0,
      fTypeId: '-All-',
      SpeedOfOnset: 0,
      ResponsiblePersonId: 0,
      ResponsibleTeamId: 0,
      HasOpportunities: false,
      Opportunities: '',
      HasIndicators: false,
      Indicators: '',
      DeactivationReason: '',
      DateAdded: new Date(),
      AddedBy: 0,
      AddedByTeamId: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      CompanyList: [],
      RiskCategoryList: [],
      RiskStatusList: [],
      RiskTypeList: [],
      SiteStructureList: [],
      TeamList: [],
      UserList: [],
      SelectedItem: 0,
      active: 0,
      dueDateOnly: false,
      SelectedUser: getUserDetails().Id,
      UserName: "",
      SelectedTeam: 0,
      TeamName: "-All-",
      LoggerId: 0,
      LogTeamId: 0,
      ConsequencesList: [],
      RootCausesList: [],
      AssuranceList: [],
      AssuranceProviderList: [],
      FrequencyList: [],
      GapClosingActionsList: [],
      FindingList: [],
      FindingActionList: [],

    };
    this.toggleControlsEdit = this.toggleControlsEdit.bind(this);
    this.toggleControlRoot = this.toggleControlRoot.bind(this);
    this.toggleControlConsequence = this.toggleControlConsequence.bind(this);
    this.toggleAssurance = this.toggleAssurance.bind(this);
    this.toggleActionEdit = this.toggleActionEdit.bind(this);
    this.addNewModalToggle = this.addNewModalToggle.bind(this);
    this.addNewActionModalToggle = this.addNewActionModalToggle.bind(this);
  }

  toggleControlsEdit() {
    this.setState({
      controlsEditModal: !this.state.controlsEditModal
    });
  }

  toggleControlRoot() {
    this.setState({
      controlRootModal: !this.state.controlRootModal
    });
  }

  toggleControlConsequence() {
    this.setState({
      controlConsequenceModal: !this.state.controlConsequenceModal
    });
  }

  toggleAssurance() {
    this.setState({
      assuranceEditModal: !this.state.assuranceEditModal
    });
  }

  toggleActionEdit() {
    this.setState({
      actionEditModal: !this.state.actionEditModal
    });
  }

  addNewModalToggle() {
    this.setState({
      addNewModal: !this.state.addNewModal
    });
  }

  addNewActionModalToggle() {
    this.setState({
      newActionModal: !this.state.newActionModal
    });
  }

  handleRootCauseChange = (id) => {
    const { ConnectedRootCauses } = this.state;

    const updatedConnectedRootCauses = ConnectedRootCauses?.includes(id)
      ? ConnectedRootCauses.filter((causeId) => causeId !== id)
      : [...ConnectedRootCauses, id];

    this.setState({ ConnectedRootCauses: updatedConnectedRootCauses });
  };

  handleConsequenceChange = (id) => {
    const { ConnectedConsequences } = this.state;

    const updatedConnectedConsequences = ConnectedConsequences?.includes(id)
      ? ConnectedConsequences.filter((consequenceId) => consequenceId !== id)
      : [...ConnectedConsequences, id];

    this.setState({ ConnectedConsequences: updatedConnectedConsequences });
  };

  componentDidMount() {
    document.title = "Processing :: OnPoint RMS";
    this.loadData();
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  viewItem = async (itemData) => {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();

    //console.log(itemData)
    if (itemData.Type == "Risk") {
      const data = await this.state.editData.find((item) => { return item.RiskId === itemData.RiskId });
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.ResponsiblePersonName) {
        handleNavigate("/risk-edit/" + data.ReferenceNumber);
      } else {
        ToastUtility.show({
          title: 'Risks', content: 'You do not have permission to view this risk!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    } else if (itemData.Type == "Control") {
      const data = this.state.ControlList.find((item) => { return item.ControlNumber === itemData.ReferenceNumber });
      //console.log(`${userData.FirstName} ${userData.LastName}` == data.ResponsiblePersonName, data)
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.ControlResponsiblePerson) {
        this.setState({
          editId: data.Id,
          ControlId: data.Id,
          ControlNumber: data.ControlNumber,
          ShortName: data.ShortName,
          Assurances: data.Assurances,
          RiskDescription: data.Description,
          Critical: data.Critical,
          NeedsAssurance: data.NeedsAssurance,
          ControlResponsiblePersonId: data.ControlResponsiblePersonId,
          LevelId: data.LevelId,
          ITId: data.ITId,
          AutomatedId: data.AutomatedId,
          ConnectedRootCauses: data.ConnectedRootCauses,
          ConnectedConsequences: data.ConnectedConsequences,
        });

        this.setState({ controlsEditModal: true });
      } else {
        ToastUtility.show({
          title: 'Control', content: 'You do not have permission to edit this control!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    } else if (itemData.Type == "Assurance") {
      const data = this.state.AssuranceList.find((item) => { return item.ConrtolAssuranceNumber === itemData.ReferenceNumber });
      //console.log(`${userData.FirstName} ${userData.LastName}` == data.AssuranceResponsiblePerson, data)
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.AssuranceResponsiblePerson) {
        this.setState({ Id: data.Id, ConrtolAssuranceNumber: data.ConrtolAssuranceNumber, ControlId: data.ControlId, ScopeId: data.ScopeId, ShortName: data.ShortName, Type: data.Type, Location: data.Location, AssuranceStatusId: data.AssuranceStatusId, StopIfDeactivated: data.StopIfDeactivated, ReviewStartingMonth: data.ReviewStartingMonth, Details: data.Details, ClarificationNotes: data.ClarificationNotes, AssuranceResponsiblePersonId: data.AssuranceResponsiblePersonId, TriggeredEventsResponsiblePersonId: data.TriggeredEventsResponsiblePersonId, LoA: data.LoA, AssuranceProviderId: data.AssuranceProviderId, ServiceProvider: data.ServiceProvider, QuestionnairesRequired: data.QuestionnairesRequired, FrequencyId: data.FrequencyId, EndRepeatId: data.EndRepeatId, MonthsToReview: data.MonthsToReview, StartsOnDate: data.StartsOnDate, EndsOnDate: data.EndsOnDate, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
        this.setState({ editId: data.Id, assuranceEditModal: true });
      } else {
        ToastUtility.show({
          title: 'Assurance', content: 'You do not have permission to edit this assurance!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    } else if (itemData.Type == "GCA") {
      const data = this.state.GapClosingActionsList.find((item) => { return item.ActionNumber === itemData.ReferenceNumber });
      console.log(`${userData.FirstName} ${userData.LastName}` == data.ResponsiblePersonName, data)
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.ActionResponsiblePerson) {
        this.setState({ Id: data.Id, ActionNumber: data.ActionNumber, RiskId: data.RiskId, ShortName: data.ShortName, Action: data.Action, IntendedOutcome: data.IntendedOutcome, ActionNotes: data.ActionNotes, ActionResponsiblePersonId: data.ActionResponsiblePersonId, DateDue: moment(data.DateDue).format(), RevisedDateDue: data.RevisedDateDue, StatusUpdateFrequencyId: data.StatusUpdateFrequencyId, StatusUpdateDate: data.StatusUpdateDate, EstimatedCompletionDate: data.EstimatedCompletionDate, ActualCompletionDate: data.ActualCompletionDate, ActionStatusId: data.ActionStatusId, StatusPercentage: data.StatusPercentage, StatusBy: data.StatusBy, StatusAsAt: data.StatusAsAt, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
        this.setState({ editId: data.Id, actionEditModal: true });
      } else {
        ToastUtility.show({
          title: 'GCA', content: 'You do not have permission to edit this GCA!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    } else if (itemData.Type == "Finding") {
      const data = this.state.FindingList.find((item) => { return item.Finding === itemData.ReferenceNumber });
      //console.log(`${userData.FirstName} ${userData.LastName}` == data.ResponsiblePersonName, data)
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.ResponsiblePerson) {
        this.setState({
          Id: data.Id,
          CompanyId: data.CompanyId,
          FindingFromId: data.FindingFromId,
          Finding: data.Finding,
          ShortName: data.ShortName,
          Description: data.Description,
          Type: data.Type,
          ResponsiblePersonId: data.ResponsiblePersonId,
          TargetDate: data.TargetDate,
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Status: data.Status
        });
        this.setState({ editId: data.Id, addNewModal: true });
      } else {
        ToastUtility.show({
          title: 'Finding', content: 'You do not have permission to edit this finding!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    } else if (itemData.Type == "Finding Action") {
      const data = this.state.FindingActionList.find((item) => { return item.FindingActionNumber === itemData.ReferenceNumber });
      //console.log(`${userData.FirstName} ${userData.LastName}` == data.ResponsiblePersonName, data)
      if (userData.UserRole > 9 || `${userData.FirstName} ${userData.LastName}` == data.ResponsiblePerson) {
        this.setState({
          Id: data.Id,
          RiskId: data.RiskId,
          FindingId: data.FindingId,
          CompanyId: data.CompanyId,
          FindingActionNumber: data.FindingActionNumber,
          ShortName: data.ShortName,
          Action: data.Action,
          IntendedOutcome: data.IntendedOutcome,
          ActionNotes: data.ActionNotes,
          DateDue: data.DateDue,
          RevisedDateDue: data.RevisedDateDue,
          ResponsiblePersonId: data.ResponsiblePersonId,
          StatusUpdateFrequencyId: data.StatusUpdateFrequencyId,
          FirstUpdateDate: data.FirstUpdateDate,
          StatusDate: data.StatusDate,
          StatusById: data.StatusBy,
          StatusId: data.StatusId,
          Percentage: data.Percentage,
          EstimatedCompletionDate: data.EstimatedCompletionDate,
          ActualCompletionDate: data.ActualCompletionDate,
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Status: data.Status,
        });
        this.setState({ editId: data.Id, newActionModal: true });
      } else {
        ToastUtility.show({
          title: 'Finding Action', content: 'You do not have permission to edit this finding action!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
        return;
      }

    }
  };

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      case 'Engage':
        this.viewItem(args.rowData);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Type' width='50' headerText="Type" />
          <ColumnDirective field='Status' width='70' headerText="Status" />
          <ColumnDirective field='ReferenceNumber' width='50' headerText="Ref. #" />
          <ColumnDirective field='ShortName' width='100' headerText="Short Name" />
          <ColumnDirective field='ResponsiblePersonName' width='70' headerText="Resp. Person" />
          <ColumnDirective field='ResponsibleTeamName' width='70' headerText="Resp. Team" />
          <ColumnDirective field='DueDate' width='70' headerText="Date Due" />
          <ColumnDirective field='Completion' width='50' headerText="Completion" />
          <ColumnDirective field='Completed' width='50' headerText="Completion" />
          <ColumnDirective field='AddedByName' width='70' headerText="Logger" />
          <ColumnDirective field='AddedByTeamName' width='70' headerText="Log Team" />
          <ColumnDirective field='Notes' width='50' headerText="My Notes" />
          <ColumnDirective headerText='Actions' width='50' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  generateGrid = () => {
    const gridItems = [];

    for (let row = 0; row < 2; row++) {
      for (let col = 0; col < 2; col++) {
        const gridItemKey = `${row}-${col}`;
        gridItems.push(<div key={gridItemKey} className="grid-item"></div>);
      }
    }

    return (
      <div className="grid-container">
        {gridItems}
      </div>
    );
  };

  render() {
    let data = this.state.editData;
    const types = [{ Title: "-All-", Id: 0 }, { Title: "Risk", Id: 1 }, { Title: "Control", Id: 2 }, { Title: "Assurance", Id: 3 }, { Title: "GCA", Id: 4 }, { Title: "Finding", Id: 5 }, { Title: "Finding Action", Id: 6 },];
    const statusUpdates = [{ Title: "NYS", Id: 0 }, { Title: "On Track", Id: 1 }, { Title: "Stalling", Id: 2 }, { Title: "Completed", Id: 3 }];
    const riskStatuses = [{ Title: "Draft", Id: 0 }, { Title: "Active", Id: 1 }, { Title: "Deactivated", Id: 2 }];

    const levels = [{ Title: "unclassified", Id: 0 }, { Title: "entity level", Id: 1 }, { Title: "activity level", Id: 2 }]
    const isIT = [{ Title: "unclassified", Id: 0 }, { Title: "IT general control", Id: 1 }, { Title: "IT application control", Id: 2 }]
    const isAutomated = [{ Title: "unclassified", Id: 0 }, { Title: "automated", Id: 1 }, { Title: "manual", Id: 2 }]

    const scopesReview = [{ Title: "-SELECT-", Id: 0 }, { Title: "All the  Operating Effectiveness Checks", Id: 1 }, { Title: "All the Design Effectiveness Checks", Id: 2 }, { Title: "All the Doc. Completeness Checks", Id: 3 }, { Title: "All the Checks &/or all the Reviews", Id: 4 }]
    const scopesCheck = [{ Title: "-SELECT-", Id: 0 }, { Title: "Operating Effectiveness", Id: 1 }, { Title: "Design Effectiveness", Id: 2 }, { Title: "Doc. Completeness", Id: 3 }]
    const statuses = [{ Title: "-SELECT-", Id: 0 }, { Title: "Draft", Id: 1 }, { Title: "Active", Id: 2 }, { Title: "Deactivated", Id: 3 }]
    const endRepeat = [{ Title: "-SELECT-", Id: 0 }, { Title: "Never", Id: 1 }, { Title: "After", Id: 2 }, { Title: "On Date", Id: 3 }]

    const frequencies = [{ Title: "NYS", Id: 0 }, { Title: "Ad hoc", Id: 1 }, { Title: "Daily", Id: 2 }, { Title: "Twice a week", Id: 3 }, { Title: "Every week", Id: 4 }, { Title: "Every 2 weeks", Id: 5 }, { Title: "Every 3 weeks", Id: 6 }, { Title: "Every 4 weeks", Id: 7 }, { Title: "Every 5 weeks", Id: 8 }, { Title: "Every 6 weeks", Id: 9 }, { Title: "Every 7 weeks", Id: 10 }, { Title: "Every 8 weeks", Id: 11 }]

    if (!this.state.loading && this.state.editData.length > 0)
      data = data.map(item => ({ ...item, Status: item.Type == "Finding Action" ? statusUpdates.find(status => status.Id == item.Status)?.Title : item.Type == "Risk" ? riskStatuses.find(status => status.Id == item.Status)?.Title : item.Status }))

    if (this.state.fTypeId !== "-All-") {
      data = data.filter(item => item.Type === this.state.fTypeId || (!this.state.fTypeId && !item.Type))
    }
    if (this.state.UserName !== "-All-") {
      data = data.filter(item => item.ResponsiblePersonName === this.state.UserName || (!this.state.UserName && !item.ResponsiblePersonName))
    }
    if (this.state.TeamName !== "-All-") {
      data = data.filter(item => item.ResponsibleTeamName === this.state.TeamName || (!this.state.TeamName && !item.ResponsibleTeamName))
    }
    if (this.state.LoggerId !== 0) {
      data = data.filter(item => item.Logger === this.state.LoggerId || (!this.state.LoggerId && !item.Logger))
    }
    if (this.state.LogTeamId !== 0) {
      data = data.filter(item => item.LogTeam === this.state.LogTeamId || (!this.state.LogTeamId && !item.LogTeam))
    }

    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : MyStuff.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    console.log(this.state);
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h1 className='mb-0'>Processing</h1>
              <small>(Find & select the item you wish to work on)</small>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To DashBoard</Link>
              {/*<Button color="primary" size="sm" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='mb-4'>
              <div className='card card-body shadow mb-3'>
                <b>Filters</b>
                <div className='row'>
                  <div className='col-md'>
                    <DropDownListComponent id='ddFilterId' name='ddFilterId' placeholder='Type' showClearButton={true} dataSource={types} fields={{ text: 'Title', value: 'Title' }} floatLabelType='Always' value={this.state.fTypeId} change={e => this.setState({ fTypeId: e.itemData ? e.itemData.Title : '-All-' })} />
                  </div>
                  <div className='col-md'>
                    <DropDownListComponent id='ddPersonId' name='ddPersonId' placeholder='Responsible Person' dataSource={[{ FullName: "-All-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.SelectedUser} change={e => this.setState({ SelectedUser: e.value, UserName: e.itemData ? e.itemData.FullName : "" })} />
                  </div>
                  <div className='col-md'>
                    <DropDownListComponent id='ddResponsibleTeamId' name='ddResponsibleTeamId' placeholder='Responsible Team' dataSource={[{ Name: "-All-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.SelectedTeam} change={e => this.setState({ SelectedTeam: e.value, TeamName: e.itemData ? e.itemData.Name : "" })} />
                  </div>
                  <div className='col-md'>
                    <DropDownListComponent id='ddLoggerId' name='ddLoggerId' placeholder='Logger' dataSource={[{ FullName: "-All-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.LoggerId} change={e => this.setState({ LoggerId: e.value })} />
                  </div>
                  <div className='col-md'>
                    <DropDownListComponent id='ddLogTeamId' name='ddLogTeamId' placeholder='Log Team' dataSource={[{ Name: "-All-", Id: 0 }, ...this.state.TeamList]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.LogTeamId} change={e => this.setState({ LogTeamId: e.value })} />
                  </div>
                </div>
              </div>
              {contents}
            </Col>
          </Row>
        </div>

        { /*-----------------------------------------------Conrtol-------------------------------------------------------*/}

        <Modal isOpen={this.state.controlsEditModal} toggle={this.toggleControlsEdit} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggleControlsEdit} close={<button className="close" onClick={this.toggleControlsEdit}><i className="fas fa-times"></i></button>}>Controls</ModalHeader>
          <ModalBody>
            <div className='text-center'>
              <em className='my-3 fw-bold'>(Add new control or click on 'edit' to do more with an existing control )</em>
            </div>
            <div className='row'>
              {this.state.editId > 0 && <div className='d-flex risk-status mb-3'>
                <Attachments Id={this.state.editId} Type={2} />
                <Findings Id={this.state.editId} Type={2} />
              </div>}
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbRiskDescription' name='tbRiskDescription' placeholder='Description' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.RiskDescription} onChange={e => this.setState({ RiskDescription: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddControlResponsiblePersonId' name='ddControlResponsiblePersonId' placeholder='Responsible Person (Control)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ControlResponsiblePersonId} change={e => this.setState({ ControlResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='col-md-4 align-self-center'>
                <CheckBoxComponent id='cbCritical' name='cbCritical' type='checkbox' label='Critical' checked={this.state.Critical} onChange={e => this.setState({ Critical: e.target.checked })} />
              </div>
              <div className='col-md-4 align-self-center'>
                <CheckBoxComponent id='cbNeedsAssurance' name='cbNeedsAssurance' type='checkbox' label='Needs Assurance' checked={this.state.NeedsAssurance} onChange={e => this.setState({ NeedsAssurance: e.target.checked })} />
              </div>
            </div>
            <div className='text-center'>
              <h6 className='my-3 fw-bold'>Control Classification</h6>
            </div>
            <div className='row'>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddLevelId' name='ddLevelId' placeholder='Level' dataSource={levels} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.LevelId} change={e => this.setState({ LevelId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddITId' name='ddITId' placeholder='IT/non-IT' dataSource={isIT} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.ITId} change={e => this.setState({ ITId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddAutoId' name='ddAutoId' placeholder='Automated/Manual' dataSource={isAutomated} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.AutomatedId} change={e => this.setState({ AutomatedId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
            {this.state.editId > 0 && <div>
              <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlRoot() }}>Select Root Causes</Button>
              <Button className='me-1' color="info" size="sm" onClick={() => { this.toggleControlConsequence() }}>Select Consequences</Button>
            </div>}
            <div className='ms-auto'>
              <Button className='ms-1' color="dark" size="sm" onClick={() => this.toggleControlsEdit()}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
              <Button className='ms-1' color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.controlRootModal} toggle={this.toggleControlRoot} className={this.props.className} scrollable size="sm" backdrop="static">
          <ModalHeader toggle={this.toggleControlRoot} close={<button className="close" onClick={this.toggleControlRoot}><i className="fas fa-times"></i></button>}>Control Root Causes</ModalHeader>
          <ModalBody>
            <div className='row'>
              <small className='text-center'>(Root cause/s addressed by this control)</small>
              {this.state.RootCausesList.length ? this.state.RootCausesList.map((cause, index) => (
                <div className='mb-2' key={index}>
                  <CheckBoxComponent
                    id={`cbRootCause_${cause.Id}`}
                    name={`cbRootCause_${cause.Id}`}
                    type="checkbox"
                    label={cause.ShortName}
                    checked={this.state.ConnectedRootCauses?.includes(cause.Id)}
                    onChange={() => this.handleRootCauseChange(cause.Id)}
                  />
                </div>
              )) : <span className='text-center'>Create a Root Cause first to link to a control</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleControlRoot}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            {this.state.RootCausesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.controlConsequenceModal} toggle={this.toggleControlConsequence} className={this.props.className} scrollable size="sm" backdrop="static">
          <ModalHeader toggle={this.toggleControlConsequence} close={<button className="close" onClick={this.toggleControlConsequence}><i className="fas fa-times"></i></button>}>Control Consequences</ModalHeader>
          <ModalBody>
            <div className='row'>
              <small className='text-center'>(Consequence/s addressed by this control)</small>
              {this.state.ConsequencesList.length ? this.state.ConsequencesList.map((consequence, index) => (
                <div className='mb-2' key={index}>
                  <CheckBoxComponent
                    id={`cbConsequence_${consequence.Id}`}
                    name={`cbConsequence_${consequence.Id}`}
                    type="checkbox"
                    label={consequence.ShortName}
                    checked={this.state.ConnectedConsequences?.includes(consequence.Id)}
                    onChange={() => this.handleConsequenceChange(consequence.Id)}
                  />
                </div>
              )) : <span className='text-center'>Create a Consequence first to link to a control</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggleControlConsequence}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            {this.state.ConsequencesList.length ? <Button color="success" size="sm" onClick={() => this.saveControls()}>Save <i className="far fa-check-circle ms-2"></i></Button> : ""}
          </ModalFooter>
        </Modal>

        { /*-----------------------------------------------Conrtol Asurance-------------------------------------------------------*/}

        <Modal isOpen={this.state.assuranceEditModal} toggle={this.toggleAssurance} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggleAssurance} close={<button className="close" onClick={this.toggleAssurance}><i className="fas fa-times"></i></button>}>Assurances</ModalHeader>
          <ModalBody>
            <div className='row'>
              {this.state.editId > 0 && <div className='d-flex risk-status mb-3'>
                <Attachments Id={this.state.editId} Type={4} />
              </div>}
              <div className='mb-3 col-md-8'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbType' name='tbType' placeholder='Type' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={false} value={this.state.Type == 1 ? 'Check' : 'Review'} />
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddScopeId' name='ddScopeId' placeholder={this.state.Type == 1 ? 'Scope of the check' : 'Scope of the review'} dataSource={this.state.Type == 1 ? scopesCheck : scopesReview} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.ScopeId} change={e => this.setState({ ScopeId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-3'>
                <TextBoxComponent id='tbLocation' name='tbLocation' placeholder='Location' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Location} onChange={e => this.setState({ Location: e.target.value })} />
              </div>

              <div className='mb-3 col-md-3'>
                <DropDownListComponent id='ddAssuranceStatusId' name='ddAssuranceStatusId' placeholder='Status' dataSource={statuses} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.AssuranceStatusId} change={e => this.setState({ AssuranceStatusId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbDetails' name='tbDetails' placeholder='Details' multiline type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Details} onChange={e => this.setState({ Details: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbClarificationNotes' name='tbClarificationNotes' placeholder='Clarification Notes' multiline type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ClarificationNotes} onChange={e => this.setState({ ClarificationNotes: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4 align-self-end'>
                <CheckBoxComponent id='cbStopIfDeactivated' name='cbStopIfDeactivated' type='checkbox' label='Stop if Risk gets Deactivated?' checked={this.state.StopIfDeactivated} onChange={e => this.setState({ StopIfDeactivated: e.target.checked })} /> &nbsp; <div id='cbStopIfDeactivatedError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddAssuranceResponsiblePersonId' name='ddAssuranceResponsiblePersonId' placeholder='Resp Person (Assurance)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.AssuranceResponsiblePersonId} change={e => this.setState({ AssuranceResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddTriggeredEventsResponsiblePersonId' name='ddTriggeredEventsResponsiblePersonId' placeholder='Resp Person (Triggered Events)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.TriggeredEventsResponsiblePersonId} change={e => this.setState({ TriggeredEventsResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-6'>
                <DropDownListComponent id='ddAssuranceProviderId' name='ddAssuranceProviderId' placeholder='Assurance Provider' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.AssuranceProviderList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.AssuranceProviderId} change={e => this.setState({ AssuranceProviderId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbServiceProvider' name='tbServiceProvider' placeholder='Service Provider' floatLabelType='Always' value={this.state.ServiceProvider} change={e => this.setState({ ServiceProvider: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-3'>
                <NumericTextBoxComponent id='tbLoA' name='tbLoA' min={0} max={100} placeholder='LoA' floatLabelType='Always' showClearButton={true} value={this.state.LoA} onChange={e => this.setState({ LoA: e.target.value })} />{/* <div id='tbRiskAnalysisError' className='error-message' />*/}
              </div>

              <div className='mb-3 col-md-3'>
                <NumericTextBoxComponent id='tbQuestionnairesRequired' name='tbQuestionnairesRequired' min={0} max={100} placeholder='No. Questionnaires required' floatLabelType='Always' showClearButton={true} value={this.state.QuestionnairesRequired} onChange={e => this.setState({ QuestionnairesRequired: e.target.value })} />{/* <div id='tbRiskAnalysisError' className='error-message' />*/}
              </div>
            </div>

            <div className='row'>
              {this.state.Type == 2 ?
                <div className='card border mx-2 col-md'>
                  <div className='text-center'>
                    <h6 className='my-3 fw-bold'>Initial period to review</h6>
                  </div>
                  <div className='row'>
                    <div className='mb-3 col-md-6'>
                      <DatePickerComponent placeholder='Starting Month' floatLabelType='Always' value={this.state.ReviewStartingMonth} onChange={(e) => { this.setState({ ReviewStartingMonth: moment(e.target.value).format() }) }} />
                    </div>
                    <div className='mb-3 col-md-6'>
                      <NumericTextBoxComponent id='tbMonthsToReview' name='tbMonthsToReview' min={0} max={100} placeholder='No. Months to Review' floatLabelType='Always' showClearButton={true} value={this.state.MonthsToReview} onChange={e => this.setState({ MonthsToReview: e.target.value })} />{/* <div id='tbRiskAnalysisError' className='error-message' />*/}
                    </div>
                  </div>
                </div> : ""}

              <div className='card border mx-2 col-md'>
                <div className='text-center'>
                  <h6 className='my-3 fw-bold'>Repeat information</h6>
                </div>
                <div className='row'>
                  <div className='mb-3 col-md-6'>
                    <DropDownListComponent id='ddFrequencyId' name='ddFrequencyId' placeholder='Frequency' dataSource={[{ Title: "-SELECT-", Id: 0 }, ...this.state.FrequencyList]} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.FrequencyId} change={e => this.setState({ FrequencyId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                  </div>
                  <div className='mb-3 col-md-6'>
                    <DropDownListComponent id='ddEndRepeatId' name='ddEndRepeatId' placeholder='End repeat' dataSource={endRepeat} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.EndRepeatId} change={e => this.setState({ EndRepeatId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                  </div>
                </div>
              </div>

              <div className='card border mx-2 col-md'>
                <div className='text-center'>
                  <h6 className='my-3 fw-bold'>First Event</h6>
                </div>
                <div className='row'>
                  <div className='mb-3 col-md-6'>
                    <DatePickerComponent placeholder='Starts On' floatLabelType='Always' value={this.state.StartsOnDate} onChange={(e) => { this.setState({ StartsOnDate: moment(e.target.value).format() }) }} />
                  </div>
                  <div className='mb-3 col-md-6'>
                    <DatePickerComponent placeholder='Ends On' floatLabelType='Always' value={this.state.EndsOnDate} onChange={(e) => { this.setState({ EndsOnDate: moment(e.target.value).format() }) }} />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={() => this.toggleAssurance()}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={() => this.saveAssurance(this.state.editId)}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        { /*-----------------------------------------------GCA-------------------------------------------------------*/}

        <Modal isOpen={this.state.actionEditModal} toggle={this.toggleActionEdit} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggleActionEdit} close={<button className="close" onClick={this.toggleActionEdit}><i className="fas fa-times"></i></button>}>Gap Closing Actions</ModalHeader>
          <ModalBody>
            <div className='text-center'>
              <em className='my-3 fw-bold'>(Add/Edit GCA details)</em>
            </div>
            <div className='row'>
              {this.state.editId > 0 && <div className='d-flex risk-status mb-3'>
                <Attachments Id={this.state.editId} Type={3} />
                <Findings Id={this.state.editId} Type={3} />
              </div>}
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbAction' name='tbAction' placeholder='Action' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Action} onChange={e => this.setState({ Action: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbIntendedOutcome' name='tbIntendedOutcome' placeholder='Intended Outcome / Deliverable' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.IntendedOutcome} onChange={e => this.setState({ IntendedOutcome: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbActionNotes' name='tbActionNotes' placeholder='Action-creator notes' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ActionNotes} onChange={e => this.setState({ ActionNotes: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddActionResponsiblePersonId' name='ddActionResponsiblePersonId' placeholder='Responsible Person (Action)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ActionResponsiblePersonId} change={e => this.setState({ ActionResponsiblePersonId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='Date Due' floatLabelType='Always' value={this.state.DateDue} onChange={(e) => { this.setState({ DateDue: e.target.value }) }} />
              </div>
              {this.state.editId > 0 ? <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='Revised Due Date' floatLabelType='Always' value={this.state.RevisedDateDue} onChange={(e) => { this.setState({ RevisedDateDue: moment(e.target.value).format() }) }} />
              </div> : ""}
            </div>
            <div className='card'>
              <div className='text-center'>
                <h6 className='my-3 fw-bold'>Required Status Updates</h6>
              </div>
              <div className='row px-2'>
                <div className='mb-3 col-md-6'>
                  <DropDownListComponent id='ddStatusUpdateFrequencyId' name='ddStatusUpdateFrequencyId' placeholder='Frequency' dataSource={frequencies} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.StatusUpdateFrequencyId} change={e => this.setState({ StatusUpdateFrequencyId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='col-md-6 mb-3 '>
                  <DatePickerComponent placeholder='1st one on' floatLabelType='Always' value={this.state.StatusUpdateDate} onChange={(e) => { this.setState({ StatusUpdateDate: moment(e.target.value).format() }) }} />
                </div>
              </div>
            </div>
            {this.state.editId > 0 ? <>
              <div className='text-center'>
                <h6 className='my-3 fw-bold'>Status</h6>
              </div>
              <div className='row'>
                <div className='mb-3 col-md-8'>
                  <DropDownListComponent id='ddActionStatusId' name='ddActionStatusId' placeholder='Status' dataSource={statusUpdates} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.ActionStatusId} change={e => this.setState({ ActionStatusId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='mb-3 col-md-4'>
                  <NumericTextBoxComponent id='tbStatusPercentage' name='tbStatusPercentage' min={0} max={100} placeholder='% Compl' floatLabelType='Always' showClearButton={true} value={this.state.StatusPercentage} onChange={e => this.setState({ StatusPercentage: e.target.value })} />{/* <div id='tbRiskAnalysisError' className='error-message' />*/}
                </div>
                <div className='col-md-4 mb-3 '>
                  <DatePickerComponent placeholder='As At' floatLabelType='Always' value={this.state.StatusAsAt} onChange={(e) => { this.setState({ StatusAsAt: moment(e.target.value).format() }) }} />
                </div>
                <div className='col-md-4 mb-3 '>
                  <DatePickerComponent placeholder='Est Compl Date' floatLabelType='Always' value={this.state.EstimatedCompletionDate} onChange={(e) => { this.setState({ EstimatedCompletionDate: moment(e.target.value).format() }) }} />
                </div>
                <div className='col-md-4 mb-3 '>
                  <DatePickerComponent placeholder='Act Compl Date' floatLabelType='Always' value={this.state.ActualCompletionDate} onChange={(e) => { this.setState({ ActualCompletionDate: moment(e.target.value).format() }) }} />
                </div>
              </div></> : ""}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={() => this.toggleActionEdit()}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={() => this.saveAction(this.state.editId)}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        { /*-----------------------------------------------Finding-------------------------------------------------------*/}

        <Modal isOpen={this.state.addNewModal} toggle={this.addNewModalToggle} className="modal-dialog modal-xl">
          <ModalHeader>Edit FINDING</ModalHeader>
          <ModalBody>
            <Row className='mb-3'>
              <Col>
                <label>Short Name</label>
                <TextBoxComponent placeholder="Short Name" value={this.state.ShortName} onChange={(e) => {
                  this.setState({ ShortName: e.target.value })
                }} />
                <div className='error-message' id="error-shortName"></div>
              </Col>
              <Col>
                <label>Responsible Person</label>
                <DropDownListComponent
                  placeholder='Select Responsible Person'
                  value={this.state.ResponsiblePersonId}
                  fields={{ text: "FullName", value: "Id" }}
                  dataSource={this.state.UserList}
                  onChange={(e) => {
                    this.setState({ ResponsiblePersonId: e.target.value })
                  }} />
                <div className='error-message' id="error-responsiblePerson"></div>
              </Col>
              <Col>
                <label>Target Date</label>
                <DatePickerComponent value={this.state.TargetDate} onChange={(e) => {
                  this.setState({ TargetDate: e.target.value })
                }} />
                <div className='error-message' id="error-targetDate"></div>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Description</label>
                <TextBoxComponent multiline value={this.state.Description} onChange={(e) => {
                  this.setState({ Description: e.target.value })
                }} />
                <div className='error-message' id="error-description"></div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => this.addNewModalToggle()}>CANCEL</Button>
            <Button color="success" onClick={() => this.saveData(this.editData)}>SAVE</Button>
          </ModalFooter>
        </Modal>

        { /*-----------------------------------------------Finding Action-------------------------------------------------------*/}

        <Modal isOpen={this.state.newActionModal} toggle={this.addNewActionModalToggle} className="modal-dialog modal-xl">
          <ModalHeader>EDIT FINDING ACTION</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ShortName} onChange={e => this.setState({ ShortName: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbAction' name='tbAction' placeholder='Action' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.Action} onChange={e => this.setState({ Action: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbIntendedOutcome' name='tbIntendedOutcome' placeholder='Intended Outcome / Deliverable' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.IntendedOutcome} onChange={e => this.setState({ IntendedOutcome: e.target.value })} />
              </div>

              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbActionNotes' name='tbActionNotes' placeholder='Action-creator notes' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.state.ActionNotes} onChange={e => this.setState({ ActionNotes: e.target.value })} />
              </div>

              <div className='mb-3 col-md-4'>
                <DropDownListComponent id='ddResponsiblePersonId' name='ddResponsiblePersonId' placeholder='Responsible Person (Action)' dataSource={[{ FullName: "-SELECT-", Id: 0 }, ...this.state.UserList]} fields={{ text: 'FullName', value: 'Id' }} floatLabelType='Always' value={this.state.ResponsiblePersonId} change={e => this.setState({ ResponsiblePersonId: e.value })} />{/*<div id='ddResponsiblePersonIdError' className='error-message' />*/}
              </div>
              <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='Date Due' floatLabelType='Always' value={this.state.DateDue} onChange={(e) => { this.setState({ DateDue: e.target.value }) }} />
              </div>
              {this.state.editId > 0 ?
                <div className='col-md-4 mb-3 '>
                  <DatePickerComponent placeholder='Revised Due Date' floatLabelType='Always' value={this.state.RevisedDateDue} onChange={(e) => { this.setState({ RevisedDateDue: moment(e.target.value).format() }) }} />
                </div>
                : ""}
            </div>
            <div className='card'>
              <div className='text-center'>
                <h6 className='my-3 fw-bold'>Required Status Updates</h6>
              </div>
              <div className='row px-2'>
                <div className='mb-3 col-md-6'>
                  <DropDownListComponent id='ddStatusUpdateFrequencyId' name='ddStatusUpdateFrequencyId' placeholder='Frequency' dataSource={frequencies} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.StatusUpdateFrequencyId} change={e => this.setState({ StatusUpdateFrequencyId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
                </div>
                <div className='col-md-6 mb-3 '>
                  <DatePickerComponent placeholder='1st one on' floatLabelType='Always' value={this.state.FirstUpdateDate} onChange={(e) => { this.setState({ FirstUpdateDate: moment(e.target.value).format() }) }} />
                </div>
              </div>
            </div>
            {/*{this.state.editId > 0 ? <>*/}
            <div className='text-center'>
              <h6 className='my-3 fw-bold'>Status</h6>
            </div>
            <div className='row'>
              <div className='mb-3 col-md-8'>
                <DropDownListComponent id='ddStatusId' name='ddStatusId' placeholder='Status' dataSource={statusUpdates} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={this.state.StatusId} change={e => this.setState({ StatusId: e.value })} />{/*<div id='tbStakeholderIdError' className='error-message' />*/}
              </div>
              <div className='mb-3 col-md-4'>
                <NumericTextBoxComponent id='tbPercentage' name='tbPercentage' min={0} max={100} placeholder='% Compl' floatLabelType='Always' showClearButton={true} value={this.state.Percentage} onChange={e => this.setState({ Percentage: e.target.value })} />{/* <div id='tbRiskAnalysisError' className='error-message' />*/}
              </div>
              <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='As At' floatLabelType='Always' value={this.state.StatusDate} onChange={(e) => { this.setState({ StatusDate: moment(e.target.value).format() }) }} />
              </div>
              <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='Est Compl Date' floatLabelType='Always' value={this.state.EstimatedCompletionDate} onChange={(e) => { this.setState({ EstimatedCompletionDate: moment(e.target.value).format() }) }} />
              </div>
              {this.state.editId > 0 ? <div className='col-md-4 mb-3 '>
                <DatePickerComponent placeholder='Act Compl Date' floatLabelType='Always' value={this.state.ActualCompletionDate} onChange={(e) => { this.setState({ ActualCompletionDate: moment(e.target.value).format() }) }} />
              </div> : ""}
            </div>
            {/*</> : ""}*/}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => this.addNewActionModalToggle()}>CANCEL</Button>
            <Button color="success" onClick={() => this.saveActionItem(this.state.editId)}>SAVE</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  async loadData() {
    const { handleNavigate, userOptions } = this.context;
    //console.log(userOptions);
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/userdata/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data?.map(item => ({ ...item, FullName: `${item.FirstName} ${item.LastName}` }))
        this.setState({ UserList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/teams/getcompanyteams/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, SelectedUsers: JSON.parse(item.SelectedUsers) }))
        this.setState({ TeamList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/rootcauses/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ RootCausesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/consequences/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ ConsequencesList: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controls/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        this.setState({ ControlList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/assuranceproviders/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ AssuranceProviderList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controlassurances/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ AssuranceList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/frequencies/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ FrequencyList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/gapclosingactions/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ GapClosingActionsList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/findings/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ FindingList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/findingactions/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ FindingActionList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risks/getalluserstuff/${userData.UserId}/${userData.UserRole}/${userData.CompanyId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    setAuthToken(getAuthToken(), new Date());
  }

  async saveControls() {
    const { handleNavigate } = this.context;
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, controlsEditModal: false, controlRootModal: false, controlConsequenceModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.ControlId, CompanyId: userData.CompanyId, RiskId: this.state.Id, ControlNumber: this.state.ControlNumber, LevelId: this.state.LevelId, ITId: this.state.ITId, AutomatedId: this.state.AutomatedId, ShortName: this.state.ShortName, Description: this.state.RiskDescription, NeedsAssurance: this.state.NeedsAssurance, Critical: this.state.Critical, ControlResponsiblePersonId: this.state.ControlResponsiblePersonId, DateAdded: new Date(), AddedBy: userData.Id, DateModified: new Date(), ModifiedBy: userData.Id, Status: 1, ConnectedRootCauses: JSON.stringify(this.state.ConnectedRootCauses), ConnectedConsequences: JSON.stringify(this.state.ConnectedConsequences) }

    try {
      const response = await fetch('api/controls', {
        method: data.Id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Controls', content: 'The controls was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Controls', content: 'There was an error saving the controls!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveAssurance(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, assuranceEditModal: false, addNewModal: false, showSuccess: false });
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    var data = {
      Id: dataId,
      ShortName: this.state.ShortName,
      ControlId: this.state.ControlId,
      ConrtolAssuranceNumber: this.state.ConrtolAssuranceNumber,
      ScopeId: this.state.ScopeId,
      Type: this.state.Type,
      Location: this.state.Location,
      AssuranceStatusId: this.state.AssuranceStatusId,
      StopIfDeactivated: this.state.StopIfDeactivated,
      Details: this.state.Details,
      ClarificationNotes: this.state.ClarificationNotes,
      AssuranceResponsiblePersonId: this.state.AssuranceResponsiblePersonId,
      TriggeredEventsResponsiblePersonId: this.state.TriggeredEventsResponsiblePersonId,
      LoA: this.state.LoA,
      AssuranceProviderId: this.state.AssuranceProviderId,
      ServiceProvider: this.state.ServiceProvider,
      QuestionnairesRequired: this.state.QuestionnairesRequired,
      FrequencyId: this.state.FrequencyId,
      EndRepeatId: this.state.EndRepeatId,
      MonthsToReview: this.state.MonthsToReview,
      ReviewStartingMonth: this.state.ReviewStartingMonth,
      StartsOnDate: this.state.StartsOnDate,
      EndsOnDate: this.state.EndsOnDate,
      DateAdded: this.state.DateAdded,
      AddedBy: this.state.AddedBy,
      DateModified: this.state.DateModified,
      ModifiedBy: this.state.ModifiedBy,
      Status: 1,
      CompanyId: userData.CompanyId,
    }

    try {
      const response = await fetch('api/controlassurances', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Control Assurances', content: 'The control assurances was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Control Assurances', content: 'There was an error saving the control assurances!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveAction(dataId) {
    const userData = getUserDetails();
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, actionEditModal: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: dataId,
      ActionNumber: this.state.ActionNumber,
      RiskId: this.state.RiskId,
      ShortName: this.state.ShortName,
      Action: this.state.Action,
      IntendedOutcome: this.state.IntendedOutcome,
      ActionNotes: this.state.ActionNotes,
      ActionResponsiblePersonId: this.state.ActionResponsiblePersonId,
      DateDue: this.state.DateDue,
      RevisedDateDue: this.state.RevisedDateDue ? this.state.RevisedDateDue : "",
      StatusUpdateFrequencyId: this.state.StatusUpdateFrequencyId,
      StatusUpdateDate: this.state.StatusUpdateDate,
      EstimatedCompletionDate: this.state.EstimatedCompletionDate,
      ActualCompletionDate: this.state.ActualCompletionDate,
      ActionStatusId: this.state.ActionStatusId,
      StatusPercentage: this.state.StatusPercentage,
      StatusBy: this.state.StatusBy,
      StatusAsAt: this.state.StatusAsAt,
      DateAdded: this.state.DateAdded,
      AddedBy: this.state.AddedBy,
      DateModified: this.state.DateModified,
      ModifiedBy: this.state.ModifiedBy,
      CompanyId: userData.CompanyId,
      Status: 1,
    }

    try {
      const response = await fetch('api/gapclosingactions', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Gap Closing Actions', content: 'The gap closing action was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Gap Closing Actions', content: 'There was an error saving the gap closing action!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveActionItem(dataId) {
    const userData = getUserDetails();
    const { handleNavigate } = this.context;
    this.addNewActionModalToggle();

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: dataId,
      RiskId: this.state.RiskId,
      FindingId: this.state.FindingId,
      CompanyId: userData.CompanyId,
      FindingActionNumber: this.state.FindingActionNumber,
      ShortName: this.state.ShortName,
      Action: this.state.Action,
      IntendedOutcome: this.state.IntendedOutcome,
      ActionNotes: this.state.ActionNotes,
      DateDue: this.state.DateDue,
      RevisedDateDue: this.state.RevisedDateDue,
      ResponsiblePersonId: this.state.ResponsiblePersonId,
      StatusUpdateFrequencyId: this.state.StatusUpdateFrequencyId,
      FirstUpdateDate: this.state.FirstUpdateDate,
      StatusDate: this.state.StatusDate,
      StatusById: this.state.StatusById,
      StatusId: this.state.StatusId,
      Percentage: this.state.Percentage,
      EstimatedCompletionDate: this.state.EstimatedCompletionDate,
      ActualCompletionDate: this.state.ActualCompletionDate,
      DateAdded: dataId > 0 ? this.state.DateAdded : new Date(),
      AddedBy: dataId > 0 ? this.state.AddedBy : userData.Id,
      Status: 1,
    }

    try {
      const response = await fetch('api/findingactions', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Finding Actions', content: 'The finding action was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Finding Actions', content: 'There was an error saving the finding action!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/risks/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risks', content: 'The risks was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risks', content: 'There was an error deleting the risks!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}
