import { useState, useEffect } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';



const ActualRiskRating = ({ RiskData }) => {
  
  useEffect(() => {

  }, []);



  return (
    <>

    </>
  );
};

export default ActualRiskRating;