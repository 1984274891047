import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card, CardBody } from 'reactstrap';
import { PieChart, Pie, BarChart, Bar, Legend, Tooltip, Cell, ResponsiveContainer, Rectangle, XAxis, YAxis, CartesianGrid } from 'recharts';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class HealthAnalyseRisk extends Component {

    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            GraphTitle: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,

        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Data Health - Analyse & Evaluate the Risks";
        this.loadData();
    }

    editItem = async (data, title) => {

        const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toISOString().split("T")[0] }));
        this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
    };

    getStatusCounts = (risks, statuses) => {
        const completeStatuses = statuses.filter(status => status.Percentage === 100 && risks.find(risk => risk.Id === status.RiskId));
        const notCompleteStatuses = statuses.filter(status => status.Percentage !== 100 && risks.find(risk => risk.Id === status.RiskId));

        const completeStatusCount = completeStatuses.length;
        const notCompleteStatusCount = notCompleteStatuses.length;

        const combinedRisks = [
            ...completeStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId) })),
            ...notCompleteStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId) })),
        ];

        //this.setState({ TableData: combinedRisks })

        return {
            counts: [
                { name: 'Complete', value: completeStatusCount },
                { name: 'Not yet complete', value: notCompleteStatusCount },
            ],
            risks: combinedRisks,
        };
    }

    getAnalysisStatusCounts = (statuses, objectives, processes, capitals, stakeholders, risks) => {
        const completeStatuses = statuses.filter(status => status.Percentage !== 100
            && objectives.find(objective => objective.RiskId === status.RiskId)?.Percentage === 100
            && processes.find(process => process.RiskId === status.RiskId)?.Percentage === 100
            && capitals.find(capital => capital.RiskId === status.RiskId)?.Percentage === 100
            && stakeholders.find(stakeholder => stakeholder.RiskId === status.RiskId)?.Percentage === 100);

        const notCompleteStatuses = statuses.filter(status => status.Percentage === 100
            && (objectives.find(objective => objective.RiskId === status.RiskId)?.Percentage !== 100
                || processes.find(process => process.RiskId === status.RiskId)?.Percentage !== 100
                || capitals.find(capital => capital.RiskId === status.RiskId)?.Percentage !== 100
                || stakeholders.find(stakeholder => stakeholder.RiskId === status.RiskId)?.Percentage !== 100));

        const completeStatusCount = completeStatuses.length;
        const notCompleteStatusCount = notCompleteStatuses.length;

        const combinedRisks = [
            ...completeStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId), ProcessPercentage: processes.find(risk => risk.RiskId === status.RiskId)?.Percentage, ObjectivePercentage: objectives.find(risk => risk.RiskId === status.RiskId)?.Percentage, CapitalPercentage: capitals.find(risk => risk.RiskId === status.RiskId)?.Percentage, SHPercentage: stakeholders.find(risk => risk.RiskId === status.RiskId)?.Percentage })),
            ...notCompleteStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId), ProcessPercentage: processes.find(risk => risk.RiskId === status.RiskId)?.Percentage, ObjectivePercentage: objectives.find(risk => risk.RiskId === status.RiskId)?.Percentage, CapitalPercentage: capitals.find(risk => risk.RiskId === status.RiskId)?.Percentage, SHPercentage: stakeholders.find(risk => risk.RiskId === status.RiskId)?.Percentage })),
        ];

        return {
            counts: [
                { name: 'Not marked as Complete, but completed', value: completeStatusCount },
                { name: 'Marked as Complete, but not completed', value: notCompleteStatusCount },
            ],
            risks: combinedRisks,
        };
    }

    getEvaluationStatusCounts = (statuses, data, risks) => {
        const completeStatuses = statuses.filter(status => status.Percentage !== 100
            && data.ActualData.find(item => item.RiskId === status.RiskId)?.CompleteStatus === 100
            && data.PotentialData.find(item => item.RiskId === status.RiskId)?.CompleteStatus === 100
            && data.TargetData.find(item => item.RiskId === status.RiskId)?.CompleteStatus === 100);

        const notCompleteStatuses = statuses.filter(status => status.Percentage === 100
            && (data.ActualData.find(item => item.RiskId === status.RiskId)?.CompleteStatus !== 100
                || data.PotentialData.find(item => item.RiskId === status.RiskId)?.CompleteStatus !== 100
                || data.TargetData.find(item => item.RiskId === status.RiskId)?.CompleteStatus !== 100));

        const completeStatusCount = completeStatuses.length;
        const notCompleteStatusCount = notCompleteStatuses.length;

        const combinedRisks = [
            ...completeStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId), ActualPercentage: data.ActualData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus, PotentialPercentage: data.PotentialData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus, TargetPercentage: data.TargetData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus })),
            ...notCompleteStatuses.map(status => ({ ...status, StatusId: status.Id, ...risks.find(risk => risk.Id === status.RiskId), ActualPercentage: data.ActualData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus, PotentialPercentage: data.PotentialData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus, TargetPercentage: data.TargetData.find(risk => risk.RiskId === status.RiskId)?.CompleteStatus })),
        ];

        return {
            counts: [
                { name: 'Not marked as Complete, but completed', value: completeStatusCount },
                { name: 'Marked as Complete, but not completed', value: notCompleteStatusCount },
            ],
            risks: combinedRisks,
        };
    }

    getEvaluationDurationCounts = (risks, evaluationStatuses) => {
        const currentDate = new Date();

        function calculateDurationInMonths(evaluationDate) {
            const diffInMs = currentDate - new Date(evaluationDate);
            return Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
        }

        const barData = {
            '<3m ago': 0,
            '3m - 6m ago': 0,
            '6m - 9m ago': 0,
            '9m - 12m ago': 0,
            '>12m ago': 0,
            'Never': 0,
        };

        const risksArray = [];

        risks.forEach(risk => {
            const matchingEvaluation = evaluationStatuses.find(status => status.RiskId === risk.Id);

            if (matchingEvaluation) {
                const durationInMonths = calculateDurationInMonths(matchingEvaluation.DateAdded);
                const newRisk = { ...risk, DurationMonths: '' };

                if (durationInMonths < 3) {
                    barData['<3m ago'] += 1;
                    newRisk.DurationMonths = '<3m ago';
                } else if (durationInMonths < 6) {
                    barData['3m - 6m ago'] += 1;
                    newRisk.DurationMonths = '3m - 6m ago';
                } else if (durationInMonths < 9) {
                    barData['6m - 9m ago'] += 1;
                    newRisk.DurationMonths = '6m - 9m ago';
                } else if (durationInMonths < 12) {
                    barData['9m - 12m ago'] += 1;
                    newRisk.DurationMonths = '9m - 12m ago';
                } else {
                    barData['>12m ago'] += 1;
                    newRisk.DurationMonths = '>12m ago';
                }
                risksArray.push(newRisk);
            } else {
                const newRisk = { ...risk, DurationMonths: '' };

                barData['Never'] += 1;
                newRisk.DurationMonths = 'Never';
                risksArray.push(newRisk);
            }
        });

        const counts = Object.entries(barData).map(([name, value]) => ({ name, value }));

        return { counts, risks: risksArray };
    }

    getEvaluationDayCounts = (risks, evaluationStatuses) => {
        const currentDate = new Date();

        function calculateDurationInDays(loggedDate) {
            const diffInMs = currentDate - new Date(loggedDate);
            return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        }

        const barData2 = {
            'Logged <7d ago': 0,
            'Logged 7d - 14d ago': 0,
            'Logged 14d - 21d ago': 0,
            'Logged >21d ago': 0,
        };

        const risksArray = [];

        risks.forEach(risk => {
            const matchingEvaluation = evaluationStatuses.find(status => status.RiskId === risk.Id);

            if (!matchingEvaluation) {
                const durationInDays = calculateDurationInDays(risk.DateAdded);
                const newRisk = { ...risk, DurationDays: '' };

                if (durationInDays < 7) {
                    barData2['Logged <7d ago'] += 1;
                    newRisk.DurationDays = 'Logged <7d ago';
                } else if (durationInDays < 14) {
                    barData2['Logged 7d - 14d ago'] += 1;
                    newRisk.DurationDays = 'Logged 7d - 14d ago';
                } else if (durationInDays < 21) {
                    barData2['Logged 14d - 21d ago'] += 1;
                    newRisk.DurationDays = 'Logged 14d - 21d ago';
                } else {
                    barData2['Logged >21d ago'] += 1;
                    newRisk.DurationDays = 'Logged >21d ago';
                }
                    risksArray.push(newRisk);
            }
        });

        const counts = Object.entries(barData2).map(([name, value]) => ({ name, value }));

        return { counts, risks: risksArray };
    }

    renderTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                                {/*<ColumnDirective headerText='Type' field='RiskTypeName'></ColumnDirective>*/}
                                {/*<ColumnDirective headerText='Speed of Onset (Months)' field='SpeedOfOnset'></ColumnDirective>*/}
                                {data.some(obj => obj.hasOwnProperty('Percentage')) && <ColumnDirective headerText='Percentage' field='Percentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('ObjectivePercentage')) && <ColumnDirective headerText='Objective %' field='ObjectivePercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('ProcessPercentage')) && <ColumnDirective headerText='Process %' field='ProcessPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('CapitalPercentage')) && <ColumnDirective headerText='Capital %' field='CapitalPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('SHPercentage')) && <ColumnDirective headerText='Stakeholder %' field='SHPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('ActualPercentage')) && <ColumnDirective headerText='Actual %' field='ActualPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('PotentialPercentage')) && <ColumnDirective headerText='Potential %' field='PotentialPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('TargetPercentage')) && <ColumnDirective headerText='Target %' field='TargetPercentage'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('DurationMonths')) && <ColumnDirective headerText='Duration (Months)' field='DurationMonths'></ColumnDirective>}
                                {data.some(obj => obj.hasOwnProperty('DurationDays')) && <ColumnDirective headerText='Duration (Days)' field='DurationDays'></ColumnDirective>}
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderDataTable(data, data2, data3, data4, barData, barData2, colors, pieColors, barColors, barColors2) {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
                </text>
            );
        };
        return (
            <>
                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Have all the risks been fully Analysed?")}>
                        <strong>Have all the risks been fully Analysed?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                                    {data.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data2.risks, "Have all the risks been fully Evaluated?")} >
                        <strong>Have all the risks been fully Evaluated?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data2.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data3.risks, "Are there any risks with inconsistent Analysis completion statuses?")}>
                        <strong>Are there any risks with inconsistent Analysis completion statuses?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data3.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} >
                                    {data3.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart ></ResponsiveContainer></div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data4.risks, "Are there any risks with inconsistent Evaluation completion statuses?")}>
                        <strong>Are there any risks with inconsistent Evaluation completion statuses?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data4.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data4.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3">
                    <div className="text-center my-3 w-50 text-light" onClick={() => this.editItem(barData.risks, "When were the risks fully Evaluated?")}>
                        <strong>When were the risks fully Evaluated?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < BarChart width={500} height={300} data={barData.counts} >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" activeBar={<Rectangle fill="midnightblue" stroke="grey" />} label={{ position: 'top' }}>
                                    {barData.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[index]} />
                                    ))}                            </Bar>
                            </BarChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 w-50 text-light" onClick={() => this.editItem(barData2.risks, "When were the Unevaluated risks logged?")}>
                        <strong>When were the Unevaluated risks logged?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < BarChart width={500} height={300} data={barData2.counts} >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" activeBar={<Rectangle fill="midnightblue" stroke="blue" />} label={{ position: 'top' }}>
                                    {barData2.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors2[index]} />
                                    ))}
                                </Bar>
                            </BarChart >
                        </ResponsiveContainer>
                    </div>
                </div>
            </>
        );
    }

    render() {
        let riskAnalysis;
        let riskEvaluation;
        let analysisStatuses;
        let evaluationStatuses;

        let barData;
        let barData2;

        const colors = ['#ffb6c1', '#c71585'];
        const pieColors = ['#32cd32', '#ffb6c1'];
        const barColors = ['royalblue', 'royalblue', 'royalblue', 'royalblue', 'royalblue', 'red'];
        const barColors2 = ['mediumseagreen', 'limegreen', 'pink', 'red'];

        if (!this.state.loading) {
            riskAnalysis = this.getStatusCounts(this.state.editData, this.state.RiskAnalysisStatusData);
            riskEvaluation = this.getStatusCounts(this.state.editData, this.state.RiskEvaluateStatusData);

            analysisStatuses = this.getAnalysisStatusCounts(this.state.RiskAnalysisStatusData, this.state.BusinessObjectivesList, this.state.BusinessProcessesList, this.state.CapitalsList, this.state.StakeholdersList, this.state.editData);
            evaluationStatuses = this.getEvaluationStatusCounts(this.state.RiskEvaluateStatusData, this.state.EvaluationData, this.state.editData);

            barData = this.getEvaluationDurationCounts(this.state.editData, this.state.RiskEvaluateStatusData);
            barData2 = this.getEvaluationDayCounts(this.state.editData, this.state.RiskEvaluateStatusData);
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(riskAnalysis, riskEvaluation, analysisStatuses, evaluationStatuses, barData, barData2, colors, pieColors, barColors, barColors2);
        //console.log(analysisStatuses)
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Data Health<br /><small>- Analyse & Evaluate the Risks -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                            <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, riskAnalysis.counts, riskEvaluation.counts, analysisStatuses.counts, evaluationStatuses.counts, barData.counts, barData2.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.editModal && this.renderTable(this.state.GraphData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal >}
                <ThrobbleHelper />
            </>

        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/rootcauses/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ RootCausesList: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/consequences/getbycompany/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ ConsequencesList: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/controls/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                let data = await response.json();
                data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
                this.setState({ ControlList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskbusinessobjectivestatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ BusinessObjectivesList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskbusinessprocessstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ BusinessProcessesList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskcapitalsstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CapitalsList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskstakeholdersstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ StakeholdersList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskevaluatestatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ RiskEvaluateStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskanalysisstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ RiskAnalysisStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskevaluation/GetRiskEvaluations`, {
                method: "GET",
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ EvaluationData: data });
            }
        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }

    handleExport = async (e, riskAnalysis, riskEvaluation, analysisStatuses, evaluationStatuses, barData, barData2) => {
        e.stopPropagation();
        ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        let postData = {
            PieData: JSON.stringify(riskAnalysis),
            Pie2Data: JSON.stringify(riskEvaluation),
            Pie3Data: JSON.stringify(analysisStatuses),
            Pie4Data: JSON.stringify(evaluationStatuses),
            Pie5Data: JSON.stringify([]),
            BarData: JSON.stringify(barData),
            Bar2Data: JSON.stringify(barData2),
        }

        try {
            const response = await fetch('api/risks/downloadhealthanalysisrisk', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    ThrobbleHelper.ToggleThrobble(false);
                    handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        const finalData = data.map(item => ({
            ResponsibleTeamName: item.ResponsibleTeamName,
            ResponsiblePersonName: item.ResponsiblePersonName,
            RiskNumber: item.RiskNumber,
            RiskName: item.Name,
            DateLogged: item.DateAdded,
            LoggedBy: item.AddedByName,
            Percentage: item.Percentage,
            ObjectivePercentage: item.ObjectivePercentage,
            ProcessPercentage: item.ProcessPercentage,
            CapitalPercentage: item.CapitalPercentage,
            SHPercentage: item.SHPercentage,
            ActualPercentage: item.ActualPercentage,
            PotentialPercentage: item.PotentialPercentage,
            TargetPercentage: item.TargetPercentage,
            DurationMonths: item.DurationMonths,
            DurationDays: item.DurationDays,
        }));
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {

            //console.log(data)
            const response = await fetch("api/risks/datahealthanalyserisks", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

export default HealthAnalyseRisk;
