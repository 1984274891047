/* eslint-disable no-unused-vars */
export const getEditId = () => {
  if (window.localStorage.getItem("EditId")) {
    return Number(window.localStorage.getItem("EditId"));
  }
  else
    return 0;
}

export const setEditId = (data) => {
  try {
    window.localStorage.setItem("EditId", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getIsAdmin = () => {
  if (window.localStorage.getItem("isAdmin")) {
    return window.localStorage.getItem("isAdmin");
  }
  else
    return 0;
}

export const setIsAdmin = (data) => {
  try {
    window.localStorage.setItem("isAdmin", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const removeAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.removeItem("AuthToken"));
    return data.authToken;
  }
  else
    return null;

};

export const IsDevEnv = () => {
  const NODE_ENV = process.env.NODE_ENV || "development";

  return NODE_ENV === "development";
}

export const getCurrentDomain = () => {
  const NODE_ENV = process.env.NODE_ENV || "development"
  if (NODE_ENV === "development")
    return "https://localhost:7251/";
  else
    return "/";
}

export const getIsFiltered = () => {
  try {
    const IsFiltered = window.localStorage.getItem("IsFiltered");
    return IsFiltered === "1";
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const setIsFiltered = (data) => {
  try {
    window.localStorage.setItem("IsFiltered", data);
    return true;
  } catch (e) {
    return false;
  }
}

export const GetFilteredData = () => {
  try {
    const FilteredData = window.localStorage.getItem("FilteredData");
    if (FilteredData) {
      return JSON.parse(FilteredData);
    }
    return [];
  } catch (e) {
    return [];
  }
}

export const SetFilteredData = (data) => {
  try {
    window.localStorage.setItem("FilteredData", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const SetCatastrophicLabel = (label) => {
  try {
    window.localStorage.setItem("CatastrophicLabel", label);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const SetImmaterialityLabel = (label) => {
  try {
    window.localStorage.setItem("ImmaterialityLabel", label);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const GetCatastrophicLabel = () => {
  try {
    let label = window.localStorage.getItem("CatastrophicLabel");
    if (label)
      return label;
    else
      return "Catastrophic";
  } catch (e) {
    return "Catastrophic";
  }
}

export const GetImmaterialityLabel = (label) => {
  try {
    let label = window.localStorage.getItem("ImmaterialityLabel");
    if (label)
      return label;
    else
      return "Immateriality";
  } catch (e) {
    return "Immateriality";
  }
}