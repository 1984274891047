import { getValue } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, setAuthToken, getUserDetails } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class ImpersonateCompany extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            Colour: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            CompanyList: [],
            ImpersonatedId: 0
        };
        this.impersonate = this.impersonate.bind(this)

    }

    componentDidMount() {
        const parts = window.location.href.split("/");
        const estnumber = parts[parts.length - 1];
        document.title = estnumber;
        let userdata = JSON.parse(localStorage.getItem("CurrentUser"))
        this.setState({ ImpersonatedId: userdata?.CompanyId })
        this.loadData();
    }

    impersonate = () => {
        let userInfo = JSON.parse(localStorage.getItem("CurrentUser"));
        userInfo.CompanyId = this.state.ImpersonatedId;
        userInfo.CompanyName = "Impersonating " + this.state.CompanyList.find(item => item.Id === this.state.ImpersonatedId)?.Name
        userInfo.Currency = this.state.CompanyList.find(item => item.Id === this.state.ImpersonatedId)?.CurrencySymbol
        localStorage.setItem("CurrentUser", JSON.stringify(userInfo))
        this.context.setCompanyName(userInfo.CompanyName)
    }

    clearImpersonate = () => {
        let userInfo = JSON.parse(localStorage.getItem("CurrentUser"));
        userInfo.CompanyId = userInfo.DefaultCompanyId;
        userInfo.CompanyName = this.state.CompanyList.find(item => item.Id === userInfo.DefaultCompanyId)?.Name
        userInfo.Currency = this.state.CompanyList.find(item => item.Id === userInfo.DefaultCompanyId)?.CurrencySymbol
        localStorage.setItem("CurrentUser", JSON.stringify(userInfo))
        this.context.setCompanyName(this.state.CompanyList.find(item => item.Id === this.state.ImpersonatedId)?.Name)
        this.setState({ ImpersonatedId: userInfo.CompanyId })
    }

    impersonateCompanies = (companies) => {
        return <div className='my-3'>
            <div>
                <DropDownListComponent id='ddCompanyId' name='ddCompanyId' placeholder='Select Company' dataSource={[{ Id: 0, Name: "-SELECT-" }, ...companies]} fields={{ text: 'Name', value: 'Id' }} floatLabelType='Always' value={this.state.ImpersonatedId} change={e => this.setState({ ImpersonatedId: e.value })} /><div id='tbCompanyIdError' className='error-message' />
            </div>
            <div className='my-3 float-end'>
                <Button color="success" size="sm" className='mx-2' onClick={this.impersonate}>Impersonate <i className="far fa-check-circle ms-2"></i></Button>
                <Button color="primary" size="sm" onClick={this.clearImpersonate} >Clear <i className="far fa-check-circle ms-2"></i></Button>
            </div>
        </div>
    }

    render() {
        const companies = this.state.CompanyList.filter(item => item.Id !== getUserDetails().DefaultCompanyId);

        let contents = this.impersonateCompanies(companies);
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h1>Impersonate Company</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
            </>

        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();

        try {
            const response = await fetch('api/companies', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CompanyList: data, loading: false });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401) {
                    localStorage.removeItem('AuthToken');
                    localStorage.removeItem('CurrentUser');
                    handleNavigate("/login");
                    //window.location.reload();
                }
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

ImpersonateCompany.contextType = Context