import React, { Component, createRef } from 'react'
//import Engagement from './Risk/Engagements';
import EngagementModal from './Risk/EngagementModal';

export class TestStuff extends Component {

  
  constructor(props) {
    super(props);
    this.childRef = createRef();
    this.modal2 = createRef();

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    //const { RatingImpacts, PotentialData, ActualData, TargetData } = this.props;
    //this.setState({ RatingImpacts, PotentialData, ActualData, TargetData, loading: false });
  }

  handleClick = () => {
    // Call the child component's function using the ref
    if (this.childRef.current) {
      this.childRef.current.showModal();
    }
  };
  handleClick2 = () => {
    // Call the child component's function using the ref
    if (this.modal2.current) {
      this.modal2.current.showModal();
    }
  };

  saveEngagement = (percentage, comment) => {
    console.log("Save the engagement");
    console.log(percentage);
    console.log(comment);
  }

  render() {
    //const testEngagement = Engagement("title", {}, null);
    return (
      <>
        Some stuff<br />
        <button onClick={this.handleClick}>Call Child Function</button>
        <button onClick={this.handleClick2}>Call Child Function</button>



        <EngagementModal key="mdl123" ref={this.childRef} Title="Edit Engagement" SaveFunction={this.saveEngagement} Data={{ Id: 1, PreviousPercentage: 20, UserName: 'Test', PreviousDate: '', PreviousComment: 'None' }} />
        <EngagementModal key="mdl321" ref={this.modal2} Title="Edit Risk" SaveFunction={this.saveEngagement} Data={{ Id: 0, PreviousPercentage: 20, UserName: 'Test', PreviousDate: '', PreviousComment: 'None' }} />

      </>

      //<div className='d-flex text-center risk-header gap-3 px-3 mb-3'>
      //  <div className='risk-box'>
      //    <small>POTENTIAL</small>
      //    <div>6.1 - Major <br /> R29,100,000</div>
      //  </div>
      //  <div className='risk-box'>
      //    <small>ACTUAL</small>
      //    <div>5.2 - Major <br /> R20,100,000</div>
      //  </div>
      //  <div className='risk-box'>
      //    <small>TARGET</small>
      //    <div>4.4 - Minor <br /> R10,500,000</div>
      //  </div>
      //</div>
    )
  }
}

