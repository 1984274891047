import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { ConfirmDialogStyles, ShowLoader } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class SiteStructures extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    //console.log(gridData);
    this.state = {
      editData: [], loading: true, editModal: false, perspectiveModal: false,
      //evaluationData: [],
      //siteStructureEvaluationsData: [],
      rowData: [],
      dropdownValues: {},
      Id: 0,
      Name: '',
      ParentId: 0,
      CompanyId: 0,
      DisplayOrder: 0,
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      
      LinkedToParent: true,
      parentItem: null,
      evalItems: [],
      editRow: 0,

    };


    this.toggle = this.toggle.bind(this);

    this.editItem = this.editItem.bind(this);

    this.nodeTemplate = this.nodeTemplate.bind(this);
  }

  handleDropdownChange = (identifier, value) => {
    this.setState(prevState => ({
      dropdownValues: {
        ...prevState.dropdownValues,
        [identifier]: value
      }
    }));
  };

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  togglePerspective() {
    this.setState({
      perspectiveModal: !this.state.perspectiveModal
    });
  }

  componentDidMount() {
    document.title = "Site Structures Administration :: OnPoint RMS";
    this.loadData();
  }

  addItem = async (id) => {
    await this.setState({ parentItem: this.state.editData.find((item) => { return item.Id === id }) });
    //console.log(this.state.editData.find((item) => { return item.Id === id }))
    this.editItem(0);
    //this.setState({ LinkedToParent: false });

  }

  editItem = async (id) => {
    //console.log(id);
    //console.log("parent:", this.state.parentItem);
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      if (data.ParentId === 0)
        this.setState({ parentItem: null, LinkedToParent: false });
      await this.setState({
        Id: data.Id, Name: data.Name, ParentId: data.ParentId, CompanyId: data.CompanyId, CurrencySymbol: data.CurrencySymbol, LayerId: data.LayerId, TypeId: data.TypeId, DisplayOrder: data.DisplayOrder, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, ImmaterialityLevel: data.ImmaterialityLevel, CatastrophicLevel: data.CatastrophicLevel, NumOfPerspectives: data.NumOfPerspectives, NumOfLevels: data.NumOfLevels,
      });

    }
    else {
      //clear state fields
      await this.setState({
        Id: 0,
        Name: '',
        ParentId: 0,
        CompanyId: 0,
        DisplayOrder: 0,
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
        LayerId: 0,
        TypeId: 1,
        treeObj: null,
        rowData: [],
      });
    }
    if (this.state.ParentId === 0)
      await this.setState({ LinkedToParent: false });

    if (this.state.parentItem && this.state.parentItem.Id > 0) {
      await this.setState({
        ParentId: this.state.parentItem.Id,
        LayerId: this.state.parentItem.LayerId + 1,
        LinkedToParent: true
      });

    }

    this.setState({ editId: id, editModal: true });
  };
    
  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.DateAdded,
          AddedBy: data.AddedBy,
          Id: data.Id,
          Status: data.Status
        });
      }
      else {
        this.setState({
          Status: 1
        });
      }
      this.saveData(this.state.editId);
      //this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {

    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  hasChildren = (data, ParentId) => {
    return data.filter(item => item.ParentId === ParentId).length > 0;
  }

  nodeTemplate(data) {
    const addItem = () => { this.addItem(data.id) };
    const editData = () => { this.editItem(data.id) };
    const deleteItem = () => { this.deleteItem(data.id) };
    
    return (<div>
      <span className="me-2">{data.name}</span>

      <button type="button" className={(data.layer < 5) ? "btn-structures " : "d-none"} onClick={addItem}><i className="fa-solid fa-plus"></i></button>
      <button type="button" className="btn-structures" onClick={editData}><i className="far fa-edit"></i></button>
      <button type="button" className={(data.pid > 0) ? "btn-structures " : "d-none"} onClick={deleteItem}><i className="far fa-trash-alt text-danger"></i></button>
    </div>);
  }

  

  render() {
    //let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : "";
    if (this.state.loading)
      return (ShowLoader());

    let structures = [];

    this.state.editData.forEach((item) => {
      let child = this.hasChildren(this.state.editData, item.Id);
      structures.push({ id: item.Id, name: item.Name, pid: (item.ParentId > 0 ? item.ParentId : null), hasChild: child, expanded: child, layer: item.LayerId });

    });

    let fields = { dataSource: structures, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };

    return (

      <>
        <div className="container-fluid">
          <Row>
            <Col xs={8}>
              <h1>Sites</h1>
            </Col>

            <Col xs={4} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>

            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <TreeViewComponent fields={fields} nodeTemplate={this.nodeTemplate} allowDragAndDrop={true} />
            </Col>
          </Row>
        </div>


        <Modal isOpen={this.state.editModal} toggle={this.toggle} scrollable backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Site Structure</ModalHeader>
          <ModalBody>
              <div className='mb-3'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='100' floatLabelType='Auto' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>





      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();
    let loadCounter = 1;

    try {
      const response = await fetch(`api/sitestructures/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {

        const data = await response.json();
        this.setState({ editData: data });
        loadCounter--;
        if (loadCounter <= 0)
          this.setState({ loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401) {
          handleNavigate("/login");
        }
      }
    } catch (e) {
      console.error(e);
    }
    setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    let existingData = this.state.editData.find(item => (item.LayerId === 1))

    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: this.state.Id, Name: this.state.Name, ParentId: this.state.ParentId, CompanyId: getUserDetails().CompanyId,
      LayerId: this.state.LayerId,
      CurrencySymbol: existingData && dataId !== existingData.Id ? existingData.CurrencySymbol : this.state.CurrencySymbol,
      DisplayOrder: this.state.DisplayOrder, 
      LinkedToParent: this.state.LinkedToParent
    }

    if (dataId === 0) {
      data.ParentId = this.state.parentItem ? this.state.parentItem.Id : 0;
      data.LayerId = this.state.parentItem ? this.state.parentItem.LayerId + 1 : 2;
    }

    //return;
    try {
      const response = await fetch('api/sitestructures', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.setState({ editModal: false });
        this.loadData();
        ToastUtility.show({
          title: 'Sites', content: 'The site was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Sites', content: 'There was an error saving the site!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/sitestructures/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Sites', content: 'The site was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Sites', content: 'There was an error deleting the site!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

