import { getValue } from '@syncfusion/ej2-base';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ColorPickerComponent, NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { CommandTemplate, ConfirmDialogStyles, GetContrastColour } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';

export class RiskStatuses extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);


    this.state = {
      editData: [], loading: true, editModal: false, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      Title: '',
      MinValue: 0,
      MaxValue: 0,
      Colour: '',
      DisplayOrder: 0,
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      orderedData: []
    };
    this.toggle = this.toggle.bind(this);
    this.orderModalToggle = this.orderModalToggle.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  orderModalToggle = () => {
    this.setState({
      orderModal: !this.state.orderModal
    });
  }


  componentDidMount() {
    document.title = "Risk Status Administration :: OnPoint RMS";
    this.loadData();
  }

  addItem = (ev) => { this.editItem(0); }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, CompanyId: getUserDetails().CompanyId, Title: data.Title, MinValue: data.MinValue, MaxValue: data.MaxValue, Colour: data.Colour, DisplayOrder: data.DisplayOrder, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        CompanyId: getUserDetails().CompanyId,
        Title: '',
        MinValue: 0,
        MaxValue: 0,
        Colour: '',
        DisplayOrder: 0,

        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    console.log(this.state.Colour);
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required', 'tbTitle');
    valid &= fieldRequired(this.state.Colour, 'tbColourError', '* required', 'tbColour');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.Id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          DateAdded: data.dateAdded,
          AddedBy: data.addedBy,
          Id: data.id,
          Status: data.status,
          CompanyId: getUserDetails().CompanyId
        });

      }
      else {
        this.setState({
          Status: 1,
          CompanyId: getUserDetails().CompanyId
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    
    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
      default: //edit
        this.editItem(args.rowData.Id);
        break;
    }
  }

  onItemDrop = (e) => {
    const orderedData = [];
    e.source.currentData.map((item, idx) =>
      orderedData.push({ ...item, displayOrder: (idx + 1) })
    );

    this.setState({ orderedData })
  }


  static renderDataTable(data, gridToolbar, commandClick) {
    

    const showStatusColour = (args) => {
      try {
        if (args.column.field === "Colour" && args.data && args.cell) {
          args.cell.style.backgroundColor = getValue('Colour', args.data);
          args.cell.style.color = GetContrastColour(getValue('Colour', args.data));
        }
      } catch (e) {
        console.error(e);
      }

    }
    //console.log("renderDataTable", data);
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} queryCellInfo={showStatusColour}>
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective field='MinValue' width='15' headerText="Min" />
          <ColumnDirective field='MaxValue' width='15' headerText="Max" />
          <ColumnDirective field='Colour' width='15' headerText="Colour" />
          <ColumnDirective headerText='Actions' width='15' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }
  
  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RiskStatuses.renderDataTable(this.state.editData, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={4}>
              <h1>Impact Level</h1>
            </Col>
            <Col xs={8} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Master Data</Link>
              <Button color="primary" size="sm" onClick={this.orderModalToggle} className='mx-2'>Set Display Order <i className="fa-solid fa-sort ms-2"></i></Button>
              <Button color="primary" size="sm" onClick={this.addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable  backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Impact Level</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3 col-md-12'>
                <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
              </div>
              <div className='mb-3 col-md-5'>
                <NumericTextBoxComponent id='tbMinValue' name='tbMinValue' placeholder='Min Value' floatLabelType='Auto' showClearButton={true} value={this.state.MinValue} onChange={e => this.setState({ MinValue: e.target.value })} /> <div id='tbMinValueError' className='error-message' />
              </div>

              <div className='mb-3 col-md-5'>
                <NumericTextBoxComponent id='tbMaxValue' name='tbMaxValue' placeholder='Max Value' floatLabelType='Auto' showClearButton={true} value={this.state.MaxValue} onChange={e => this.setState({ MaxValue: e.target.value })} /> <div id='tbMaxValueError' className='error-message' />
              </div>
              <div className='mb-3 col-md-2'>
                <div className="small-label">Colour</div>
                <ColorPickerComponent id="tbColour" name="tbColour" enableOpacity={false} placeholder='Colour' floatLabelType='Always' showClearButton={true} value={this.state.Colour} onChange={e => this.setState({ Colour: e.value })} />
                <div className='error-message' id='tbColourError'></div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal}>
          <ModalHeader toggle={this.orderModalToggle} close={<button className="close" onClick={this.orderModalToggle}><i className="fas fa-times"></i></button>}>Order Risk Statuses</ModalHeader>
          <ModalBody>
            <i style={{ color: "grey", fontSize: 12 }}>Drag and Drop</i>
            <ListBoxComponent dataSource={this.state.editData} allowDragAndDrop={true} fields={{ text: "Title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.orderModalToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
    setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, CompanyId: getUserDetails().CompanyId, Title: this.state.Title, MinValue: this.state.MinValue, MaxValue: this.state.MaxValue, Colour: this.state.Colour, DisplayOrder: this.state.DisplayOrder, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }
    try {
      const response = await fetch('api/riskstatuses', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Statuses', content: 'The risk status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Statuses', content: 'There was an error saving the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveOrder() {
    const { handleNavigate } = this.context;
    this.orderModalToggle()
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('api/riskstatuses/order', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.orderedData),
      });

      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Statuses', content: 'The risk status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Statuses', content: 'There was an error saving the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }


  async deleteData(dataId) {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/riskstatuses/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Risk Statuses', content: 'The risk status was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Risk Statuses', content: 'There was an error deleting the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

