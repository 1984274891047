import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes, { AdminRoutes } from './AppRoutes';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import ForgotPassword from './components/ForgotPassword';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import ProtectedRoute from './components/ProtectedRoutes';
import ResetPassword from './components/ResetPassword';
import './custom.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Routes>
        <Route
          index
          path="/*"
          element={
            <Layout>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <AuthenticatedRoute key={index} {...rest} element={element} />;
              })}
            </Layout>
          }
        />
        <Route
          path="/admin/*"
          element={
            <Layout>
              {AdminRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <ProtectedRoute key={index} {...rest} element={element} />;
              })}
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
      </Routes>
    );


  }
}
