import { MDBBtn } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fieldRequired } from '../helpers/validation';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    isValid &= fieldRequired(this.state.username, "errEmail", "* required", 'tbUsername');
    if (isValid) {
      setLoading(true);
      const response = await fetch('/api/login/forgotpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('An email has been sent with a link to reset your password.');
        setLoading(false);
      } else {
        setMessage('Failed to reset password. Please check your email address.');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    document.title = "OnPoint RMS :: Forgot Password";
  }, []);

  return (
    <>
      <div className="login-container">
        <div className="">
          <div className="row">
            <div className="card px-0">
              <div className="card-header login-title">
                <h5 className="card-title">On<span>Point</span> Risk Management System</h5>
                Empowering Decisions with Precision
              </div>
              <div className="card-body">
                <h5 className='mb=3 text-uppercase'>Forgot Password</h5>

                <div className="mb-3">

                  <label>Email
                    <span className="error-message">&nbsp;</span>
                    <span id="errEmail" className="error-message" />
                  </label>

                  <input id="tbUsername" className='form-control form-control-sm' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <p>{message}</p>
              </div>
              <div className="card-footer text-end">
                <MDBBtn onClick={handleSubmit} size='sm'>Reset Password &nbsp;{!loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}</MDBBtn>
                <div className="version">Version: 0.1</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;