/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import Loader from '../Common/Loader';
import ActualRiskRating from './Actual/ActualRiskRating';
import AnalyseRisk from './Analyse/AnalyseRisk';
import RiskStatus from './Common/RiskStatus';
import RiskSummary from './Common/RiskSummary';
import RiskValues from './Common/RiskValues';
import EvaluateRisk from './Evaluation/EvaluateRisk';
import PlanRisk from './Plan/PlanRisk';
import PotentialRiskRating from './Potential/PotentialRiskRating';
import TargetRiskRating from './Target/TargetRiskRating';
import TreatRisk from './Treat/TreatRisk';

export default function RiskEditor(props) {

  const [riskData, setRiskData] = useState();
  const [siteData, setSiteData] = useState();
  const [companyData, setCompanyData] = useState();
  const [riskAnalysisStatusData, setRiskAnalysisStatusData] = useState();
  const [riskEvaluateStatusData, setRiskEvaluateStatusData] = useState();
  const [riskTreatStatusData, setRiskTreatStatusData] = useState();
  const [riskPlanStatusData, setRiskPlanStatusData] = useState();
  const [riskStatus, setRiskStatus] = useState();
  const [rootCauses, setRootCauses] = useState();
  const [controls, setControls] = useState();
  const [consequences, setConsequences] = useState();
  const [potentialActualTargetData, setPotentialActualTargetData] = useState();

  const [riskId, setRiskId] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const loadRisk = async () => {
    setIsLoading(true);
    var bearer = 'Bearer ' + getAuthToken();
    const parts = window.location.href.split("/");
    const riskNumber = parts[parts.length - 1];
    setRiskId(riskNumber);
    //const userData = getUserDetails();
    try {
      const response = await fetch(`api/risks/getbyriskid/${riskNumber}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setRiskData(data);
        loadRiskData(data)
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const loadRiskData = async (riskData) => {
    const userData = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    let loadCounter = 11;

    //Load Site Data
    try {
      const response = await fetch(`api/sitestructures/${riskData.SiteStructureId}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSiteData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Company Data
    try {
      const response = await fetch(`api/companies/${userData.CompanyId}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        setCompanyData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      } else {
        console.error(response.status + ": " + response.statusText);

      }
    } catch (e) {
      console.error(e);
    }

    //Load Risk Analysis
    try {
      const response = await fetch(`api/riskanalysisstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskAnalysisStatusData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Evaluate Status
    try {
      const response = await fetch(`api/riskevaluatestatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskEvaluateStatusData(data)
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Evaluations
    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${riskData.Id}/${userData.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        setPotentialActualTargetData(data)
        loadCounter--;
        setIsLoading(loadCounter > 0);
      } else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    //Load Risk Treat
    try {
      const response = await fetch(`api/risktreatstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskTreatStatusData(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Plan
    try {
      const response = await fetch(`api/riskplanstatus/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskPlanStatusData(data);
        //console.log(data)
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load root causes
    try {
      const response = await fetch(`api/rootcauses/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        //this.setState({ RootCausesList: data });
        setRootCauses(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Controls
    try {
      const response = await fetch(`api/controls/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data);
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        console.log(data);
        //this.setState({ ControlsList: data });
        setControls(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/consequences/getbyriskid/${riskData.Id}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data)
        data = data.map(item => ({ ...item, ConnectedPerspectives: item.ConnectedPerspectives ? JSON.parse(item.ConnectedPerspectives) : [] }));
        console.log(data);
        //this.setState({ ConsequencesList: data });
        setConsequences(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    //Load Risk Status
    try {
      const response = await fetch(`api/riskstatuses/listallbycompany/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRiskStatus(data);
        loadCounter--;
        setIsLoading(loadCounter > 0);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

  }

  useEffect(() => {
    loadRisk();
  }, []);

  /*({, , , RootCauses, Consequences, RiskImpactRatings, }) */
  return (
    <>
      <div className="row">
        <div className="col-md-6 "><h1>Risk Engagement</h1></div>
        <div className="col-md-6 text-end align-self-center">
          <Link to="/my-items" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Back To Risks</Link>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <RiskSummary RiskData={riskData} />
          <RiskValues RiskData={riskData} SiteData={siteData} CompanyData={companyData} />
          <RiskStatus RiskData={riskData} RiskAnalysisStatusData={riskAnalysisStatusData} RiskEvaluateStatusData={riskEvaluateStatusData} RiskTreatStatusData={riskTreatStatusData} RiskPlanStatusData={riskPlanStatusData} />

            <PotentialRiskRating RiskData={riskData} SiteData={siteData} RiskEvaluateStatusData={riskEvaluateStatusData} PotentialData={potentialActualTargetData.PotentialData} RiskImpactRatings={potentialActualTargetData.RiskImpactRatings } />

          <ActualRiskRating RiskData={riskData} />
          <TargetRiskRating RiskData={riskData} />

          <AnalyseRisk RiskData={riskData} />
          <EvaluateRisk RiskData={riskData} />
          <TreatRisk RiskData={riskData} />
          <PlanRisk RiskData={riskData} />

        </>
      )}

    </>
  );
}

