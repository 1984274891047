import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Attachments from './Attachments';

import Findings from './Findings';
import TooltipComponent from '../../Common/ToolTip';



const RiskSummary = ({ RiskData }) => {
  const statuses = [{ Title: "Draft", Id: 0 }, { Title: "Active", Id: 1 }, { Title: "Deactivated", Id: 2 }];

  useEffect(() => {

  }, []);



  return (
    <>
      <div className='card bg-light mb-3'>
        <div className='card-body'>
          <div className='row risk-status my-3'>
            <div className='col-md-4'>
              <Attachments Id={RiskData.Id} Type={1} />
              <Findings Id={RiskData.Id} Type={1} />

              <i className="fa-regular fa-circle-user text-danger" id="icoResponsible"></i>
              <TooltipComponent id="ttResponsible" target="icoResponsible" content={`Responsible Person: ${RiskData.ResponsiblePersonName}<br>Responsible Team: ${RiskData.ResponsibleTeamName}`} />

              <i className="fa-regular fa-circle-user text-primary" id="icoLogging"></i>
              <TooltipComponent id="ttLogging" target="icoLogging" content={`Logging Person: ${RiskData.AddedByName}<br>Logging Team: ${RiskData.AddedByTeamName}<br>Logging Date: ${new Date(RiskData.DateAdded).toDateString()}`} />

              <i className="fas fa-info-circle" id="icoDescription"></i>
              <TooltipComponent id="ttDescription" target="icoDescription" content={'<b>DESCRIPTION</b><br>'+RiskData.Description} />

              <i class="far fa-lightbulb" id="icoOpportunities"></i>
              <TooltipComponent id="ttOpportunities" target="icoOpportunities" content={'<b>OPPORTUNITIES</b><br>' +RiskData.Opportunities} />

              <i class="fas fa-chart-line" id="icoIndicators"></i>
              <TooltipComponent id="ttIndicators" target="icoIndicators" content={'<b>INDICATORS</b><br>' +RiskData.Indicators} />

            </div>
            <div className='col-md-4 text-center'><h5>RISK # {RiskData.RiskNumber}</h5></div>
            <div className="col-md-4 text-end">
              <span className={'risk-status-box ' + (statuses.find(item => item.Id === RiskData.Status)?.Title.toLowerCase())}>{statuses.find(item => item.Id === RiskData.Status)?.Title}</span>
            </div>
          </div>
          <div className='row'>
            <div className='mb-3 col-md-8 col-lg-3'>
              <div className="card risk-detail">
                <div className="card-body">Short Name</div>
                <div className="card-footer">{RiskData.Name}</div>
              </div>

            </div>
            <div className='mb-3 col-md-4 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Type</div>
                <div className="card-footer">{RiskData.RiskTypeName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-5 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Category</div>
                <div className="card-footer">{RiskData.RiskCategoryName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-5 col-lg-3'>
              <div className="card risk-detail">
                <div className="card-body">Site</div>
                <div className="card-footer">{RiskData.SiteStructureName}</div>
              </div>
            </div>
            <div className='mb-3 col-md-2 col-lg-2'>
              <div className="card risk-detail">
                <div className="card-body">Onset</div>
                <div className="card-footer">{RiskData.SpeedOfOnset + "m"}</div>
              </div>
            </div>
          </div>

        </div>

      </div>






    </>
  );
};

export default RiskSummary;