/* eslint-disable array-callback-return */
/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import { Rating } from "@syncfusion/ej2-react-inputs";
import moment from "moment";

export const OnsetFilter = (Onset, Risks) => {
    Risks = Risks.filter(c => {
        const today = moment();
        const riskDate = moment(c.DateAdded);
        const monthDiff = today.diff(riskDate, "M");
        switch (Onset) {
            case 1:
                return monthDiff < 4;
            case 2:
                return monthDiff > 3 && monthDiff < 7;
            case 3:
                return monthDiff > 6 && monthDiff < 10;
            case 4:
                return monthDiff > 9 && monthDiff < 13;
            case 5:
                return monthDiff > 12 && monthDiff < 19;
            case 6:
                return monthDiff > 18 && monthDiff < 25;
            case 7:
                return monthDiff > 24 && monthDiff <= 48;
            default:
                return monthDiff > 48
        }
    });

    return Risks;
}

export const PeopleFilter = (PeopleCat, Person, Risks) => {
    let PersonTeamId = 0;
    if (Person != 0)
        PersonTeamId = parseInt(Person.split("-")[1])

    if (PeopleCat > 0) {
        switch (PeopleCat) {
            case 1:
                if (PersonTeamId > 0)
                    Risks = Risks.filter(c => c.ResponsiblePersonId == PersonTeamId);
                break;
            case 2:
                if (PersonTeamId > 0)
                    Risks = Risks.filter(c => c.ResponsibleTeamId == PersonTeamId);
                break;
            case 3:
                if (PersonTeamId > 0)
                    Risks = Risks.filter(c => c.AddedBy == PersonTeamId);
                break;
            case 4:
                if (PersonTeamId > 0)
                    Risks = Risks.filter(c => c.AddedByTeamId == PersonTeamId);
                break;
        }
    } else if (PersonTeamId > 0) {
        if (Person.includes("user"))
            Risks = Risks.filter(c => c.ResponsiblePersonId == PersonTeamId || c.AddedBy == PersonTeamId);
        else
            Risks = Risks.filter(c => c.ResponsibleTeamId == PersonTeamId || c.AddedByTeamId == PersonTeamId);
    }

    return Risks;
}

export const ObjectiveFilter = (ObjectiveId, Risks) => {
    return Risks.filter(c => {
        let idx = -1;
        if (c.BusinessObjectives && c.BusinessObjectives.length > 0)
            idx = c.BusinessObjectives.findIndex(i => i.Id == ObjectiveId);
        return idx > -1;
    })
}

export const ProcessFilter = (ProcessId, Risks) => {
    return Risks.filter(c => {
        let idx = -1;
        if (c.BusinessProcesses && c.BusinessProcesses.length > 0)
            idx = c.BusinessProcesses.findIndex(i => i.Id == ProcessId);
        return idx > -1;
    })
}

export const CapitalFilter = (CapitalId, Risks) => {
    return Risks.filter(c => {
        let idx = -1;
        if (c.Capitals && c.Capitals.length > 0)
            idx = c.Capitals.findIndex(i => i.Id == CapitalId);
        return idx > -1;
    })
}

export const StakeholderFilter = (StakeholderId, Risks) => {
    return Risks.filter(c => {
        let idx = -1;
        if (c.Stakeholders && c.Stakeholders.length > 0)
            idx = c.Stakeholders.findIndex(i => i.Id == StakeholderId);
        return idx > -1;
    })
}

export const TopNFilter = (RatingPerspective, HowMany, OfWhat, Risks) => {

    let FilterRiskData = [];
    Risks.sort((a, b) => {
        let AmountA = 0;
        let AmountB = 0;
        if (RatingPerspective > 0) {
            switch (OfWhat) {
                case 0:
                    AmountA = a.RiskPotentialEvaluation ? a.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    AmountB = b.RiskPotentialEvaluation ? b.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    return AmountB - AmountA;
                case 1:
                    AmountA = a.RiskActualEvaluation ? a.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    AmountB = b.RiskActualEvaluation ? b.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    return AmountB - AmountA;
                case 2:
                    AmountA = a.RiskTargetEvaluation ? a.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    AmountB = b.RiskTargetEvaluation ? b.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact : 0;
                    return AmountB - AmountA;
                case 3:
                    return b.PotentialActualGap - a.PotentialActualGap;
                case 4:
                    return b.ActualTargetGap - a.ActualTargetGap;
                case 5:
                    AmountA = a.RiskPotentialEvaluation ? a.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    AmountB = b.RiskPotentialEvaluation ? b.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    return AmountB - AmountA;
                case 6:
                    AmountA = a.RiskActualEvaluation ? a.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    AmountB = b.RiskActualEvaluation ? b.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    return AmountB - AmountA;
                case 7:
                    AmountA = a.RiskTargetEvaluation ? a.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    AmountB = b.RiskTargetEvaluation ? b.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent : 0;
                    return AmountB - AmountA;
            }



        } else {
            switch (OfWhat) {
                case 0:
                    AmountA = a.RiskPotentialEvaluation ? a.RiskPotentialEvaluation.LikelyImpact : 0;
                    AmountB = b.RiskPotentialEvaluation ? b.RiskPotentialEvaluation.LikelyImpact : 0;
                    return AmountB - AmountA;
                case 1:
                    AmountA = a.RiskActualEvaluation ? a.RiskActualEvaluation.LikelyImpact : 0;
                    AmountB = b.RiskActualEvaluation ? b.RiskActualEvaluation.LikelyImpact : 0;
                    return AmountB - AmountA;
                case 2:
                    AmountA = a.RiskTargetEvaluation ? a.RiskTargetEvaluation.LikelyImpact : 0;
                    AmountB = b.RiskTargetEvaluation ? b.RiskTargetEvaluation.LikelyImpact : 0;
                    return AmountB - AmountA;
                case 3:
                    return b.PotentialActualGap - a.PotentialActualGap;
                case 4:
                    return b.ActualTargetGap - a.ActualTargetGap;
                case 5:
                    a.RiskPotentialEvaluation && a.RiskPotentialEvaluation.MetaData.map(item => AmountA += item.FinComponent);
                    b.RiskPotentialEvaluation && b.RiskPotentialEvaluation.MetaData.MetaData.map(item => AmountB += item.FinComponent);
                    return AmountB - AmountA;
                case 6:
                    a.RiskActualEvaluation && a.RiskActualEvaluation.MetaData.map(item => AmountA += item.FinComponent);
                    b.RiskActualEvaluation && b.RiskActualEvaluation.MetaData.MetaData.map(item => AmountB += item.FinComponent);
                    return AmountB - AmountA;
                case 7:
                    a.RiskTargetEvaluation && a.RiskTargetEvaluation.MetaData.map(item => AmountA += item.FinComponent);
                    b.RiskTargetEvaluation && b.RiskTargetEvaluation.MetaData.MetaData.map(item => AmountB += item.FinComponent);
                    return AmountB - AmountA;
            }
        }
    }).map(item => {
        if (FilterRiskData.length < HowMany || HowMany == 0)
            FilterRiskData.push(item);
        else
            return FilterRiskData;
    })

    return FilterRiskData;
}

export const AboveAmountFilter = (RatingPerspective, AboveAmount, OfWhat, Risks) => {
    let FilterRiskData = [];

    FilterRiskData = Risks.filter(item => {
        let Amount = 0;

        if (RatingPerspective > 0) {
            switch (OfWhat) {
                case 0:
                    Amount = 0;
                    if (item.RiskPotentialEvaluation)
                        Amount = item.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact
                    break;
                case 1:
                    Amount = 0;
                    if (item.RiskActualEvaluation)
                        Amount = item.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact
                    break;
                case 2:
                    Amount = 0;
                    if (item.RiskTargetEvaluation)
                        Amount = item.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).LikelyImpact
                    break;
                case 3:
                    return item.PotentialActualGap >= AboveAmount;
                case 4:
                    return item.ActualTargetGap >= AboveAmount;
                case 5:
                    Amount = 0;
                    if (item.RiskPotentialEvaluation)
                        Amount = item.RiskPotentialEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent
                    break;
                case 6:
                    Amount = 0;
                    if (item.RiskActualEvaluation)
                        Amount = item.RiskActualEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent;
                    break;
                case 7:
                    Amount = 0;
                    if (item.RiskTargetEvaluation)
                        Amount = item.RiskTargetEvaluation.MetaData.find(c => c.PerspectiveId == RatingPerspective).FinComponent;
                    break;
            }
        } else {
            switch (OfWhat) {
                case 0:
                    Amount = 0;
                    if (item.RiskPotentialEvaluation)
                        Amount = item.RiskPotentialEvaluation.TotalLikelyImpact
                    break;
                case 1:
                    Amount = 0;
                    if (item.RiskActualEvaluation)
                        Amount = item.RiskActualEvaluation.TotalLikelyImpact
                    break;
                case 2:
                    Amount = 0;
                    if (item.RiskTargetEvaluation)
                        Amount = item.RiskTargetEvaluation.TotalLikelyImpact
                    break;
                case 3:
                    return item.PotentialActualGap >= AboveAmount;
                case 4:
                    return item.ActualTargetGap >= AboveAmount;
                case 5:
                    Amount = 0;
                    if (item.RiskPotentialEvaluation)
                        item.RiskPotentialEvaluation.MetaData.map(item => Amount += item.FinComponent);
                    break;
                case 6:
                    Amount = 0;
                    if (item.RiskActualEvaluation)
                        item.RiskActualEvaluation.MetaData.map(item => Amount += item.FinComponent);
                    break;
                case 7:
                    Amount = 0;
                    if (item.RiskTargetEvaluation)
                        item.RiskTargetEvaluation.MetaData.map(item => Amount += item.FinComponent);
                    break;
            }
        }
        return Amount >= AboveAmount
    })
    return FilterRiskData;
}