import React, { Component } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card, CardBody } from 'reactstrap';
import { PieChart, Pie, BarChart, Bar, Legend, Tooltip, Cell, ResponsiveContainer, Rectangle, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Link } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class HealthTreatRisk extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            Title: '',
            GraphTitle: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DisplayOrder: 0,

        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Data Health - Treat the Risks";
        this.loadData();
    }

    editItem = async (data, title) => {

        const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toISOString().split("T")[0] }));
        this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
    };

    getStatusCounts = (risks, statuses, treatStatuses, gca) => {
        const risksArray = [];
        const counts = treatStatuses.map((treatStatus) => {
            const noNeedGCARisks = risks.filter(
                (risk) =>
                    statuses.some((status) => risk.Id === status.RiskId && risk.Id === treatStatus.RiskId) &&
                    treatStatus.Percentage === 100
            ).map((risk) => ({ ...risk, Result: "Does not need GCAs" }));

            const needsGCARisks = risks.filter(
                (risk) =>
                    statuses.some((status) => risk.Id === status.RiskId && risk.Id === treatStatus.RiskId && gca.RiskId === risk.Id) &&
                    treatStatus.Percentage !== 100 &&
                    gca.some((gcaRisk) => gcaRisk.RiskId === risk.Id)
            ).map((risk) => ({ ...risk, Result: "Needs GCAs" }));

            const unknownRisks = risks.filter(
                (risk) =>
                    statuses.some((status) => risk.Id === status.RiskId) &&
                    !statuses.some((status) => risk.Id === status.RiskId && status.Percentage === 100)
            ).map((risk) => ({ ...risk, Result: "Not fully evaluated" }));

            risksArray.push(...noNeedGCARisks, ...needsGCARisks, ...unknownRisks);
            //console.log(risksArray)
            const noNeedGCACount = noNeedGCARisks.length;
            const needsGCACount = needsGCARisks.length;
            const unknownCount = unknownRisks.length;

            return [
                { name: "Does not need GCAs", value: noNeedGCACount },
                { name: "Needs GCAs", value: needsGCACount },
                { name: "Not fully evaluated", value: unknownCount },
            ];
        });

        return { counts: counts.flat(), risks: risksArray };
    }

    getLateGCACounts = (risks, evaluations, gca) => {
        const risksArray = [];

        const currentDate = new Date();

        const aheadRisks = risks.filter((risk) =>
            evaluations.TargetData.some((evalu) =>
                risk.Id === evalu.RiskId && new Date(evalu.TargetedCompletionDate) > currentDate
            )
        ).map((risk) => ({ ...risk, Result: "Ahead of Target Date" }));

        const behindRisks = risks.filter((risk) =>
            evaluations.TargetData.some((evalu) =>
                risk.Id === evalu.RiskId && new Date(evalu.TargetedCompletionDate) < currentDate
            )
        ).map((risk) => ({ ...risk, Result: "Behind Target Date" }));

        risksArray.push(...aheadRisks, ...behindRisks);

        const aheadCount = aheadRisks.length;
        const behindCount = behindRisks.length;

        return {
            counts: [
                { name: "Ahead of Target Date", value: aheadCount },
                { name: "Behind Target Date", value: behindCount },
            ],
            risks: risksArray,
        };
    }

    getInadequateCounts = (risks, treatStatuses) => {
        const risksArray = [];

        const completeRisks = risks.filter((risk) =>
            treatStatuses.some((status) => risk.Id === status.RiskId && status.Percentage === 100)
        ).map((risk) => ({ ...risk, Result: 'Complete Gap-Closing Actions' }));

        const notCompleteRisks = risks.filter((risk) =>
            treatStatuses.some((status) => risk.Id === status.RiskId && status.Percentage !== 100)
        ).map((risk) => ({ ...risk, Result: 'Incomplete Gap-Closing Actions' }));

        risksArray.push(...completeRisks, ...notCompleteRisks);

        const completeStatusCount = completeRisks.length;
        const notCompleteStatusCount = notCompleteRisks.length;

        return {
            counts: [
                { name: 'Complete Gap-Closing Actions', value: completeStatusCount },
                { name: 'Incomplete Gap-Closing Actions', value: notCompleteStatusCount },
            ],
            risks: risksArray,
        };
    }

    getInconsistentCounts = (risks, evaluations, treatStatuses) => {
        const currentDate = new Date();

        const inconsistentRisks = risks.filter((risk) =>
            evaluations.TargetData.some((evalu) => risk.Id === evalu.RiskId) &&
            treatStatuses.some((status) => risk.Id === status.RiskId)
        );

        const aheadRisks = inconsistentRisks.map(
            (risk) => ({
                ...risk,
                Result: evaluations.TargetData
                    .filter((evalu) => risk.Id === evalu.RiskId)
                    .some((evalu) => new Date(evalu.TargetedCompletionDate) > currentDate) ?
                    "Ahead of Target Date and Incomplete" : null
            })
        ).filter((risk) => risk.Result !== null);

        const behindRisks = inconsistentRisks.map(
            (risk) => ({
                ...risk,
                Result: evaluations.TargetData
                    .filter((evalu) => risk.Id === evalu.RiskId)
                    .some((evalu) => new Date(evalu.TargetedCompletionDate) < currentDate) ?
                    "Behind Target Date and Incomplete" : null
            })
        ).filter((risk) => risk.Result !== null);

        return {
            counts: [
                { name: 'Ahead of Target Date and Incomplete', value: aheadRisks.length },
                { name: 'Behind Target Date and Incomplete', value: behindRisks.length },
            ],
            risks: [...aheadRisks, ...behindRisks],
        };
    }

    renderTable(data) {
        //console.log(data)
        return (
            <div className='mb-3' >
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

                            <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
                        </div>
                        <GridComponent dataSource={data} >
                            <ColumnsDirective>
                                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                                {data.some(obj => obj.hasOwnProperty('Result')) && <ColumnDirective headerText='Result' field='Result'></ColumnDirective>}
                            </ColumnsDirective>
                        </GridComponent>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderDataTable(data, data2, data3, data4, colors, colors2, pieColors) {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
                </text>
            );
        };
        return (
            <>
                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Which risks need gap-closing actions?")}>
                        <strong>Which risks need gap-closing actions?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                                    {data.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors2[index % colors2.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data2.risks, "Which risks have gap-closing actions that are running late?")}>
                        <strong>Which risks have gap-closing actions that are running late?</strong>
                        <ResponsiveContainer width="100%" height={300}>
                            < PieChart width={500} height={300} >
                                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data2.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data3.risks, "Are there risks with inadequate gap-closing actions?")}>
                        <strong>Are there risks with inadequate gap-closing actions?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data3.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} >
                                    {data3.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>

                    <div className="text-center my-3 text-light" onClick={() => this.editItem(data4.risks, "Are there risks with GCAs with inconsistent inconsistent tracking information?")}>
                        <strong>Are there risks with GCAs with inconsistent inconsistent tracking information?</strong>
                        <ResponsiveContainer width="100%" height={400}>
                            < PieChart width={500} height={300} >
                                <Pie data={data4.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                                    {data4.counts.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart >
                        </ResponsiveContainer>
                    </div>
                </div>
            </>
        );
    }

    render() {
        let riskAnalysis;
        let lateGCA;
        let inadequateGCA;
        let inconsistentGCA;

        const colors = ['#ffb6c1', '#c71585'];
        const colors2 = ['#32cd32', '#ffb6c1', '#c71585'];
        const pieColors = ['#32cd32', '#ffb6c1'];

        if (!this.state.loading) {
            riskAnalysis = this.getStatusCounts(this.state.editData, this.state.RiskEvaluateStatusData, this.state.RiskTreatStatusData, this.state.GapClosingActionsList);
            lateGCA = this.getLateGCACounts(this.state.editData, this.state.EvaluationData, this.state.GapClosingActionsList);

            inadequateGCA = this.getInadequateCounts(this.state.editData, this.state.RiskTreatStatusData);
            inconsistentGCA = this.getInconsistentCounts(this.state.editData, this.state.EvaluationData, this.state.RiskTreatStatusData);

        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(riskAnalysis, lateGCA, inadequateGCA, inconsistentGCA, colors, colors2, pieColors);
        //console.log(riskAnalysis, lateGCA, inadequateGCA, inconsistentGCA)
        return (
            <>
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <h2>Data Health<br /><small>- Treat the Risks -</small></h2>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
                            <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, riskAnalysis.counts, lateGCA.counts, inadequateGCA.counts, inconsistentGCA.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>
                <div>

                </div>
                {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.editModal && this.renderTable(this.state.GraphData)}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal >}
                <ThrobbleHelper />
            </>

        );
    }

    async loadData() {
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        try {
            const response = await fetch(`api/gapclosingactions/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ GapClosingActionsList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskevaluatestatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ RiskEvaluateStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/risktreatstatus`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data);
                this.setState({ RiskTreatStatusData: data });
            }
            else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/riskevaluation/GetRiskEvaluations`, {
                method: "GET",
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ EvaluationData: data });
            }
        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    handleNavigate("/login");
            }

        } catch (e) {
            console.error(e);
        }

        setAuthToken(getAuthToken(), new Date());
    }

    handleExport = async (e, riskAnalysis, lateGCA, inadequateGCA, inconsistentGCA) => {
        e.stopPropagation();
        ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
        const { handleNavigate } = this.context;
        var bearer = 'Bearer ' + getAuthToken();
        const userData = getUserDetails();

        let postData = {
            PieData: JSON.stringify(riskAnalysis),
            Pie2Data: JSON.stringify(lateGCA),
            Pie3Data: JSON.stringify(inadequateGCA),
            Pie4Data: JSON.stringify(inconsistentGCA),
            Pie5Data: JSON.stringify([]),
            BarData: JSON.stringify([]),
            Bar2Data: JSON.stringify([]),
        }

        try {
            const response = await fetch('api/risks/downloadhealthtreatrisk', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    ThrobbleHelper.ToggleThrobble(false);
                    handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async ExportReport(evt, data) {
        //const { handleNavigate } = this.context;
        const finalData = data.map(item => ({
            ResponsibleTeamName: item.ResponsibleTeamName,
            ResponsiblePersonName: item.ResponsiblePersonName,
            RiskNumber: item.RiskNumber,
            RiskName: item.Name,
            DateLogged: item.DateAdded,
            LoggedBy: item.AddedByName,
            Result: item.Result,
        }));
        evt.stopPropagation();
        var bearer = 'Bearer ' + getAuthToken();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
        try {

            //console.log(data)
            const response = await fetch("api/risks/datahealthtreatrisk", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.Item1;
                const MimeType = data.Item2;
                const Filename = data.Item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                ToastUtility.show({
                    title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
                });

            } else {
                if (response.status === 401) {
                    //handleNavigate("/login");
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    ToastUtility.show({
                        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                };
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            ToastUtility.show({
                title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }

        setAuthToken(getAuthToken(), new Date());
    }
}

export default HealthTreatRisk;