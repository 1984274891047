import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { MDBBtn } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGroup, FormText, Input, Label } from "reactstrap";

function ResetPassword() {
  const { id } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fieldRequired = (value, errorState, errorMessage) => {
    if (!value) {
      setError(errorState, errorMessage);
    } else {
      setError(errorState, null);
    }
  };

  const setError = (errorState, errorMessage) => {
    switch (errorState) {
      case 'errPassword':
        setPasswordError(errorMessage);
        break;
      case 'errConfirmPassword':
        setConfirmPasswordError(errorMessage);
        break;
      default:
        break;
    }
  };

  const passwordFieldRequired = (isMatch, errorState, errorMessage) => {
    if (!isMatch) {
      setError(errorState, errorMessage);
    } else {
      setError(errorState, null);
    }
  };

  const handleResetPassword = async (e) => {
    //e.preventDefault();
    setLoading(true);
    const response = await fetch('/api/login/resetpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        password,
      }),
    });

    if (response.ok) {
      setLoading(false);
      ToastUtility.show({
        title: 'Reset password', content: 'Password reset successfully', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
      });
      navigate("/login");
    } else {
      setLoading(false);
      ToastUtility.show({
        title: 'Reset password', content: 'There was an error resetting password', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  };

  useEffect(() => {
    document.title = "OnPoint RMS :: Reset Password";
  }, []);

  return (

    <>
      <div className="login-container">
        <div className="">
          <div className="row">
            <div className="card px-0">
              <div className="card-header login-title">
                <h5 className="card-title">On<span>Point</span> Risk Management System</h5>
                Empowering Decisions with Precision
              </div>
              <div className="card-body">
                <h6 className='mb=3 text-uppercase'>Reset Password</h6>

                <div className="mb-3">
                  <FormGroup>
                    <Label>
                      Password
                      <span className="required-icon">*</span>
                      <span id="errPassword" className="required-icon">
                        {passwordError}
                      </span>
                    </Label>
                    <Input type="password" bsSize="sm" onChange={(e) => { setPassword(e.target.value); fieldRequired(e.target.value, 'errPassword', 'required'); }} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Confirm Password
                      <span className="required-icon">*</span>
                      <span id="errConfirmPassword" className="required-icon">
                        {confirmPasswordError}
                      </span>
                    </Label>
                    <Input type="password" bsSize="sm" onChange={(e) => { passwordFieldRequired(e.target.value === password, "errConfirmPassword", "Password doesn't match"); }} />
                    <FormText color="muted">
                      Password entered here should match the one in the above password
                      field.
                    </FormText>
                  </FormGroup>

                </div>
              </div>
              <div className="card-footer text-end">
                <MDBBtn onClick={handleResetPassword} size='sm'>Reset Password &nbsp;{!loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}</MDBBtn>
                <div className="version">Version: 0.1</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;