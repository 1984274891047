/* eslint-disable no-extend-native */

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    const target = this;
    // Escape special characters in the search string
    const escapedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    return target.replace(new RegExp(escapedSearch, 'g'), replacement);
  }
};

String.prototype.convertToNumber = function (currencySymbol = "") {
  const target = this;
  let result = target.replaceAll(",", "");
  result = result.replaceAll(currencySymbol, "");
  return parseFloat(result);
};