import { useEffect, useState } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { GetRatingBand } from '../../../helpers/global';

const RiskValues = ({ RiskData, CompanyData, SiteData, PotentialClick, ActualClick, TargetClick }) => {
  const [potentialActualTargetData, setPotentialActualTargetData] = useState();
  

  const loadRiskData = async (riskData) => {
    const userData = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    
    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations/${userData.CompanyId}/${riskData.Id}/${userData.UserRole}`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setPotentialActualTargetData(data);
      } else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    loadRiskData(RiskData);
  }, [RiskData]);

  return (
    <>
      <div className='d-flex justify-content-around align-items-center flex-wrap'>
        <div className='d-flex text-center risk-header mb-3 col-md'>
          {potentialActualTargetData ? <>

            <div onClick={PotentialClick} style={{ cursor: "pointer" }} className={CompanyData.PotentialRiskUsed === 1 ? 'risk-box' : 'd-none'} >
              <small>POTENTIAL</small>
              {potentialActualTargetData.PotentialData.OverallRating ? <div>{potentialActualTargetData.PotentialData.OverallRating + " - " + GetRatingBand(potentialActualTargetData.PotentialData.OverallRating, potentialActualTargetData.RiskImpactRatings, SiteData.ImmaterialityLevel, SiteData.CatastrophicLevel, SiteData.NumOfLevels, SiteData.TypeId, potentialActualTargetData.PotentialData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + potentialActualTargetData.PotentialData.TotalLikelyImpact.toNumberString()}</div>
                : <div>NYS</div>}
            </div>

            <div onClick={ActualClick} style={{ cursor: "pointer" }} className='risk-box'>
              <small>ACTUAL</small>
              {potentialActualTargetData.ActualData.OverallRating ? <div>{potentialActualTargetData.ActualData.OverallRating + " - " + GetRatingBand(potentialActualTargetData.ActualData.OverallRating, potentialActualTargetData.RiskImpactRatings, SiteData.ImmaterialityLevel, SiteData.CatastrophicLevel, SiteData.NumOfLevels, SiteData.TypeId, potentialActualTargetData.ActualData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + potentialActualTargetData.ActualData.TotalLikelyImpact.toNumberString()}</div>
                : <div>NYS</div>}
            </div>
            <div onClick={TargetClick} style={{ cursor: "pointer" }} className='risk-box'>
              <small>TARGET</small>
              {potentialActualTargetData.TargetData.OverallRating ? <div>{potentialActualTargetData.TargetData.OverallRating + " - " + GetRatingBand(potentialActualTargetData.TargetData.OverallRating, potentialActualTargetData.RiskImpactRatings, SiteData.ImmaterialityLevel, SiteData.CatastrophicLevel, SiteData.NumOfLevels, SiteData.TypeId, potentialActualTargetData.TargetData.TotalLikelyImpact)}<br />{getUserDetails().Currency + " " + potentialActualTargetData.TargetData.TotalLikelyImpact.toNumberString()}</div>
                : <div>NYS</div>}
            </div></> : ""
          }
        </div>
      </div>
    </>
  );
};

export default RiskValues;