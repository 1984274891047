/* eslint-disable array-callback-return */
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import React, { Component } from 'react';
import { CalculateOverallRating, CalculateTotalLikelyImpact, GetLikelyImpact, GetMaxImpactRating, GetMaxRatingAmount, GetProbabilityValue, GetRatingBand, GetRatingImpact } from '../../../helpers/global';
import { Button, Card, CardBody, CardHeader, Label } from 'reactstrap';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { fieldRequired } from '../../../helpers/validation';
import { getAuthToken } from '../../../helpers/authentication';
import moment from 'moment';
import { Context } from '../../../helpers/Context';

export default class EditEvaluation extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      EvaluationType: 0,
      EditId: 0,
      RiskId: 0,
      EditData: [],
      RatingImpactData: [],
      OverallRating: 0,
      ProbabilityRating: 0,
      TotalLikelyImpact: 0,
      CompleteStatus: 0,
      Comment: "",
      DateUpdated: null,
      UpdatedBy: "",
      NewCompleteStatus: 0,
      NewComment: "",
      loading: false,
      IsReadOnly: false,
      selectedRootsIndex: [],
      selectedConsIndex: [],
      TargetedCompletionDate: null,
    }
    this.OnValueChange = this.OnValueChange.bind(this);
    this.saveEvaluation = this.saveEvaluation.bind(this);
  }

  componentDidMount() {
    const { EvaluationType, IsReadOnly, RiskId, CurrentData, showPrevEvaluation, PrevEvaluationData, RatingImpactData } = this.props;
    this.setState({
      RatingImpactData, EvaluationType, IsReadOnly, showPrevEvaluation,
      PrevEvaluationData, EditId: CurrentData.Id,
      RiskId, EditData: CurrentData.MetaData, ProbabilityRating: CurrentData.ProbabilityRating,
      OverallRating: CurrentData.OverallRating, UpdatedBy: CurrentData.UpdatedBy,
      DateUpdated: moment(CurrentData.DateModified).format("DD/MM/yy"),
      TargetedCompletionDate: CurrentData.TargetedCompletionDate ? CurrentData.TargetedCompletionDate : null,
      CompleteStatus: CurrentData.CompleteStatus, Comment: CurrentData.Comment, loading: false
    });
  }

  handleItemClick = (id, roots, cons) => {
    if (roots) {
      this.setState({ selectedRootsIndex: roots, selectedRootCauseId: roots[0] });
    }
    if (cons) {
      this.setState({ selectedConsIndex: cons, selectedConsequenceId: cons[0] });
    }
  };

  handleRootCauseClick = (id) => {

    if (id) {
      this.setState({ selectedConsequenceId: 0, selectedRootCauseId: id, selectedRootsIndex: [], selectedConsIndex: [] });
    }
  };

  handleConsequenceClick = (id) => {

    if (id) {
      this.setState({ selectedRootCauseId: 0, selectedConsequenceId: id, selectedRootsIndex: [], selectedConsIndex: [] });
    }
  };

  getBackgroundClass() {
    let result = " bg-evaluation " + this.props.EvaluationType.toLowerCase();
    return result;
  }

  renderEvaluationForm() {
    const P = GetProbabilityValue(this.state.ProbabilityRating);
    const MaxRating = GetMaxImpactRating(this.state.RatingImpactData);
    //console.log(this.props.Controls, this.state.selectedRootsIndex, this.state.selectedConsIndex)
    console.log(this.props.RootCauses);
    console.log(this.props.Consequences);
    return (
      <div className={'row my-4 ' + this.getBackgroundClass()}>
        {!this.props.RootCauses?.length < 1 && !this.props.Consequences?.length < 1 && this.props.EvaluationType === 'Potential' ? <div className='d-flex justify-content-between mb-3'>
          <Card className=' col-md-4'>
            <CardHeader>
              Root Causes
            </CardHeader>
            <CardBody>
              <div className='text-center mb-2'><small>When rating the Probability, consider ALL these root causes</small></div>
              {/*<ul className='px-3'>*/}
              <table>
                {this.props.RootCauses && this.props.RootCauses.map((item, index) =>
                (
                  <tr key={index}>
                    <td className="pe-1">{item.IsUsed ? "" : <i className="fa-solid fa-link-slash text-danger me-2" title="This cause has not yet been linked to any control"></i>}</td>
                    <td>{item.ShortName}</td>
                  </tr>
                 /* <li key={index}>{item.ShortName}</li>*/
                ))}
              </table>
                {/*{this.props.RootCauses && this.props.RootCauses.map((item, index) =>*/}
                {/*(<li key={index}>{item.ShortName}</li>*/}
                {/*))}*/}
              {/*</ul>*/}
            </CardBody>
          </Card>

          <Card className='transparent col-md-4'>
            <CardHeader>
              Consequences
            </CardHeader>
            <CardBody>
              <div className='text-center mb-2'><small>When rating each Impact Type, consider only relevant consequences</small></div>
              {/*<ul className='px-3'>*/}
                {this.props.Consequences && this.props.Consequences.map((item, index) =>
                (
                  /*<li key={index}>{item.ShortName}</li>*/
                  <tr key={index}>
                    <td className="pe-1">{item.IsUsed ? "" : <i className="fa-solid fa-link-slash text-danger me-2" title="This consequence has not yet been linked to any control"></i>}</td>
                    <td>{item.ShortName}</td>
                  </tr>
                ))}
            {/*</ul>*/}
            </CardBody>
          </Card>
        </div> : !this.props.RootCauses?.length < 1 && !this.props.Consequences?.length < 1 && this.props.EvaluationType === 'Actual' ? <div className='d-flex flex-wrap justify-content-between gap-2 mb-3'>
          <Card className='transparent col-md'>
            <CardHeader>Root Causes</CardHeader>
            <CardBody>
              <div className='text-center mb-2'>
                  <small>
                (Click a Root Cause to see related controls)<br />
                    <span className="text-muted">When rating the Probability, consider ALL these root causes</span></small>
              </div>
                {/*<ul className='px-3'>*/}
              <table>
                {this.props.RootCauses && this.props.RootCauses.map((item, index) =>
                (
                  /*<li key={index} onClick={() => this.handleRootCauseClick(item.Id, null, null)} style={{ backgroundColor: this.state.selectedRootsIndex.includes(item.Id) ? 'lightgreen' : this.state.selectedRootCauseId === item.Id ? 'lightgreen' : 'transparent' }}>{item.ShortName}</li>*/
                  <tr key={index}>
                    <td className="pe-1">{item.IsUsed ? "" : <i className="fa-solid fa-link-slash text-danger me-2" title="This cause has not yet been linked to any control"></i>}</td>
                    <td className="px-1" onClick={() => this.handleRootCauseClick(item.Id, null, null)} style={{ backgroundColor: this.state.selectedRootsIndex.includes(item.Id) ? 'lightgreen' : this.state.selectedRootCauseId === item.Id ? 'lightgreen' : 'transparent' }}>{item.ShortName}</td>
                  </tr>
                ))}
                </table>
                {/*</ul>*/}
            </CardBody>
          </Card>

          <Card className='transparent col-md-6'>
            <CardHeader>Controls</CardHeader>
            <CardBody>
              <div className='text-center mb-2'><small>(Click a Control to see related root causes & consequences)</small></div>

              {this.props.Controls && this.props.Controls.map((item, index) =>
              (<div key={index} className='d-flex flex-row justify-content-around align-items-center' onClick={() => this.handleItemClick(null, item.ConnectedRootCauses, item.ConnectedConsequences)}>
                <div className='px-1'>
                  {item.ControlNumber}
                </div>
                <div className='col-md px-2 pb-3' style={{ backgroundColor: item.ConnectedRootCauses.includes(this.state.selectedRootCauseId) ? 'lightgreen' : item.ConnectedConsequences.includes(this.state.selectedConsequenceId) ? 'lightgreen' : 'transparent' }}>
                  <TextBoxComponent placeholder='Control' type='text' disabled maxLength='2500' floatLabelType='Always' value={item.ShortName} />
                </div>
                <div className='px-1 pb-3'>
                  <small>Critical?</small>
                  {item.Critical === false && item.NeedsAssurance ?
                    <div style={{ width: "30px", height: "30px", backgroundColor: "orange" }}></div> : item.Critical ?
                      <div style={{ width: "30px", height: "30px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-4"></i></div> :
                      <div style={{ width: "30px", height: "30px", border: "1px grey solid" }}></div>
                  }
                  {/*<CheckBoxComponent id={'cbCritical' + index} disabled name={'cbCritical' + index} type='checkbox' label='Critical?' checked={item.Critical} />*/}
                </div>
              </div>
              ))}
            </CardBody>
          </Card>

          <Card className='transparent col-md'>
            <CardHeader>Consequences</CardHeader>
            <CardBody>
              <div className='text-center mb-2'>
                (Click a Consequence to see related controls)<br />
                <small>When rating each Impact Type, consider only relevant consequences</small>
              </div>
                {/*<ul className='px-3'>*/}
                <table>
                {this.props.Consequences && this.props.Consequences.map((item, index) =>
                (
                  //<li key={index} onClick={() => this.handleConsequenceClick(item.Id, null, null)} style={{ backgroundColor: this.state.selectedConsIndex.includes(item.Id) ? 'lightgreen' : this.state.selectedConsequenceId === item.Id ? 'lightgreen' : 'transparent' }}>{item.ShortName}
                  //</li>

                  <tr key={index}>
                    <td className="pe-1">{item.IsUsed ? "" : <i className="fa-solid fa-link-slash text-danger me-2" title="This cause has not yet been linked to any control"></i>}</td>
                    <td className="px-1" onClick={() => this.handleConsequenceClick(item.Id, null, null)} style={{ backgroundColor: this.state.selectedConsIndex.includes(item.Id) ? 'lightgreen' : this.state.selectedConsequenceId === item.Id ? 'lightgreen' : 'transparent' }}>{item.ShortName}</td>
                  </tr>
                ))}
                </table>
{/*              </ul>*/}
            </CardBody>
          </Card>
        </div> : ""}
        <div className="col-md-10">
          <h3 className='text-uppercase text-decoration-underline'>{this.props.EvaluationType} Risk Rating</h3>
          <small className="text-muted">{this.props.SubHeading}</small>
        </div>
        <div className="col-2 text-end">
          <label className='fw-bold'>Probability Rating <small>(%)</small>
            <span id="errProbabilityRating" className='ErrorText'></span>
          </label>
          <NumericTextBoxComponent disabled={this.props.IsReadOnly} showSpinButton={!this.props.IsReadOnly} max={100} min={0} step={1} change={e => { this.OnValueChange(e, 3, 0) }} className='text-end me-2' value={this.state.ProbabilityRating} />
        </div>
        <div className="col-12 my-3">
          <Card className='transparent'>
            <CardBody>
              <table className='table table-sm w-100'>
                <thead className='fw-bold'>
                  <tr>
                    <th>Rating Perspective</th>
                    <th className='text-center' style={{ width: "150px" }}>Score</th>
                    <th className='text-center' >Risk Level</th>
                    <th className='text-center'>Impact (I)</th>
                    <th className='text-center' >Fin. Component</th>
                    <th className='text-center' style={{ width: "75px" }}>P</th>
                    <th className='text-center' >(I * P)<br /> Likely Impact</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.EditData.sort((a, b) => a.DisplayOrder - b.DisplayOrder).map(item => {
                    const RatingImpact = GetRatingImpact(item.Rating, this.props.Site?.ImmaterialityLevel, this.props.Site?.CatastrophicLevel, this.props.Site?.NumOfLevels, this.props.Site?.TypeId);
                    const RatingBand = GetRatingBand(item.Rating, this.state.RatingImpactData, this.props.Site?.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId, RatingImpact);
                    return (<tr key={item.PerspectiveId}>
                      <td><Button type="button" color='light' disabled={this.props.IsReadOnly} className='text-start w-100'>{item.PerspectiveName}</Button></td>
                      <td><NumericTextBoxComponent disabled={this.props.IsReadOnly} showSpinButton={!this.props.IsReadOnly} className='text-end me-2' value={item.Rating} min={0} format='n1' step={1} change={e => this.OnValueChange(e, 1, item.PerspectiveId)} />
                        <div id={"errRating" + item.PerspectiveId} className='ErrorText'></div>
                      </td>
                      <td><TextBoxComponent disabled={true} value={RatingBand} /></td>
                      <td><NumericTextBoxComponent className='text-end me-2' format='n2' currencyCode='ZAR' showSpinButton={false} disabled={true} value={RatingImpact} /></td>
                      <td><NumericTextBoxComponent className='text-end me-2' format='n2' disabled={this.props.IsReadOnly} showSpinButton={!this.props.IsReadOnly} min={0} max={GetMaxRatingAmount(item.Rating, this.state.RatingImpactData)} value={item.FinComponent} change={e => this.OnValueChange(e, 2, item.PerspectiveId)} /></td>
                      <td><NumericTextBoxComponent className='text-end me-2' disabled={true} showSpinButton={false} value={P} /></td>
                      <td><NumericTextBoxComponent className='text-end me-2' format='n2' currencyCode='ZAR' disabled={true} showSpinButton={false} value={item.LikelyImpact} /></td>
                    </tr>)
                  })}
                </tbody>
              </table>
              <div className="row mb-3">
                <div className="col-md-7 mb-3 fw-bold text-end align-self-center"></div>
                <div className="col-md-3 mb-3 fw-bold text-end align-self-center">Total likely impact: </div>
                <div className="col-md-2">
                  <NumericTextBoxComponent format='n2' className='text-end me-2' currencyCode='ZAR' disabled={true} showSpinButton={false} value={this.state.TotalLikelyImpact} />
                </div>
                <div className="col-md-7 mb-3 fw-bold text-end align-self-center"></div>
                <div className="col-3 mb-3 fw-bold text-end align-self-center">
                  Total risk score:
                </div>
                <div className="col-2">
                  <NumericTextBoxComponent format='n1' className='text-end me-2' disabled={true} showSpinButton={false} value={this.state.OverallRating} />
                </div>
                <div className="col-md-7 mb-3 fw-bold text-end align-self-center"></div>
                <div className="col-md-3 mb-3 fw-bold text-end align-self-center">Total risk level: </div>
                <div className='col-2'>
                  <TextBoxComponent readOnly={true} className='text-end pe-2' value={GetRatingBand(0, this.state.RatingImpactData, this.props.Site.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId, this.state.TotalLikelyImpact)} />
                </div>
              </div>
              {this.props.EvaluationType.toLowerCase() === 'target' ?
                <div className='col-md-3 mb-3 '>
                  <DatePickerComponent placeholder='Targeted Completion Date' floatLabelType='Always' value={this.state.TargetedCompletionDate} onChange={(e) => { this.setState({ TargetedCompletionDate: e.target.value }) }} />
                </div> : ""}
            </CardBody>
          </Card>

          <div className="col-md-10 mx-md-auto" hidden={this.props.IsReadOnly}>
            <Card className='mt-3'>
              <CardBody className='text-center'>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <h4 className="text-decoration-underline">Last Update <small className='ms-2'>(%)</small></h4>
                        <NumericTextBoxComponent className='text-end pe-2' width={"20%"} readOnly={true} value={this.state.CompleteStatus} showSpinButton={false} />
                      </div>
                      <div className="col-md-8 mb-3">
                        <TextBoxComponent cssClass='mb-2' readOnly={true} value={this.state.UpdatedBy} />
                      </div>
                      <div className='col-md-4'>
                        <TextBoxComponent readOnly={true} value={this.state.DateUpdated} />
                      </div>
                      <div className="col-12">
                        <TextBoxComponent readOnly multiline placeholder='Short comment in here...' value={this.state.Comment} />
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-12 text-center mb-3">
                        <h4 className="text-decoration-underline">New Update <small className='ms-2'>(%)</small></h4>
                        <NumericTextBoxComponent cssClass='mb-2' className='text-end pe-2' width={"30%"} max={100} min={0} step={1} value={this.state.NewCompleteStatus} change={e => this.setState({ NewCompleteStatus: e.value })} />
                        <TextBoxComponent multiline placeholder='Short comment in here...' value={this.state.NewComment} change={e => this.setState({ NewComment: e.value })} />
                      </div>
                      <div className="col-12 text-end">
                        <Button color='success' onClick={this.saveEvaluation}>Save</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  OnValueChange(evt, ChangeType, PerspectiveId) {
    //console.log(this.props.Site.TypeId, Math.floor(evt.value), evt.value)
    let ProbabilityRating = this.state.ProbabilityRating;
    let EditData = this.state.EditData;
    let idx = EditData.findIndex(c => c.PerspectiveId === PerspectiveId);
    let RatingImpact = 0;
    let P = 0;
    let LikelyImpact = 0;
    let TotalLikelyImpact = this.state.TotalLikelyImpact;
    let OverallRating = this.state.OverallRating;

    let EditPerspective = null;
    switch (ChangeType) {
      case 1:
        if (idx >= 0) {
          EditPerspective = EditData[idx];
          EditPerspective.Rating = evt.value;
          RatingImpact = GetRatingImpact(EditPerspective.Rating, this.props.Site.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId);
          P = GetProbabilityValue(this.state.ProbabilityRating);
          LikelyImpact = GetLikelyImpact(RatingImpact, P);
          EditPerspective.LikelyImpact = LikelyImpact;
          EditData[idx] = EditPerspective;

          TotalLikelyImpact = CalculateTotalLikelyImpact(EditData);
          OverallRating = CalculateOverallRating(EditData, this.props.Site.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId, TotalLikelyImpact);
        }
        break;
      case 2:
        if (idx >= 0) {
          EditPerspective = EditData[idx];
          EditPerspective.FinComponent = evt.value;
          EditData[idx] = EditPerspective;
        }
        break;
      case 3:
        ProbabilityRating = evt.value;
        for (var i = 0; i < EditData.length; i++) {
          RatingImpact = GetRatingImpact(EditData[i].Rating, this.props.Site.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId);
          P = GetProbabilityValue(ProbabilityRating);
          LikelyImpact = GetLikelyImpact(RatingImpact, P);
          EditData[i].LikelyImpact = LikelyImpact;
        }
        TotalLikelyImpact = CalculateTotalLikelyImpact(EditData);
        OverallRating = CalculateOverallRating(EditData, this.props.Site.ImmaterialityLevel, this.props.Site.CatastrophicLevel, this.props.Site.NumOfLevels, this.props.Site.TypeId, TotalLikelyImpact);
        break;
      default:
        return;
    }
    this.setState({ ProbabilityRating, TotalLikelyImpact, OverallRating, EditData });
  }

  render() {

    const content = this.state.loading ? <em>Loading Evaluation Data...</em> : this.renderEvaluationForm();
    return (
      <>
        {content}
      </>
    );
  }

  saveEvaluation(e) {
    e.preventDefault();

    let isValid = true;
    this.state.EditData.map(item => {
      isValid &= fieldRequired(item.Rating, "errRating" + item.PerspectiveId, "Set Rating");
    });

    isValid &= fieldRequired(this.state.ProbabilityRating, "errProbabilityRating", "* Required");
    if (isValid) {
      switch (this.props.EvaluationType.toLowerCase()) {
        case "potential":
          this.savePotentialEvaluationData();
          break;
        case "actual":
          this.saveActualEvaluationData();
          break
        case "target":
          this.saveTargetEvaluationData();
          break
        default:
          return;

      }
    }
    else
      ToastUtility.show({
        title: 'Risk Evaluation Error', content: 'Please make sure that all your rating perspectives have impact rating!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
  }

  async savePotentialEvaluationData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const data = {
      Id: this.state.EditId,
      RiskId: this.state.RiskId,
      ProbabilityRating: this.state.ProbabilityRating,
      OverallRating: this.state.OverallRating,
      TotalLikelyImpact: this.state.TotalLikelyImpact,
      MetaData: this.state.EditData,
      CompleteStatus: this.state.NewCompleteStatus,
      Comment: this.state.NewComment
    }

    const response = await fetch("/api/riskevaluation/SavePotentialData", {
      method: "POST",
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      if (this.props.OnSave)
        this.props.OnSave();
    } else {
      console.log(response.status + ": ", response.statusText);
      if (response.status === 401)
        handleNavigate("/login");
      else
        ToastUtility.show({
          title: 'Save Potential Evaluation', content: 'There was an error saving Pontetial evaluation!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
    }
  }

  async saveActualEvaluationData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const data = {
      Id: this.state.EditId,
      RiskId: this.state.RiskId,
      ProbabilityRating: this.state.ProbabilityRating,
      OverallRating: this.state.OverallRating,
      TotalLikelyImpact: this.state.TotalLikelyImpact,
      MetaData: this.state.EditData,
      CompleteStatus: this.state.NewCompleteStatus,
      Comment: this.state.NewComment
    }

    const response = await fetch("/api/riskevaluation/SaveActualData", {
      method: "POST",
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      if (this.props.OnSave)
        this.props.OnSave();
    } else {
      console.log(response.status + ": ", response.statusText);
      if (response.status === 401)
        handleNavigate("/login");
      else
        ToastUtility.show({
          title: 'Save Actual Evaluation', content: 'There was an error saving Actual evaluation!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
    }
  }

  async saveTargetEvaluationData() {
    //console.log(this.state.TargetedCompletionDate)
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const data = {
      Id: this.state.EditId,
      RiskId: this.state.RiskId,
      ProbabilityRating: this.state.ProbabilityRating,
      OverallRating: this.state.OverallRating,
      TargetedCompletionDate: this.state.TargetedCompletionDate,
      TotalLikelyImpact: this.state.TotalLikelyImpact,
      MetaData: this.state.EditData,
      CompleteStatus: this.state.NewCompleteStatus,
      Comment: this.state.NewComment
    }

    const response = await fetch("/api/riskevaluation/SaveTargetData", {
      method: "POST",
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      if (this.props.OnSave)
        this.props.OnSave();
    } else {
      console.log(response.status + ": ", response.statusText);
      if (response.status === 401)
        handleNavigate("/login");
      else
        ToastUtility.show({
          title: 'Save Target Evaluation', content: 'There was an error saving target evaluation!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
    }
  }

}